@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/*@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');*/

@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap');

/**font-family: 'Poppins', sans-serif;font-family: 'Playfair Display', serif; font-family: 'Lato', sans-serif;**/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

body {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    overflow-x: hidden;
    color: #333333;
}

html,
body {
    height: 100%;
}


/**Common Css Start**/

.border-radius-banner {
    border-radius: 15px;
}

a:focus,
a:hover,
a:visited {
    text-decoration: none
}

a:focus {
    outline: 0
}

*:focus {
    outline: none !important
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0
}

ol,
ul {
    list-style: none;
    margin: 0
}

.bold {
    font-weight: 700;
}

.cursor-pointer {
    cursor: pointer
}

.text-uppercase {
    text-transform: uppercase
}

.position-relative {
    position: relative;
}

.container {
    max-width: 1170px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.fitimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/**Width**/

.w-100 {
    width: 100% !important
}

.w-50 {
    width: 50% !important
}

.w-25 {
    width: 25% !important
}

.w-24 {
    width: 24% !important
}

.w-33 {
    width: 33% !important
}

.w-32 {
    width: 32% !important
}

.w-49 {
    width: 49%;
}

.w-46 {
    width: 46%;
}

.w-60 {
    width: 60% !important
}

.h-100 {
    height: 100vh !important
}

.w-40 {
    width: 40%
}


/* Margins Top*/

.m-auto {
    margin: auto !important;
}

.m-0 {
    margin: 0px !important
}

.mt-05 {
    margin-top: 5px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-1 {
    margin-top: 10px !important;
}

.mt-2 {
    margin-top: 20px !important;
}

.mt-3 {
    margin-top: 30px !important;
}

.mt-4 {
    margin-top: 40px !important;
}

.mt-5 {
    margin-top: 50px !important;
}

.mt-6 {
    margin-top: 60px !important;
}

.mt-7 {
    margin-top: 70px !important;
}

.ml-15 {
    margin-left: 15px;
}

.mt-15 {
    margin-top: 15px;
}


/**Margin Bottom**/

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-1 {
    margin-bottom: 10px !important;
}

.mb-2 {
    margin-bottom: 20px !important;
}

.mb-3 {
    margin-bottom: 30px !important;
}

.mb-4 {
    margin-bottom: 40px !important;
}

.mb-5 {
    margin-bottom: 50px !important;
}

.mb-6 {
    margin-bottom: 60px !important;
}


/**Margin Right**/

.mr-05 {
    margin-right: 5px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.ml-40 {
    margin-left: 40px !important;
}


/**Start**/

.header-wrp {
    min-height: 80px;
    padding: 0px 30px;
    position: fixed;
    width: 100%;
    z-index: 1000;
    top: 0;
    transition: top 0.2s ease-in-out;
}

.nav-down {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 12px !important;
}

.location-dropdown p {
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    margin-left: 15px;
    font-family: 'Poppins', sans-serif;
}

.right-panel ul li {
    margin-left: 20px;
}

.right-panel ul li:nth-child(1) {
    margin-left: 0px;
}

.btn-default {
    border-radius: 20px;
    display: inline-block;
    padding: 6px 35px;
    color: #333333;
    font-size: 14px;
    transition: all 0.5s linear;
    border: 1px solid #d7d7d7;
    cursor: pointer;
}

.right-panel .btn-default {
    color: #fff;
}

.btn-default:hover {
    color: #333333;
    background-color: #ffde00;
    border: 1px solid #ffde00
}

.btn-yellow {
    border-radius: 20px;
    display: inline-block;
    padding: 8px 35px;
    color: #000 !important;
    font-size: 14px;
    transition: all 0.5s linear;
    background-color: #ffde00 !important;
    cursor: pointer;
    border: transparent;
}

.btn-yellow:hover {
    color: #000 !important;
    opacity: 0.7;
}

.btn-yellow-bold {
    font-weight: 500;
    color: #1a1a1a;
    padding: 8px 30px;
    transition: 0.2s linear;
}

.btn-yellow-bold:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.center-block {
    display: table;
    margin: auto
}

.btn-default:hover {
    color: #1a1a1a;
}

.login {
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.login:hover {
    color: #fff;
    xopacity: 0.8;
}

.login img {
    margin-right: 5px;
}

.text-gray .location-dropdown p,
.text-gray .right-panel .btn-default,
.text-gray .login,
.text-gray .openSearch,
.text-gray .openMenu {
    color: #333333;
    ;
}

.text-gray-innerMenu {
    xbox-shadow: 2px 3px 5px #ddd;
    background: #fff
}

.text-gray-innerMenu .location-dropdown p,
.text-gray-innerMenu .right-panel .btn-default,
.text-gray-innerMenu .login,
.text-gray-innerMenu .openSearch,
.text-gray-innerMenu .openMenu,
.text-gray-innerMenu .welcome-user .info h4,
.text-gray-innerMenu .welcome-user .info p {
    color: #333333;
    ;
}

.text-gray-innerMenu .welcome-user .icons img {
    filter: brightness(0);
}

.text-gray .login:hover,
.text-gray .openSearch:hover,
.text-gray .openMenu:hover,
.text-gray .welcome-user .info h4,
.text-gray .welcome-user .info p {
    color: #1a1a1a;
}

.text-gray-innerMenu .login:hover,
.text-gray-innerMenu .openSearch:hover,
.text-gray-innerMenu .openMenu:hover {
    color: #1a1a1a;
}

.welcome-user {}

.welcome-user a i,
.welcome-user i {
    font-size: 20px;
    color: #fff;
}

.text-gray .welcome-user a i,
.text-gray .welcome-user i {
    font-size: 20px;
    color: #333;
}

.welcome-user .info h4 {
    color: #fff;
    font-size: 11px;
    font-weight: 300;
    line-height: 1.1;
    text-transform: uppercase;
}

.welcome-user .info p {
    color: #fff;
    font-size: 10px;
}


/****/

.middle-panel .swtitch-tab {
    border-radius: 20px;
    border: 1px solid #ddd;
    padding: 3px;
    margin-left: 0px;
}

.middle-panel .swtitch-tab.uk-tab>.uk-active>a {
    color: #1a1a1a;
    border-color: #1e87f0;
    background: #ffde00;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 500;
}

.middle-panel .uk-tab>*>a {
    font-size: 13px;
    font-weight: 500;
    color: #1a1a1a;
    border-bottom: none;
    padding: 5px 35px;
}

.middle-panel .uk-tab::before {
    display: none
}

.middle-panel .swtitch-tab.uk-tab>* {
    padding-left: 0;
}

.w-20 {
    width: 20%
}

.scrolltoshow {
    display: none;
}

.scrolltoalwayshow {
    display: block;
}

.brigtness-black {
    filter: brightness(0);
}


/**Switch Buttons **/


/****/

.select-city-wrp {
    background-color: #eeeeee;
    position: absolute;
    top: 80px;
    width: 100%;
    left: 0;
    z-index: 12;
}

.select-city-wrp-inner {
    min-height: 330px;
}

.SelectCity-card li:first-child {
    color: #1a1a1a;
    font-size: 20px;
    border-right: 1px solid #ddd;
    padding-right: 60px;
}

.SelectCity-card li {
    margin: 0 15px;
    margin: 0 30px;
    padding: 30px 20px;
}

.SelectCity-card li a {
    color: #6a6969;
}

.SelectCity-card li i {
    color: #6a6969;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-style: normal;
    display: block;
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
}

.SelectCity-card li:hover i {
    color: #000
}

.SelectCity-card li a.active,
.SelectCity-card li a.active i {
    text-decoration: underline;
    font-weight: 500;
    color: #1a1a1a;
}

.hide-city {
    display: none;
}


/****/

.search-box-wrp {
    background-color: #eeeeee;
    position: absolute;
    top: 80px;
    width: 100%;
    left: 0;
    z-index: 12;
}

.search-box-card {
    min-height: 330px;
    max-width: 700px;
    margin: auto;
}

.search-box-card h4 {
    color: #1a1a1a;
    font-size: 25px;
    margin-bottom: 40px;
}

.search-box-card .uk-input {
    height: 80px;
    border-radius: 5px;
    border: none;
}

.search-box-card .uk-form-icon:not(.uk-form-icon-flip)~.uk-input {
    padding-left: 55px !important;
}

.search-box-card .uk-inline {
    width: 100%;
}

.search-box-card .btn-default {
    font-weight: 500;
    padding: 8px 35px;
}

.search-box-card .uk-form-icon {
    left: 15px;
}

.hide-search {
    display: none;
}

.bg-gray {
    background-color: #eeeeee
}

.openSearch {
    display: flex;
    height: 80px;
    align-items: center;
    font-size: 20px;
    color: #fff;
}

.openSearch:hover {
    color: #fff;
    opacity: 0.8;
}


/****/

.hamburger-menu-wrp {
    background-color: #eeeeee;
    position: absolute;
    top: 80px;
    width: 100%;
    left: 0;
    z-index: 12;
}

.hamburger-menu-wrp-inner {
    min-height: 330px;
    max-width: 900px;
    margin: auto;
}

.menu-2 ul,
.menu-1 ul {
    padding-left: 0;
}

.menu-2 li a {
    color: #1a1a1a;
    margin: 0 15px;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
}

.menu-2 li a:hover {
    opacity: 0.7
}

.menu-1 .uk-accordion-title {
    font-size: 14px;
    color: #1a1a1a;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
}

.menu-1 .uk-accordion-content {
    margin-top: 30px;
}

.menu-1 .uk-accordion-content li a {
    color: #6a6969;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 8px 0;
    display: inline-block;
}

.menu-1 .uk-accordion-content li a.active {
    text-decoration: underline;
    font-weight: 500;
    color: #1a1a1a;
}

.menu-1 .uk-accordion-content li a:hover {
    color: #1a1a1a;
    font-weight: 500;
}

.menu-1 .uk-open>.uk-accordion-title::before {
    display: none;
}

.menu-1 .uk-column-1-2 {
    border-right: 1px solid #ddd;
}

.openMenu {
    display: flex;
    height: 80px;
    align-items: center;
    font-size: 20px;
    color: #fff;
}

.openMenu:hover {
    color: #fff;
    opacity: 0.8;
}

.hide-menu {
    display: none;
}


/****/

.loginSignup-btn {
    border-radius: 20px;
    border: 1px solid #ddd;
    display: table;
    margin: auto;
    padding: 2px;
}

.loginSignup-btn a {
    border-radius: 20px;
    display: inline-block;
    padding: 5px 20px;
    color: #333333;
    font-size: 12px;
    text-align: center;
    font-weight: 500;
}

.loginSignup-btn a.active {
    background-color: #ffde00
}

.uk-modal-dialog {
    width: 360px;
    padding: 35px 40px 40px
}

.border-form-input {
    text-align: center;
}

.border-form-input .form-group {
    margin-bottom: 0px
}

.border-form-input .uk-input {
    border: none;
    border-bottom: 1px solid #ddd;
    color: #333333;
    font-size: 14px;
    font-weight: 300
}

.error {
    color: #ff0000 !important;
    font-size: 11px;
    text-align: left;
    margin-top: 5px;
    display: block;
}

.border-error {
    border-bottom: 1px solid #ff0000 !important;
}

.visibilityhidden {
    visibility: hidden;
}

.btn-yellow.btn-full {
    display: block;
    width: 100%;
    border: 1px solid #ffde00;
}

.or-style {
    background-color: #e5e5e5;
    color: #5b5b5b;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 5px;
    box-sizing: content-box;
    text-align: center;
    line-height: 22px;
    margin-top: 20px;
    display: table;
    margin: auto;
    margin-top: 20px;
    position: relative
}

.or-style::after {
    content: "";
    position: absolute;
    top: 50%;
    border-top: 1px solid #e5e5e5;
    right: -100px;
    width: 100px;
}

.or-style::before {
    content: "";
    position: absolute;
    top: 50%;
    border-top: 1px solid #e5e5e5;
    left: -100px;
    width: 100px;
}

.connect-social a {
    display: inline-block;
    width: 45px;
    margin: 0 10px;
}

.signup-popup {
    display: none;
}

.border-form-input [class*=uk-inline] {
    width: 100%;
}


/****/

.video-wrp {
    height: 100vh;
    background: #333333;
    overflow: hidden;
}

.video-poster {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    xheight: 100vh;
    background: url("assets/images/banner-1.jpg");
    background-size: cover;
    background-position: center;
}

.video-poster-pune {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: url("assets/images/pune-banner.jpg");
    background-size: cover;
    background-position: center;
}

.video-hightlight {
    z-index: 10;
    max-width: 960px;
    margin: auto;
    text-align: center;
    position: relative;
    top: 55%;
    transform: translate(0, -55%);
}

.video-hightlight p {
    color: #fff;
    font-size: 23px;
    line-height: 12px;
    margin-bottom: 20px;
    font-weight: 300;
}

.video-hightlight .details h4 {
    color: #fff;
    font-size: 80px;
    font-family: 'Playfair Display', serif;
    font-weight: 700;
}

.video-hightlight .details h6 {
    color: #fff;
    font-size: 20px;
    font-weight: 300;
}

.play-btn-box {
    min-height: 400px;
    position: relative;
    margin-top: -96px;
    border-bottom: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-down {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 18px;
    display: block;
    width: 100px;
    text-align: center;
}

.scroll-down p {
    font-size: 13px;
    padding-bottom: 30px;
    color: #fff;
    opacity: 0.8;
}

.line-vertical {
    width: 40%;
    border-top: 1px solid #ffde00;
    transform: rotate(90deg);
    margin: auto;
}

.video-stream {
    display: none;
}

.video-thumbnail {
    height: 100vh;
    background-color: #f4f4f4
}


/****/

.categories-wrp {
    padding: 20px 0 5px;
    position: relative;
}

.categories-wrp::before {
    position: absolute;
    content: "";
    background-color: #ffde00;
    height: 330px;
    top: 0;
    width: 100%;
    z-index: -1;
}

.text-info {
    width: 100%;
    padding-right: 25px;
    margin-bottom: 20px;
}

.categories-slider {
    width: 100%;
    margin-top: 30px;
}

.categories-wrp-inner .text-info h4 {
    color: #1a1a1a;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 500;
    position: relative;
}


/* .categories-wrp-inner .text-info h4::after {
    content: "";
    position: absolute;
    width: 86%;
    border-top: 1px solid #e5c700;
    top: 50%;
    left: 170px;
    transform: translate(0, -50%);
} */

.categories-wrp-inner .text-info p {
    color: #1a1a1a;
    font-weight: 300;
    font-size: 14px;
}

.categories-slider .custom-nav svg {
    display: none;
}

.categories-slider .uk-position-center-left-out {
    right: 30px;
    top: -85px;
}

.categories-slider .uk-position-center-right-out {
    right: 0px;
    transform: rotate(180deg);
    top: -95px;
    left: initial;
}


/* .categories-slider .uk-child-width-1-5\@m >* {
    width: 18%;
    margin: 1%;
    cursor: pointer;
    margin-bottom: 15px;
} */

.categories-slider .categories-img {
    height: 200px;
    border-radius: 8px;
    object-fit: cover;
    background: #ddd;
    border-bottom: 5px solid #d9d9d9;
}

.categories-slider .cate-name h4 {
    font-weight: 500;
    font-size: 15px;
    color: #1a1a1a;
    text-align: center;
    margin-top: 20px;
}

.categories-slider li.active .categories-img {
    border-bottom: 5px solid #ffde00;
    border-radius: 8px;
    position: relative;
}


/* .categories-slider .active .categories-img::before {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #ffde00;
    position: absolute;
    bottom: -18px;
    content: "";
    left: 0;
    right: 0;
    margin: auto;
} */


/***************/

.experience-wrp {
    padding: 50px 15px 10px;
    position: relative;
}


/*.experience-wrp::before {*/


/*  content: "";*/


/*  width: 700px;*/


/*  height: 300px;*/


/*  !*background: url("assets/images/watermark.png") no-repeat center center;*!*/


/*  position: absolute;*/


/*  top: 0;*/


/*  left: 0;*/


/*  right: 0;*/


/*  margin: auto;*/


/*  z-index: -1;*/


/*  background-size: contain*/


/*}*/

.heading-text-wrp {
    margin-bottom: 120px;
}

.heading-text-wrp h4 {
    font-size: 42px;
    font-weight: 700;
    /* font-family: 'Playfair Display', serif;*/
    font-family: 'Maven Pro';
    text-align: center;
    color: #1a1a1a;
    margin-bottom: 15px;
}

.heading-text-wrp h4 span {
    letter-spacing: -3px
}

.heading-text-wrp h4 span .large-font {
    font-size: 65px;
}

.heading-text-wrp p {
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    color: #333333;
    padding-bottom: 30px;
}

.line {
    Width: 250px;
    border-top: 2px solid #ffde00;
    margin: auto
}

.listing-card {
    background-color: #fff;
    padding: 15px 15px 30px;
    border-radius: 15px;
    width: 31.3333%;
    margin: 0 1%;
    margin-bottom: 80px;
    -webkit-box-shadow: 0px 0px 8px 0px rgb(218 218 218);
    -moz-box-shadow: 0px 0px 8px 0px rgb(218 218 218);
    box-shadow: 0px 0px 8px 0px rgb(218 218 218)
}

.listing-card .imageVideo {
    margin-top: -40px;
    border-top: 8px solid #ffde00;
    border-top: 5px solid #ffde00;
    border-radius: 22px;
}

.listing-card .image {
    overflow: hidden;
    border-radius: 15px;
    width: 100%;
    xheight: 430px;
    position: relative;
}

.listing-card .video {
    height: 430px;
}

.video .vdo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.listing-card .voice {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.listing-card .image img {
    border-radius: 15px;
    object-fit: fill;
    height: 100%;
}

.listing-card .details {
    padding-top: 25px;
}

.listing-card .details span {
    color: #333333;
    font-size: 14px;
    display: block;
    letter-spacing: 1px;
}

.listing-card .details h4,
.listing-card .details h4 a {
    color: #1a1a1a;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 15px;
}

.listing-card .details p,
.listing-card .details p a {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 25px;
    line-height: 1.4;
    min-height: 85px;
}

.listing-card .btn-default {
    margin: auto;
    display: table;
    padding: 6px 30px;
}


/****/

.camera-len-wrp {
    background: url("assets/images/img-11.jpg") no-repeat;
    background-size: cover;
    padding: 30px 0 30px;
    min-height: 580px;
}

.mt-100 {
    margin-top: 100px;
}

.mt-115 {
    margin-top: 115px;
}

.camera-len {
    text-align: center;
}

.camera-len h4 {
    color: #1c1c1c;
    font-size: 16px;
    font-weight: 500;
    margin-top: 40px;
}

.camera-len p {
    color: #5b5b5b;
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
}

.hidelg {
    display: none;
}


/**18 Aug 21**/

.listing-card .uk-cover {
    object-fit: fill;
}


/**19 Aug 2021**/

.video-img-slider .custom-nav svg {
    display: none;
}

.video-img-slider .uk-position-center-right img {
    transform: rotate(180deg);
}

.video-img-slider video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.uk-panel video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/****/

.key-highlight-wrp {
    padding-top: 60px;
}

.wrapper {
    position: relative
}

.wrapper::before {
    position: absolute;
    content: "";
    background-color: #ffde00;
    height: 235px;
    top: 0;
    width: 100%;
    z-index: -1;
}

.container-inner .w-70 {
    padding-right: 40px;
}

.key-highlight-info {
    padding-right: 50px;
}

.key-highlight-info-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding-bottom: 25px;
}

.w-65 {
    width: 65%
}

.key-highlight-info-header h6 {
    color: #1a1a1a;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 300;
    font-family: Poppins, sans-serif;
}

.key-highlight-info-header h6 img {
    vertical-align: text-bottom;
    margin-right: 5px;
}

.key-highlight-info-header h4 {
    color: #1a1a1a;
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
}

.key-highlight-info-header span {
    color: #333333;
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
}

.key-highlight-info-header a {
    margin: 0 10px;
    font-size: 18px;
    color: #1a1a1a;
    margin-top: 3px;
}

.key-highlight-info-details p {
    color: #1a1a1a;
    font-size: 16px;
    line-height: 1.9;
    padding-right: 20px;
}

.hightlight-point h5 {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
}

.hightlight-point h5::after {
    content: "";
    width: 40px;
    border-top: 1px solid #1a1a1a;
    position: absolute;
    top: 50%;
    right: -55px;
}

.hightlight-point ul {
    list-style-type: disc;
    padding-left: 15px;
}

.hightlight-point ul li {
    color: #1a1a1a;
    margin: 12px 0;
}

.w-35 {
    width: 35%
}

.key-highlight-pricing {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 30px 30px 40px;
    background-color: #fff;
    position: relative;
}

.key-highlight-pricing h4 {
    color: #333333;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 25px;
}

.key-highlight-pricing .border-form-input {
    text-align: left;
}

.custom-radio span {
    color: #333333;
    display: block;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
}

.custom-radio label {
    margin-right: 10px;
}

.uk-checkbox:checked,
.uk-checkbox:indeterminate,
.uk-radio:checked {
    background-color: #ffde00 !important;
    border-color: transparent;
}

.key-highlight-pricing .border-form-input .uk-form-icon {
    width: 25px;
}

.border-form-input button {
    border: 1px solid transparent;
}

.type-ahead a {
    font-size: 12px;
    margin: 0 8px;
    cursor: default;
    display: inline-block;
}

.blue-text {
    color: #6b6ada;
}

.type-ahead a:nth-child(2) {
    border-left: 1px solid #ff8f15;
    padding-left: 20px;
}

.orange-text {
    color: #ff8f15;
}

.pricing-box {
    background: #ebebeb;
    margin-top: 15px;
    padding: 25px 20px;
    border-radius: 5px;
}

.pricing-box .price span {
    color: #1a1a1a;
    font-size: 12px;
    font-weight: 300;
}

.pricing-box .price p {
    color: #1a1a1a;
    font-size: 25px;
    font-weight: 700;
}

.key-highlight-pricing .uk-form-icon:not(.uk-form-icon-flip)~.uk-input {
    padding-left: 30px !important;
}

.key-highlight-pricing input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url("assets/images/icons/calendar.png") no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
    cursor: pointer;
    background-position: left
}

.key-highlight-pricing input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: -19.50px;
}

.key-highlight-pricing input::-webkit-datetime-edit {
    position: relative;
    left: 0;
}

.key-highlight-pricing input::-webkit-datetime-edit-fields-wrapper {
    position: relative;
    left: 0;
}

.key-highlight-info .view-list ul {
    padding-left: 0;
}

.key-highlight-info .view-list ul li {
    font-size: 13px;
    color: #333333;
    margin-right: 25px;
}

.key-highlight-info .view-list ul li img {
    margin-right: 5px;
    vertical-align: sub;
}

.guest-booking {
    background-color: #fff;
    border-radius: 15px;
    -webkit-box-shadow: 0px 0px 8px 0px rgb(218 218 218);
    -moz-box-shadow: 0px 0px 8px 0px rgb(218 218 218);
    box-shadow: 0px 0px 8px 0px rgb(218 218 218);
    padding: 20px;
}

.guest-card {
    border: 1px dashed rgba(0, 0, 0, 0.5);
    padding: 10px 20px
}

.guest-card .icon {
    margin-right: 10px;
    width: 40px;
}

.guest-card i {
    font-size: 30px;
}

.icon.fa-icon3 i {
    font-size: 28px;
}

.icon.fa-icon2 i {
    font-size: 32px;
}

.guest-card .info {
    width: calc(100% - 55px)
}

.guest-card .info h4 {
    color: #333333;
    font-size: 16px;
}

.guest-card .info button {
    background: #fff;
    border: none;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: self-start;
}

.guest-card .uk-form-custom {
    width: 100%;
}

.guest-card .info .uk-input {
    height: 20px;
    bottom: none;
    border: none;
    padding: 0;
    color: #333333;
    font-size: 12px;
    position: relative;
}


/* .guest-card .info input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url("assets/images/icons/arrow-bottom-2.png") no-repeat;
    background-position: right top;
    width: 14px;
    height: 8px;
    border-width: thin;
    cursor: pointer;
} */

.guest-booking .details h4 {
    font-size: 30px;
    font-weight: 500;
    color: #000
}

.guest-booking .details h4 .fa-rupee {
    font-size: 60%;
}

.guest-booking .details p {
    font-size: 13px;
    color: #333333
}

.guest-booking .details span {
    font-size: 12px;
    display: block;
    color: #3bb201;
    margin-top: 25px;
}

.btn-full {
    width: 100%;
    padding: 10px 15px;
    color: #000
}


/****/

.more-related-video-wrp h4 {
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 35px;
    position: relative;
}

.more-related-video-wrp h4::after {
    content: "";
    position: absolute;
    width: 68%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    top: 50%;
    left: 150px;
    transform: translate(0, -50%);
}

.related-video-slider .video-thumbnail {
    height: 290px;
    overflow: hidden;
}

.related-video-slider .video-thumbnail .video-stream video {
    height: 100vh;
    width: 100%;
}

.related-video-slider .video-poster {
    height: 100%;
}

.related-video-slider .uk-grid>* {
    padding-left: 10px;
}

.image-top,
.image-bottom {
    height: 140px;
    background-color: #ddd;
    overflow: hidden
}

.images-block-single {
    background-color: #ddd;
    height: 290px;
    overflow: hidden
}

.images-block img,
.images-block-single img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-bottom {
    margin-top: 10px;
}

.video-hightlight.center-icon {
    top: 50%;
    transform: translate(0, -50%);
}

.related-video-slider .custom-nav svg {
    display: none;
}

.related-video-slider .uk-position-center-left-out {
    right: 35px;
    bottom: initial;
    top: -50px;
}

.related-video-slider .uk-position-center-right-out {
    right: 0;
    transform: rotate(180deg);
    left: initial;
    top: -67px;
}

.related-video-slider .uk-icon.uk-slidenav-next.uk-slidenav img,
.related-video-slider .uk-icon.uk-slidenav-previous.uk-slidenav img {
    display: none
}

.related-video-slider .uk-slidenav-next:after {
    content: "\f104";
    font-size: 30px;
    font-family: 'FontAwesome';
    color: #1a1a1a;
    line-height: 1;
}

.related-video-slider .uk-slidenav-previous:after {
    content: "\f104";
    font-size: 30px;
    font-family: 'FontAwesome';
    color: #1a1a1a;
    line-height: 1;
}


/****/

.highlight-accordions-wrp-inner {}

.highlight-accordions-wrp-inner .uk-accordion-title {
    font-size: 18px;
    color: #1a1a1a;
    border: 1px solid #ddd;
    padding: 35px 25px;
}


/**Progress List**/

.progress-list {
    padding: 10px 0px 10px;
}

.progress-list ul {
    padding: 0;
    margin: 0;
    position: relative;
}

.list-circle {
    background: url('assets/images/icons/yellow-dot.png') no-repeat;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    display: inline-block;
    margin-left: 8px;
    position: relative;
    z-index: 1;
}

.progress-list ul li::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 99%;
    border-left: 1px dashed #ddd;
    left: 15px;
    top: 5px;
}


/* .progress-list ul li:last-child:after {
    background: red
} */

.progress-list li a.list-group {
    align-items: flex-start;
    margin-bottom: 50px;
    transition: 0.2s linear;
    cursor: default
}

.progress-list li a.list-group.uk-flex-middle {
    align-items: center;
}

.progress-list .details.link {
    text-decoration: underline;
    color: #1a1a1a;
    font-size: 16px;
    cursor: pointer;
}

.progress-list .details {
    width: calc(100% - 34px);
    padding-left: 30px;
}

.progress-list .details h4 {
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.progress-list .details h5 {
    color: #1a1a1a;
    font-size: 18px;
    margin-bottom: 15px;
}

.progress-list .details p {
    color: #1a1a1a;
    line-height: 1.9;
    font-weight: 300;
}

.text-green {
    color: #00a380
}

.text-red {
    color: #ff1010
}


/* .progress-list li a.active {
    color: #fff
}

.progress-list li a.active .list-circle {
    background: #fff
}

.progress-list li a:hover {
    color: #fff
} */

.progress-list li a .list-line::before {
    /* content: "";
position: absolute;
width: 1px;
height: 35px;
background: #4d7fa9;
left: 0;
right: 0;
margin: auto;
top: 14px; */
}


/* .progress-list li::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 0;
    border-left: 1px dashed red;
    left: 15px;
    top: 5px;
    animation: drawBorderlist 1s ease forwards;
}

@keyframes drawBorderlist {
    from {
        height: 0px;
    }
    to {
        height: 98%;
    }
} */


/* .progress-list li:last-child a {
    margin-bottom: 0px;
} */

.what-include li {
    color: #1a1a1a;
    margin: 15px 0;
    display: flex;
}

.what-include li span {
    margin-right: 25px;
    width: 13px;
    display: inline-block;
}

.what-include li i {
    width: calc(100% - 38px);
    font-style: normal;
}

.terms-condition {
    padding-left: 30px;
}

.cancel-policy h6 {
    color: #1a1a1a;
    font-size: 13px;
    font-weight: 700;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
}

.cancel-policy p {
    color: #1a1a1a;
    font-weight: 300;
}

.cancel-policy p a {
    text-decoration: underline;
}

.ShowList {
    display: none;
}


/****/

.heading-text h4 {
    color: #1a1a1a;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}

.heading-text p {
    color: #1a1a1a;
}

.text-center {
    text-align: center;
}

.related-collection-slider .uk-grid>* {
    /* padding-left: 15px; */
}

.related-collection-slider .uk-card-media-top {
    height: 370px;
    overflow: hidden;
    background-color: #ddd
}

.related-collection-slider .uk-card-media-top img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.related-collection-slider .uk-card-body {
    padding: 15px 5px;
}

.related-collection-slider .uk-card-body .uk-card-title {
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
}

.related-collection-slider .uk-card-body span {
    color: #777676;
    font-size: 12px;
    font-weight: 300;
    display: inline-block;
    margin-bottom: 10px;
}

.related-collection-slider .uk-card-body p {
    color: #1a1a1a;
    line-height: 1.9;
    margin-bottom: 25px;
    padding-right: 20px;
}

.related-collection-slider .uk-card-default {
    background: #fff;
    color: initial;
    box-shadow: none;
}

.related-collection-slider .uk-card-default .btn-default {
    color: #333333 !important;
}


/****/

.offer-text-wrp {
    min-height: 230px;
    background: url("assets/images/bg-1.jpg") no-repeat;
    width: 100%;
    background-position: center;
    background-size: cover
}

.offer-text-card {
    padding: 40px 40px;
}

.line-left {
    position: relative;
    min-height: 170px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.line-left::before {
    content: " ";
    width: 100px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 0;
    left: 0;
}

.line-left::after {
    content: " ";
    width: 100px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    bottom: 0;
    left: 0;
}

.line-right {
    position: relative;
    min-height: 170px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
}

.line-right::before {
    content: " ";
    width: 100px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 0;
    left: -100px;
}

.line-right::after {
    content: " ";
    width: 100px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    position: absolute;
    bottom: 0;
    left: -100px;
}

.offer-text-card h4 {
    color: #fff;
    font-size: 23px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 15px;
}

.offer-text-card p {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.categories-wrp-inner {
    text-align: left;
}


/****/

.footer-wrp {
    background-color: #1a1a1a;
    padding: 50px 15px 50px
}

.footer-wrp-inner .w-25 {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    padding-top: 15px;
}

.footer-wrp-inner .w-25:last-child {
    border-right: none;
}

.w-900 {
    max-width: 900px;
}

.footer-panel-left h4 {
    text-transform: uppercase;
    color: #ffde00;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.footer-panel-left .list {
    padding-left: 0;
}

.footer-panel-left.list2 {
    padding-left: 40px;
}

.footer-panel-left {}

.footer-panel-left ul li a {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    margin-bottom: 15px;
    display: inline-block;
    text-transform: unset;
}

.footer-panel-left ul.list li a:hover {
    opacity: 0.7;
}

.footer-panel-left p {
    color: #fff;
    opacity: 0.3;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-top: 10px;
}

.footer-panel-left {
    position: relative;
    xborder-right: 1px solid rgba(255, 255, 255, 0.3);
}

.footer-panel-left::after {
    /* border-right: 1px solid rgba(255, 255, 255, 0.3);
  xposition: absolute;
  height: 90%;
  right: 0;
  content: '';
  top: 0px; */
}


/****/

.footer-panel-right {
    padding-left: 50px;
}

.footer-panel-right .number h4 {
    color: #ffde00;
    font-size: 24px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
}

.footer-panel-right .number img,
.footer-panel-right i {
    margin-right: 10px;
    vertical-align: text-top;
    line-height: 1.6;
}

.footer-panel-right a,
.email i {
    color: #fff;
    font-size: 14px;
    /* font-weight: 500; */
    /* font-family: 'Poppins', sans-serif; */
}

.footer-panel-right .email img {
    margin-right: 10px;
    width: 15px;
}

.footer-panel-right .social-box {
    text-align: left;
    margin-left: 0px;
    margin-top: 30px;
}

.footer-panel-right .social-box a {
    font-size: 20px;
    margin-right: 20px;
}

.footer-panel-right .social-box a:hover {
    opacity: 0.6
}

.footer-panel-left .uk-accordion-title::before {
    display: none;
}

.footer-panel-left .uk-accordion-content {
    margin-top: 0;
}

.uk-accordion-title:hover {
    cursor: default
}


/****/

.footer-bottom {
    background-color: #fff;
    padding: 25px 5px 25px
}

.footer-bottom .policy-link a {
    color: #1a1a1a;
    opacity: 0.7;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-right: 5px;
    transition: 0.2s linear
}

.footer-bottom .policy-link a:hover {
    opacity: 0.8;
}

.footer-bottom p {
    color: #1a1a1a;
    opacity: 0.7;
    font-size: 12px;
    font-weight: 500;
}


/****/

.login-wrp {
    max-width: 360px;
    padding: 35px 40px 50px;
    margin: 10px auto 50px;
}


/**22 Aug**/

.mobile-profile-details {
    padding: 0 15px;
    text-align: center;
}

.photo {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    clear: both;
    margin: auto;
    margin-bottom: 15px;
    overflow: hidden;
}

.photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mobile-profile-details h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 5px;
}

.city-accordion .uk-accordion-title {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #1a1a1a;
    text-transform: capitalize;
}

.city-accordion .uk-accordion-title::before {
    display: none;
}

.city-accordion .uk-accordion-title::after {
    content: "";
    width: 13px;
    height: 13px;
    margin-left: 5px;
    background-image: url("assets/images/icons/arrow-down-sm.png");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: inline-block;
    vertical-align: middle;
}

.city-accordion .uk-open>.uk-accordion-title::after {
    background-image: url("assets/images/icons/arrow-down-sm.png");
    transform: rotate(180deg);
}

.city-accordion .uk-accordion-content {
    margin-top: 10px;
}

.city-accordion .uk-accordion-content ul {
    padding: 0
}

.city-accordion .uk-accordion-content ul li a {
    color: #6a6969;
    margin: 5px;
    display: block;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 400;
}


/**23 Aug**/

.profile-wrp {
    background-color: #e4e4e4;
    height: 100vh;
    position: relative;
}

.profile-wrp::after {
    background-color: #fff;
    content: "";
    position: absolute;
    bottom: 0;
    height: calc(100vh - 230px);
    width: 100%;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px
}

.profile-wrp-inner {
    padding-top: 185px;
    position: relative;
    z-index: 10;
}

.w-580 {
    max-width: 650px;
    margin: auto;
}

.profile-list-wrp .list-card a {
    border: 1px solid #cdcdcd;
    display: block;
    padding: 20px;
    text-align: center;
    min-height: 110px;
    border-radius: 5px;
    transition: 0.2s linear;
    width: 21%;
    margin: 0 2%;
}

.profile-list-wrp .list-card a:hover {
    border: 1px solid #1a1a1a;
}

.profile-list-wrp .list-card a p {
    color: #1a1a1a;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    margin-top: 8px;
    margin-bottom: 15px;
}

.other-link ul {
    padding-left: 0;
}

.other-link ul li {
    border-right: 1px solid #989898;
}

.other-link ul li:last-child {
    border-right: none;
}

.other-link li a {
    color: #989898;
    font-size: 11px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0 20px;
    display: inline list-item;
    transition: 0.2s linear;
}

.other-link li a:hover {
    color: #000
}


/****/

.enquiry-wrp {
    margin-top: 30px;
}

.enquiry-wrp .inquiry-banner {
    background: url("assets/images/enquiry-bg.jpg");
    min-height: 110px;
    padding: 20px;
    background-size: cover;
}

.enquiry-wrp .inquiry-banner h3 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 900;
    line-height: 1.3;
    margin-bottom: 10px;
}

.enquiry-wrp .inquiry-banner p {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
}

.enquiry-wrp .key-highlight-pricing {
    padding: 0
}

.enquiry-wrp .border-form-input {
    padding: 0px 30px 40px
}

.enquiry-wrp .key-highlight-pricing h4 {
    padding: 25px 30px 0;
}


/**25 Aug 21**/

.video-img-slider .uk-slideshow-items {
    min-height: calc(100vh) !important;
}

.video-img-slider .info {
    display: none;
}

.notfound-page-wrp {
    margin-top: 150px;
    margin-bottom: 50px;
    text-align: center
}

.notfound-page-card p {
    color: #333333;
    font-weight: 300;
    max-width: 470px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 50px;
    line-height: 1.9;
}


/****/

.mt-110 {
    margin-top: 110px;
}

.contact-us-wrp-inner h4 {
    font-size: 22px;
    font-weight: 700;
}

.contact-us-wrp-inner h4 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 20px;
    font-family: 'Maven Pro';
    font-weight: 800;
}

.contact-card-top {
    background-color: #333333;
    ;
    padding: 25px;
}

.contact-card-top .contact-card {
    padding: 25px 0
}

.contact-card-top .contact-card:nth-child(2) {
    border-left: 1px solid #666669;
    border-right: 1px solid #666669;
    padding: 25px 50px;
}

.contact-card-top .contact-card:first-child {
    padding-right: 50px;
}

.contact-card-top .contact-card:last-child {
    padding-left: 50px;
}

.contact-card-top .contact-card .icons {
    margin-right: 30px;
}

.contact-card-top .contact-card h3 {
    color: #fff;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
}

.contact-card-top .contact-card a,
.contact-card-top .contact-card p {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    transition: 0.2s linear
}

.contact-card-top .contact-card a:hover {
    opacity: 0.7
}


/****/

.contact-card-field-wrp .border-form-input .form-group {
    width: 30%;
}

.w-1020 {
    max-width: 1024px;
    margin: auto
}

.contact-card-field-wrp .border-form-input .uk-form-icon {
    width: 25px;
}

.contact-card-field-wrp .uk-form-icon:not(.uk-form-icon-flip)~.uk-input {
    padding-left: 30px !important;
}

.border-form-input .form-group .uk-textarea {
    border: none;
    height: 45px;
    border-bottom: 1px solid #ddd
}

.border-form-input .form-group.btn-box {
    text-align: left;
}


/**26 Aug**/

.w-30 {
    width: 30%
}

.information-wrp h4 {
    color: #333333;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 30px;
}

.information-wrp-inner .left-panel ul {
    padding-left: 0;
}

.information-wrp-inner .left-panel ul li a {
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    display: flex;
    padding: 25px 25px 25px 25px;
    justify-content: space-between;
    align-items: center;
    transition: all 0.2s;
    background-color: #fff;
    color: #1a1a1a;
    font-size: 16px;
}

.information-wrp-inner .left-panel ul li a:hover {
    opacity: 0.7;
}

.information-wrp-inner .left-panel ul li:last-child a {
    xbackground-color: #ebebeb;
    border-bottom: 1px solid #ddd;
    xpadding-left: 30px;
}

.information-wrp-inner .left-panel li.uk-active a {
    background-color: #fcd872;
    color: #fff;
}

.information-wrp-inner .left-panel ul li a.links img {
    background: #ddd;
    padding: 10px;
    border-radius: 50%
}

.text-lowercase {
    text-transform: lowercase;
}

.uk-switcher {
    list-style-type: none;
}


/*.information-wrp-inner .left-panel ul li:nth-child(1) a img {
    background: #6ca0b6;
}

.information-wrp-inner .left-panel.privacy-policy ul li:nth-child(1) a img {
    background-color: #fcd872;
}

.information-wrp-inner .left-panel ul li:nth-child(2) a img {
    background: #e9785b;
}

.information-wrp-inner .left-panel ul li:nth-child(3) a img {
    background: #31a38d;
}

.information-wrp-inner .left-panel ul li:nth-child(4) a img {
    background: #f36b7f;
}*/

.information-wrp-inner .left-panel ul li a span {}

.information-wrp-inner .left-panel.privacy-policy ul li a span {
    color: #fff;
}

.w-70 {
    width: 70%;
}

.information-wrp-details {
    padding-left: 50px;
}

.information-wrp-details .info h3 {
    font-size: 25px;
    margin-bottom: 25px;
}

.information-wrp-details .info p {
    font-weight: 300;
    color: #333333;
    line-height: 1.9;
    padding-left: 20px;
    position: relative;
    margin-bottom: 30px;
}

.information-wrp-details .info h6 {
    color: #333333;
    font-size: 14px;
    margin-bottom: 20px;
}

.information-wrp-details .info p::before {
    height: 30px;
    border-left: 2px solid #6ca0b6;
    content: "";
    left: 0;
    top: 8px;
    position: absolute;
}

.information-wrp-details .info ul {
    margin-bottom: 30px;
}

.information-wrp-details .info ul li {
    font-weight: 300;
    color: #333333;
    line-height: 1.9;
    padding-left: 5px;
    xlist-style: disc;
    xlist-style-type: disc;
    padding-bottom: 5px;
}

.information-wrp-details .info.disclaimer p::before {
    border-left: 1px solid #e9785b;
}

.information-wrp-details .info.disclaimer p::before {
    border-left: 1px solid #e9785b;
}

.information-wrp-details .info.agreement p::before {
    border-left: 1px solid #31a38d;
}

.information-wrp-details .info.data-security p::before {
    border-left: 1px solid #f36b7f;
}

.information-wrp-details .info.privacy-policy p::before {
    border-left: 1px solid #fcd872;
}

.information-wrp-details .info p a {
    color: #6ca0b6
}

.text-blue {
    color: #6ca0b6 !important;
}

.text-orange {
    color: #e9785b !important;
}

.text-green {
    color: #31a38d !important;
}

.text-pink {
    color: #f36b7f !important;
}

.numeric-asc {
    list-style-type: decimal
}

.alpha-asc {
    list-style-type: lower-alpha
}

.disc {
    list-style-type: disc;
}


/****/

.uk-slideshow-items li::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 214px !important;
    background: url("assets/images/img-overlay.png");
    background-position: 100% 100%;
    left: 0;
    bottom: 0;
    top: initial !important;
}


/****/

.thankyou-box {
    text-align: center;
    padding: 20% 0;
}

.contact-thanks {
    padding: 50px 0 0;
}

.thankyou-box h6 {
    color: #333333;
    font-size: 20px;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 15px;
}

.uk-input,
.uk-select,
.uk-textarea {
    background: #ffff;
}


/* .key-highlight-wrp::after {
    background: url("assets/images/yellow-bg.png");
    content: "";
    position: absolute;
    right: 0;
    top: -30px;
    width: 405px;
    z-index: -1;
    height: 450px;
    background-position: bottom;
    background-size: cover;
} */


/****/


/****/

.mt-130 {
    margin-top: 130px;
}

.about-us-wrp .heading h4,
.faq-wrp .heading h4 {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    /* font-family: 'Playfair Display', serif; */
    font-family: 'Maven Pro';
    color: #1a1a1a;
    margin-bottom: 15px;
}

.about-us-wrp .heading p,
.faq-wrp .heading p {
    color: #333333;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    line-height: 1.9
}

.w-49 {
    width: 49%
}

.about-big-img .w-49 img {
    width: 100%;
    height: 380px;
    object-fit: cover;
}

.about-us-wrp .info p {
    color: #333333;
    line-height: 1.9;
    max-width: 940px;
    margin: auto;
    text-align: center
}


/****/

.coundown-wrp ul {
    padding-left: 0;
}

.coundown-wrp ul li {
    width: 25%;
    text-align: center;
    border-right: 1px solid #ddd;
}

.coundown-wrp ul li:last-child {
    border-right: none;
}

.coundown-wrp ul li h4 {
    font-size: 45px;
    font-weight: 200;
    color: #333333
}

.coundown-wrp ul li span {
    font-size: 14px;
    font-weight: 300;
    color: #333333;
    text-transform: uppercase;
    display: inline-block;
}


/****/

.community-wrap {
    background-color: #f3f3f3;
    padding-top: 60px;
    padding-bottom: 90px;
}

.sub-heading h4 {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    text-align: center;
    margin-bottom: 15px;
}

.sub-heading p {
    font-size: 14px;
    font-weight: 300;
    color: #333333;
    text-align: center;
}

.city-wrp ul {
    padding-left: 0;
}

.city-wrp ul li {
    width: 25%;
}

.city-wrp ul li a {
    border: 1px solid #ddd;
    display: block;
    padding: 30px 15px;
    text-align: center;
    min-height: 185px;
    background-color: #f3f3f3;
    position: relative;
    transition: all 0.2s;
}

.city-wrp ul li a span {
    color: #333333;
    font-size: 14px;
    display: block;
    margin-top: 20px;
}

.city-wrp ul li a:hover {
    background-color: #ffde00;
    z-index: 10;
}

.city-wrp ul li a:hover span {
    color: #fff;
}

.city-wrp ul li a:hover img {
    filter: brightness(0) invert(1);
}


/****/

.faq-wrp-inner .uk-accordion-content {
    padding: 0 20px;
}

.faq-wrp-inner .acc-content p {
    line-height: 1.9;
    margin-bottom: 15px;
}

.faq-wrp-inner .acc-content p a {
    color: #6ca0b6;
}

.faq-wrp-inner .uk-accordion-content ul {
    margin-bottom: 30px;
}

.faq-wrp-inner .acc-content ul li {
    font-weight: 300;
    color: #333333;
    line-height: 1.9;
    padding-left: 5px;
    list-style: disc;
    list-style-type: disc;
    padding-bottom: 5px;
}

.fa-angle-down {
    transition: 0.2s linear;
}

.rotate {
    transform: rotate(180deg);
    transition: 0.2s linear;
}


/****/

.logo-shadow {
    position: relative;
}


/*.logo-shadow::after {
    position: absolute;
    content: "";
    width: 330px;
    height: 200px;
    background: url("assets/images/logo-shadow.png") no-repeat;
    left: -125px;
    background-size: 100% 100%;
    top: -12px;
    z-index: -1;
}*/


/****/

.otp-wrp .info p {
    color: #1a1a1a;
    font-size: 12px;
    font-weight: 300;
    text-align: center
}

.otp-wrp .info a {
    color: #1579b3;
    font-size: 10px;
    font-weight: 300;
    margin: auto;
    display: table;
    text-decoration: underline;
    margin-top: 5px;
}

.otp-wrp .info h6 {
    color: #898989;
    font-size: 10px;
    text-align: center
}


/****/

.scrollup {
    width: 30px;
    height: 30px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: none;
    background-color: #facd0f;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    line-height: 30px;
    font-size: 18px;
    z-index: 100;
    border-radius: 50%
}

.scrollup:hover {
    color: #1a1a1a;
    opacity: 0.8;
}

.video-stream video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}


/****/

.book-now-card {
    padding: 15px 15px;
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 100%;
    -webkit-box-shadow: 0px -1px 5px 0px rgba(153, 148, 153, 1);
    -moz-box-shadow: 0px -1px 5px 0px rgba(153, 148, 153, 1);
    box-shadow: 0px -1px 5px 0px rgba(153, 148, 153, 1);
    z-index: 100;
    min-height: 80px;
}

.book-now-card .price span {
    color: #1a1a1a;
    font-size: 12px;
    font-weight: 300;
}

.book-now-card .price p {
    color: #1a1a1a;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.1;
}


/****/

.sharelink {
    position: absolute;
    top: 45px;
    z-index: 10;
    left: -22px;
    display: none;
    min-width: 190px;
    max-width: 190px;
}

.sharelink a {
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-left: 5px;
    text-align: center;
    line-height: 35px;
    font-size: 16px;
    margin-right: 0;
    color: #fff;
}

.twitter-color {
    background: #37b1e0
}

.facebook-color {
    background: #4b68b0
}

.google-plus-color {
    background: #e93f3a
}

.linkedin-color {
    background: #0579b6
}


/**social media**/

.sb-facebook {
    background: #3a559f;
}

.sb-twitter {
    background: #50abf1;
}

.sb-linkedin {
    background: #0084b1;
}

.sb-pinterest {
    background: #c9353d;
}

.sb-reddit {
    background: #FF4006;
}

.sb-mix {
    background: #eb4924;
}

.sb-vk {
    background: #4C75A3;
}

.sb-telegram {
    background: #08c;
}

.sb-messenger {
    background: #0080FF;
}

.sb-whatsapp {
    background: #25D366;
}

.sb-xing {
    background: #006567;
}

.sb-line {
    background: #00b900;
}

.sb-sms {
    background: #20c16c;
}

.sb-email {
    background: #FF961C;
}

.sb-copy {
    background: #607D8B;
}


/****/

.center-slider-wrp {
    background: #ffde00;
    xheight: 470x;
}

.mt-8 {
    margin-top: 80px;
}

.center-slider-wrp .uk-width-3-4 {
    margin: 0 5px;
    position: relative;
    xheight: 470px;
    overflow: hidden;
    width: 68%;
}

.center-slider-wrp .img-width {
    width: 100%;
    object-fit: cover;
}

.full-img {
    width: 100%;
    object-fit: cover;
    height: 100%
}

.center-slider-wrp .uk-width-3-4 .img-width {
    filter: brightness(40%);
    transition: 0.1s linear;
}

.center-slider-wrp .uk-width-3-4.uk-active .img-width {
    filter: brightness(100%);
    transition: 0.1s linear;
}


/* .uk-width-3-4::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.2s linear;
}

.uk-width-3-4.uk-active::before {
    display: none
} */


/****/

.wishlist-sharing {
    background-color: #fff;
    padding: 10px;
    min-width: 140px;
    display: table;
    margin: auto;
    border-radius: 20px;
    margin-bottom: 50px;
    margin-top: -20px;
}

.wishlist-sharing .fa-share-alt,
.wishlist-sharing .fa-bookmark-o,
.wishlist-sharing .fa-bookmark,
.opensharelink {
    font-size: 22px;
    color: #000
}

.listshare {
    width: 70px;
    text-align: center;
}

.info-section .listshare:first-child {
    border-left: 1px solid #ddd;
    padding-left: 1em;
    vertical-align: unset;
    cursor: pointer;
    font-size: 22px;
    color: #333333;
}

section.wrapper .position-relative.listshare {
    border-right: 1px solid #ddd;
}

section.wrapper .container a.listshare {
    display: inline-block;
}


/****/

.key-highlight-info-details .btn-default {
    padding: 8px 25px;
    font-weight: 500;
}

.key-highlight-info-details .btn-default img {
    margin-right: 10px;
    vertical-align: inherit;
}


/****/

.select-city-model .uk-modal-title {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 35px;
}

.city-card-m {
    width: 25%;
    text-align: center;
    margin-bottom: 15px;
}

.city-card-m a {
    display: inline-block;
}

.city-card-m a span {
    color: #1a1a1a;
    font-size: 14px;
    display: block;
    margin-top: 20px;
}

.city-card-m a:hover {
    opacity: 0.7;
    font-weight: 600;
}

.citymodel.uk-modal-container .uk-modal-dialog {
    width: 1100px;
    border-radius: 5px;
}

.citymodel .uk-modal-dialog {
    padding: 35px 50px 35px;
}

.citymodel .uk-close {
    color: #1a1a1a;
}


/****/

.hamburger-menu-main {
    background-color: #fff;
    padding: 10px 15px 30px 30px;
    position: fixed;
    right: 40px;
    top: 20px;
    z-index: 1010;
    min-width: 280px;
    border-radius: 5px;
}

.selected-city-card {
    margin-top: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-right: 15px;
}

.selected-city-card .icons {
    width: 55px;
    margin-right: 10px;
}

.selected-city-card .info h4 {
    font-size: 12px;
    color: #333333;
}

.selected-city-card .info p {
    font-size: 18px;
    color: #333333;
    line-height: 1.1;
}

.selected-city-card .info p img {
    margin-left: 10px;
}


/****/

.hamburger-accord {
    margin-right: 15px;
    margin-top: 25px;
}

.uk-accordion-title {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 500;
}

.hamburger-accord-innerlist,
.hamburger-accord-outerlist {
    padding-left: 0;
}

.hamburger-accord-innerlist li a {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 300;
    margin: 5px 0;
    display: inline-block;
}

.hamburger-accord-outerlist li a {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0;
    display: inline-block;
}

.hamburger-menu-main {
    right: -100%;
    transition: 0.2s linear;
}

.hamburger-menu-right {
    right: 25px;
}

.hamburger-accord .uk-open>.uk-accordion-title::before {
    background-image: none;
    transition: 0.2s linear;
    content: "\f106";
}

.hamburger-accord .uk-accordion-title::before {
    background-image: none;
    transition: 0.2s linear;
    content: "\f107";
    font-family: 'FontAwesome';
    font-size: 20px;
    line-height: 1;
    color: #333;
}

.hamburger-accord-outerlist.mt-2 {
    margin-top: 10px !important;
}


/****/

.heading-w-border {
    color: #000;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 35px;
    position: relative;
    overflow: hidden;
    width: calc(100% - 80px);
    font-family: Maven Pro;
    font-weight: 600;
}

.heading-w-border>span {
    position: relative;
    display: inline-block;
}

.heading-w-border::after {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-left: 20px;
    width: 100%;
}

.heading-w-border.border-80per::after {
    xwidth: 80%;
}

.heading-w-border>span:before {
    right: 100%;
}

.spotlight-card {
    box-shadow: 0px 0px 5px #ddd;
    padding: 35px;
    margin-bottom: 15px;
    border-radius: 10px;
    border-right: 4px solid #f9d900;
    border-bottom: 4px solid #f9d900;
}

.spotlight-card h4 {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 15px;
}

.spotlight-card p {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8;
}

.the-plan-wrp .heading-w-border::after {
    width: 88%;
}


/****/

.inclusion-card {
    margin-bottom: 40px;
    padding-right: 30px;
}

.inclusion-card .icon {
    margin-right: 15px;
    width: 20px;
    text-align: center;
}

.inclusion-card .info {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8;
    width: calc(100% - 20px);
}


/****/

.terms-wrp h5 {
    color: #1a1a1a;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 20px;
}

.terms-wrp ul {
    list-style-type: disc;
    padding-left: 15px;
}

.terms-wrp ul li {
    color: #1a1a1a;
    font-weight: 300;
    margin: 20px 0;
}


/****/

.gray-accordion .uk-accordion-title,
.gray-accordion .acc-title {
    background: transparent;
    padding: 15px 15px 15px 0px;
    border-radius: 0px;
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 500;
    display: block;
    line-height: 1.4;
    overflow: hidden;
    border-bottom: 1px solid #cccccc;
}

.gray-accordion .uk-accordion-content p {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.8
}


/****/

.container-1050 {
    max-width: 1050px;
}

.tabs-link {
    border: 1px solid #ddd;
    border-radius: 5px;
}

.tabs-link li a span {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
}

.tabs-link li a {
    color: #333333;
    font-size: 16px;
    text-transform: capitalize;
    height: 52px;
}

.tabs-link.uk-tab>*>a {
    display: block !important;
    text-align: center;
    padding: 5px 10px;
}

.tabs-link li a b {
    font-weight: 400;
}

.tabs-link.uk-tab>* {
    padding-left: 20px;
    padding-right: 20px;
}

.tabs-link.uk-tab {
    margin-left: 0;
}

.tabs-link.uk-tab>.uk-active>a {
    border-color: transparent;
}

.tabs-link li {
    position: relative;
}

.tabs-link li a i {
    font-style: normal;
}

.tabs-link li.uk-active>a i {
    border-bottom: 4px solid #ffde00;
    padding: 0 20px 7px;
}

.tabs-link li .icon {
    position: absolute;
    top: 10px;
    right: -10px;
    z-index: 10;
}

.tabs-link li::after {
    position: absolute;
    border: 1px solid #ddd;
    content: "";
    width: 36px;
    height: 36px;
    top: 8px;
    transform: rotate(130deg);
    border-right: none;
    border-bottom: none;
    right: 0;
    box-shadow: -5px -5px 5px #ddd;
}

.tabs-link li:last-child::after {
    display: none
}

.confirmation-wrp .highlight-point h4 {
    color: #333333;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}

.confirmation-wrp .highlight-point p {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 300;
}

.order-details-wrp .w-40,
.order-details-wrp .w-60 {
    border: 1px solid #ddd;
    xborder-radius: 5px
}

.order-details-wrp .details-card {
    xborder: 1px solid #ddd;
    padding: 25px 10px;
    border-radius: 5px
}

.order-details-wrp .details-card h6 {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
}

.order-details-wrp .details-card h6 img {
    margin-right: 10px;
}

.order-details-wrp .details-card .image {
    width: 100%;
    height: 240px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.order-details-wrp .details-card .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sharing-card-wrp {
    margin: 20px;
    xborder-bottom: 1px solid #ddd;
    xpadding-bottom: 20px;
}

.sharing-card-wrp h5 {
    font-size: 14px;
    font-weight: 300;
    color: #333333;
    margin-bottom: 10px;
}

.sharing-card-wrp h5 img {
    margin-right: 10px;
}

.sharing-card-wrp h4 {
    font-size: 25px;
    color: #333333;
    margin-bottom: 5px;
}

.sharing-card-wrp .wishticons {
    margin: 0 10px;
    margin-top: 0px;
    font-size: 18px;
    color: #1a1a1a;
    margin-top: 3px;
}

.sharing-card-wrp span {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
    margin-right: 20px;
}


/****/

.date-and-time-wrp {
    margin: 0 20px;
}

.date-and-time-wrp .dt-card h4 {
    font-size: 18px;
    color: #333333;
    margin-bottom: 15px;
    font-weight: 500;
}

.date-and-time-wrp .dt-card p {
    font-size: 15px;
    color: #1a1a1a;
    margin-bottom: 2px;
}

.date-and-time-wrp .dt-card span {
    font-size: 15px;
    color: #00a380;
}

.date-and-time-wrp .info a {
    color: #1a1a1a;
    font-size: 15px;
    text-decoration: underline;
}

.date-and-time-wrp .uk-form-controls .uk-select {
    padding: 0;
    padding-right: 0px;
    color: #333333;
    border: none;
    font-size: 15px;
    color: #00a380;
}

.date-and-time-wrp .uk-form-controls .uk-select:not([multiple]):not([size]) {
    background-image: url("assets/images/icons/arrow-bottom.png");
    background-position: 75% 50%;
}

.date-and-time-wrp .dt-card {
    width: 60%;
    border-right: 1px solid #ddd
}

.date-and-time-wrp .info {
    width: 40%;
    padding-left: 20px;
}

.date-and-time-wrp .info p {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
}


/****/

.order-details-wrp .w-60 {
    margin-left: 20px;
    width: calc(60% - 20px) !important;
}

.voucher-code {
    border-radius: 5px;
    xborder: 1px solid #ddd;
    padding: 40px
}

.voucher-code h4 {
    font-size: 18px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 30px;
}

.voucher-code .code {
    color: #333333;
    font-size: 35px;
    font-weight: 300;
    border: 2px dashed #ffde00;
    border-radius: 20px;
    display: inline-block;
    padding: 15px 60px;
    margin-bottom: 20px;
}

.voucher-code p {
    font-size: 14px;
    color: #1a1a1a;
    padding: 0 45px;
    line-height: 1.8;
    font-weight: 300;
}


/****/

.order-summery {
    xborder-radius: 5px;
    xborder: 1px solid #ddd;
    padding: 25px;
    margin-top: 20px;
    border-bottom: 1px solid #ddd;
    position: relative;
}


/* .order-summery::before {
    content: "";
    width: 100%;
    height: 20px;
    background-color: red;
    position: absolute;
    bottom: 0;
} */

.order-summery-card {
    border-right: 1px solid #ddd;
    padding-right: 30px;
    width: 65%;
    position: relative
}

.rightangle::after {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #ddd;
    position: absolute;
    content: "";
    right: -10px;
    top: 50%;
    transform: translate(0, -50%);
}

.order-summery-card h5 {
    font-size: 18px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 15px;
}

.order-summery-card .order-price {
    flex-direction: column
}

.order-summery-card .order-price .w-50 {
    width: 100% !important
}

.order-summery-card p {
    font-size: 15px;
    color: #1a1a1a;
    xmargin-bottom: 10px;
    min-height: 45px;
}

.order-summery-card h4 {
    font-size: 30px;
    color: #1a1a1a;
    font-weight: 500;
}

.order-summery-card h4 .fa-rupee {
    font-size: 65%;
}

.order-table p {
    color: #333333;
    font-size: 13px;
    margin-bottom: 0;
}

.order-table h6 {
    color: #1a1a1a;
    font-size: 15px;
    font-weight: 500;
    text-align: right;
}

.order-table {
    border-top: 1px solid #ddd;
    margin-top: 15px;
    padding-top: 15px;
}


/****/

.order-summery .order-details {
    width: calc(100% - 65%);
}

.order-summery .order-details h5 {
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 50px;
}

.order-summery .order-details p {
    color: #333333;
    font-size: 18px;
    font-weight: 500;
}

.order-summery .order-details h4 {
    color: #1a1a1a;
    font-size: 30px;
    font-weight: 500;
}

.order-summery .order-details h4 .fa-rupee {
    font-size: 65%;
}

.cashback {
    background: #beeddb;
    padding: 12px 10px;
    font-size: 15px;
    color: #535050;
    border-radius: 8px;
}

.cashback .cashback-icon {
    padding-right: 10px;
}


/****/

.need-help {
    background-color: #ebebeb;
    padding: 20px 30px;
}

.need-help .info h4 {
    color: #333333;
    font-size: 18px;
    font-weight: 500;
}

.need-help .info p {
    color: #1a1a1a;
    font-size: 14px;
    font-weight: 300;
}

.need-help .number a {
    background-color: #fff;
    border-radius: 20px;
    padding: 8px 40px;
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
}

.need-help .number a img {
    vertical-align: baseline;
}

.border-btn {
    color: #1a1a1a;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 20px;
    border: 1px solid #ddd;
    display: inline-block;
    padding: 10px 30px;
    transition: 0.2s linear;
}

.border-btn:hover {
    background-color: #ffde00;
    color: #1a1a1a;
}


/****/

.ordersummary-wrp .order-details-wrp .details-card {
    padding: 10px 10px 35px;
    xmin-height: 565px;
}


/****/

.quantity {
    display: flex;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.quantity button {
    width: 45px;
    height: 35px;
    border: none;
    color: #333333;
    font-size: 16px;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
}

.quantity input {
    -webkit-appearance: none;
    border: none;
    text-align: center;
    width: 60px;
    font-size: 16px;
    color: #333333;
}


/****/

.ordersummary-wrp .order-summery {
    margin-top: 0px;
}

.discount-code {
    border-top: 1px solid #ddd;
    margin-top: 20px;
    padding-top: 10px;
}

.discount-code .code-field {
    width: 60%
}

.discount-code .code-field label {
    color: #333333;
    margin-bottom: 10px;
    display: inline-block
}

.discount-code .redclose {
    position: absolute;
    right: 15px;
    top: 42px;
}

.discount-code .uk-input {
    border-radius: 0px;
    color: #333333;
    font-size: 16px;
    padding-right: 35px;
    border-bottom: 1px solid #ddd;
}

.code-apply,
.code-apply:hover {
    color: #999999;
    font-size: 15px;
    text-decoration: underline;
    margin-left: 25px;
    display: inline-block;
    margin-top: 35px;
}

.code-applied {
    text-align: right;
    padding-left: 20px;
    margin-bottom: 10px;
}

.promo-code-applied {
    border-top: 1px solid #ddd;
    margin-top: 15px;
    padding-top: 35px;
}

.promo-code-applied h6 {
    color: #333333;
    font-size: 13px;
    margin-bottom: 5px;
}

.promo-code-applied span {
    color: #1a1a1a;
    font-size: 15px;
}

.promo-code-applied span .fa-rupee {
    font-size: 65%
}

.order-summery .total-amount {
    border-top: 1px solid #ddd;
    margin-top: 20px;
    padding-top: 20px;
}

.order-summery .total-amount p {
    color: #333333;
    font-size: 18px;
    font-weight: 500;
}

.order-summery .total-amount h4 {
    border-left: 1px solid #ddd;
    padding: 5px 0 0 25px;
    color: #1a1a1a;
    font-size: 30px;
    font-weight: 500;
}

.order-summery .total-amount h4 .fa-rupee {
    font-size: 65%
}


/****/

.order-details-profile {
    xborder-radius: 5px;
    xborder: 1px solid #ddd;
    padding: 15px 25px;
    border-top: 1px solid #ddd;
    position: relative;
}

.order-details-profile::before {
    content: "";
    width: 101%;
    height: 19px;
    background-color: #fff;
    position: absolute;
    top: -21px;
    left: -2px;
}

.order-details-profile p {
    color: #333333;
    font-size: 18px;
    font-weight: 500;
}

.order-details-profile h4 {
    color: #333333;
    font-size: 20px;
    text-align: center;
}

.profile-icon {
    width: 57px;
    height: 58px;
    background: url("assets/images/icons/profile-icon.png") no-repeat;
    margin: auto;
    margin-bottom: 10px;
}

.profile-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #ddd;
}

.profile-link-btn {
    border: 1px solid #ddd;
    margin: 10px;
    display: inline-block;
    padding: 8px 15px;
    color: #1a1a1a;
    font-size: 15px;
    min-width: auto;
}

.profile-link-btn:hover {
    color: #1a1a1a;
    background-color: #ffde00
}

.select-people-drop .uk-select:not([multiple]):not([size]) {
    background-image: url("assets/images/icons/arrow-bottom.png");
    background-position: 90% 50%;
}

.select-people-drop .uk-select {
    border-radius: 5px;
    border: 1px solid #ddd;
    color: #333333;
    font-size: 14px;
}

.edit-btn-wrp {
    border-top: 1px solid #ddd;
    margin-bottom: 20px;
}

.edit-btn-wrp .edit-btn {
    color: #999999;
    font-size: 12px;
    border: 1px solid #ddd;
    text-decoration: none;
    display: inline-block;
    padding: 2px 20px;
    border-radius: 20px;
    position: absolute;
    top: -12px;
    background: #fff;
    right: 55px;
}

.date-and-time-wrp .uk-input {
    border: none;
    width: 90%;
    padding: 0;
    color: #1a1a1a;
    font-size: 15px;
    height: initial
}

.date-and-time-wrp input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url("assets/images/icons/arrow-bottom.png") no-repeat;
    width: 10px;
    height: 6px;
    border-width: thin;
    cursor: pointer;
    background-position: right
}

.date-and-time-wrp input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 25px;
}


/****/

.terms-wrp {
    padding-left: 15px;
}


/**22 Sep 2021**/

.container-1080 {
    max-width: 1080px;
}

.myprofile-left-panel {
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 25px 20px
}

.myprofile-left-panel .profile-photo {
    width: 116px;
    height: 117px;
    background: url("assets/images/icons/profile-icon-big.png");
    border-radius: 50%;
    margin: auto;
    overflow: hidden
}

.myprofile-left-panel .profile-photo .profileimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.opencamera {
    position: absolute;
    right: 10px;
    z-index: 1;
    bottom: 0;
}

.myprofile-info h4 {
    font-size: 20px;
    color: #333333;
}

.myprofile-info p {
    font-size: 14px;
    color: #333333;
    margin-top: 10px;
}

.myprofile-info p img {
    width: 9px;
    vertical-align: inherit;
    margin-right: 3px;
}


/****/

.myprofile-left-panel .uk-subnav {
    margin-left: 0;
}

.myprofile-left-panel .uk-subnav>* {
    padding-left: 0;
    width: 100%;
}

.myprofile-left-panel .uk-subnav li>a {
    padding: 15px 20px;
    border-top: 1px solid #ddd;
    color: #1a1a1a !important;
    font-size: 15px !important;
    transition: 0.2s linear;
    -webkit-box-shadow: inset 8px -14px 0px -11px #fff, inset 8px 14px 0px -11px #fff;
    -moz-box-shadow: inset 8px -14px 0px -11px #fff, inset 8px 14px 0px -11px #fff;
    box-shadow: inset 8px -14px 0px -11px #fff, inset 8px 14px 0px -11px #fff;
}

.myprofile-left-panel .uk-subnav>.uk-active>a {
    background: #ffde00;
    -webkit-box-shadow: inset 8px -14px 0px -11px #fff, inset 8px 14px 0px -11px #fff;
    -moz-box-shadow: inset 8px -14px 0px -11px #fff, inset 8px 14px 0px -11px #fff;
    box-shadow: inset 8px -14px 0px -11px #fff, inset 8px 14px 0px -11px #fff;
    position: relative;
    transition: 0.2s linear;
}

.myprofile-left-panel .uk-subnav>.uk-active>a::after {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #ffde00;
    position: absolute;
    content: "";
    right: -9px;
    transition: 0.2s linear;
}

.pl-25 {
    padding-left: 25px;
}

.mybucketlist-wrp .listing-card {
    width: 46%;
    margin: 0 2% 50px;
}

.pagination .uk-pagination {
    align-items: center;
}

.pagination .uk-pagination>li>a {
    color: #acacac;
    font-size: 18px;
    padding: 0;
    margin: 0 18px;
}

.pagination .uk-pagination>.uk-active>* {
    color: #ffde00;
    font-weight: bold;
    font-size: 30px;
    border-bottom: 1px solid #ffde00;
}


/****/

.myprofile-order-tab.uk-subnav {
    margin-left: 20px;
}

.myprofile-order-tab.uk-subnav>* {
    padding-left: 0px;
}

.myprofile-order-tab.uk-subnav li>a {
    border: 1px solid #ddd;
    border-bottom: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 8px 20px;
    color: #1a1a1a;
    font-size: 13px;
    font-weight: 500;
}

.myprofile-order-tab.uk-subnav-pill>.uk-active>a {
    background-color: #ffde00;
    color: #1a1a1a;
}


/****/

.myorder-card {
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 15px;
}

.myorder-card .image {
    height: 230px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
}

.myorder-card .image img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.myorder-card .details {
    padding-left: 15px;
}

.myorder-card .details .location-info a {
    color: #1a1a1a;
    font-size: 13px;
    text-decoration: none;
    background: #ffde00;
    margin-bottom: 15px;
    display: inline-block;
    padding: 0.3rem 1rem;
    border-radius: 30px;
}

.myorder-card .details .location-info p {
    color: #333333;
    font-size: 14px;
}

.myorder-card .details .location-info p img {
    margin-right: 5px;
}

.myorder-card .details .order-name h4 {
    color: #1a1a1a;
    font-size: 20px;
    margin-bottom: 5px;
}

.myorder-card .details .order-name p {
    color: #1a1a1a;
    font-size: 12px;
    margin-bottom: 15px;
    background: #ccc;
    display: inline-block;
    padding: 0.1rem 0.5rem;
    border-radius: 30px;
}

.myorder-card .details .order-details {
    border-top: 1px solid #ddd;
    padding: 5px 10px 5px 0
}

.myorder-card .details .order-details p {
    color: #1a1a1a;
    font-size: 13px;
    padding: 10px 0;
}

.myorder-card .details .order-details h3 {
    color: #1a1a1a;
    font-size: 15px;
    padding: 10px 0;
}

.myorder-card .details .order-details h6 {
    color: #00a380;
    font-size: 13px;
    padding: 10px 0 10px 20px;
    border-left: 1px solid #ddd;
    text-align: right
}

.myorder-card .details .order-details h4 {
    color: #1a1a1a;
    font-size: 28px;
    padding: 10px 0 10px 20px;
    border-left: 1px solid #ddd;
    text-align: right
}

.myorder-card .details .order-details h4 .fa-rupee {
    font-size: 60%;
}


/****/

.myprofile-form-wrp {
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 20px 25px;
    margin-bottom: 15px;
}

.profile-heading {
    font-size: 18px;
    color: #333333;
    font-weight: 500;
}

.full-border-form label {
    color: #999999;
    display: block;
    margin-bottom: 10px;
}

.full-border-form .form-group {
    margin-bottom: 25px;
}

.full-border-form .uk-input,
.full-border-form .uk-select {
    color: #1a1a1a;
    border: 1px solid #ddd;
    font-size: 15px;
    border-radius: 5px
}

.full-border-form [class*=uk-inline] {
    display: block;
}

.full-border-form .btn-yellow {
    border: 1px solid transparent;
    cursor: pointer;
}

.full-border-form input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url("assets/images/icons/calendar-3.png") no-repeat;
    width: 16px;
    height: 18px;
    border-width: thin;
    cursor: pointer;
    background-position: left
}

.full-border-form input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    left: -13px;
}

.full-border-form .select-people-drop .uk-select:not([multiple]):not([size]) {
    background-image: url("assets/images/icons/arrow-bottom.png");
    background-position: 96% 50%;
}

.hide {
    display: none;
}

.profile-form-field .btn-yellow {
    display: none;
}


/****/

#MyBucket,
#MyProfile,
#MyCoupons,
#MyPollHistory {
    display: none;
}

.custom-file input[type='file'] {
    display: none
}

.ordersummary-other-details .btn-yellow {
    border: none
}


/****/

.categores-card-2 {
    background-color: #fff;
    padding: 20px 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #ddd;
    margin-bottom: 20px;
}

.categories-slider-2 .uk-slider-container {
    overflow: hidden;
    padding-bottom: 20px;
    padding-top: 30px;
}

.categores-card-2 .icons {
    padding: 30px 20px;
    border-radius: 10px;
    margin-bottom: 15px;
    text-align: center;
    margin-top: -40px;
    background-color: #fff;
}

.border-green {
    border-right: 1px solid #a6e8aa;
    border-bottom: 5px solid #a6e8aa;
}

.border-red {
    border-right: 1px solid #f1956e;
    border-bottom: 5px solid #f1956e;
}

.border-orange {
    border-right: 1px solid #fcb96b;
    border-bottom: 5px solid #fcb96b;
}

.border-blue {
    border-right: 1px solid #b0cffe;
    border-bottom: 5px solid #b0cffe;
}

.border-purple {
    border-right: 1px solid #c3c2e2;
    border-bottom: 5px solid #c3c2e2;
}

.border-valentine {
    border-right: 1px solid #ff99ff;
    border-bottom: 5px solid #ff99ff;
}

.border-darkpurple {
    border-right: 1px solid #571253;
    border-bottom: 5px solid #571253;
}

.border-red-deal {
    border-right: 1px solid #dd3434;
    border-bottom: 5px solid #dd3434;
}

.categores-card-2 p {
    font-size: 15px;
    text-align: center;
    font-weight: 500;
    color: #333333;
    text-transform: uppercase;
}

.pb-0 {
    padding-bottom: 0px !important;
}


/****/

.popular-experiences-card {
    background-color: #fff;
    box-shadow: 0px 0px 5px #ddd;
    border-radius: 15px;
    padding: 20px 20px 20px 0;
    xmargin: 0 2%
}

.popular-experiences-card .image {
    width: 100%;
    height: 345px;
    border-radius: 15px;
    overflow: hidden;
    margin-left: -20px;
}

.popular-experiences-card .image img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.popular-experiences-card .details .logo {
    width: 149px;
    height: 60px;
    margin: auto;
    margin-bottom: 10px !important;
}

.popular-experiences-card .details .logo img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.popular-experiences-card .details h5,
.popular-experiences-card .details h5 a {
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
}

.popular-experiences-card .details h6 {
    color: #333333;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.popular-experiences-card .details h6 {
    color: #333333;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.popular-experiences-card .location {
    border: 1px solid #ddd;
    border-radius: 20px;
    display: inline-block;
    padding: 5px 20px;
    color: #333333;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 10px;
    position: relative;
}

.popular-experiences-card .location::before {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    width: 15%;
    left: -30px;
}

.popular-experiences-card .location::after {
    content: '';
    position: absolute;
    top: 50%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    width: 15%;
    right: -30px;
}

.popular-experiences-card .location img {
    width: 9px;
    margin-right: 5px;
    vertical-align: baseline;
}

.popular-experiences-card del {
    display: block;
    color: #f66c40;
    font-size: 14px;
}

.popular-experiences-card h4 {
    color: #1a1a1a;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.1;
    margin-top: 5px;
}

.popular-experiences-card h4 .fa-rupee {
    font-size: 60%;
}

.popular-experiences-card p {
    color: #333333;
    font-size: 13px;
    margin-bottom: 15px;
}

.popular-experiences-card .vouchers {
    display: block;
    color: #ff3300;
    font-size: 12px;
    margin-top: 10px;
    display: none;
}

.trending {
    display: inline-block;
    padding: 7px 20px;
    position: absolute;
    top: -38px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    left: 30px;
    min-height: 38px;
}

.trending img {
    margin-right: 10px;
}

.trending span {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

.bg-orange {
    background-color: #f66c40
}

.bg-pink {
    background-color: #e26868
}

.bg-purple {
    background-color: #6a68e2
}

.bg-pinkk {
    background-color: #e50097
}

.bg-red {
    background-color: #e10000
}

.bg-womensday {
    background-color: #e7839e
}

.bg-deal-pe-deal {
    background-color: #db122a;
}

.bg-delivery-box {
    background-color: #147904;
}

.bg-dine-in-deal {
    background-color: #790417;
}

.w-55 {
    width: 55%
}

.w-45 {
    width: 45%
}


/****/

.touch-slider .custom-nav svg {
    display: none;
}

.touch-slider .uk-position-center-left-out {
    right: 30px;
    top: -63px;
}

.touch-slider .uk-position-center-right-out {
    right: 0px;
    transform: rotate(180deg);
    top: -80px;
    left: initial;
}

.new-arrive-inner-slider .touch-slider .uk-position-center-left-out {
    top: -61px;
}

.new-arrive-inner-slider .touch-slider .uk-position-center-right-out {
    top: -78px;
}


/****/

.img-full {
    width: 100%;
}

.border-radius-banner {
    border-radius: 15px;
}

.trending-wrp {
    padding-top: 50px;
    background-color: #eaeaea;
    padding-bottom: 50px;
}

.bg-white {
    background-color: #fff !important
}

.trending-wrp .uk-slider-container,
.new-arrival-wrp .uk-slider-container {
    padding-top: 25px;
    padding-bottom: 20px;
}

.new-arrival-wrp .uk-slider-container {
    padding-top: 20px;
}

.trending-wrp::before {
    position: absolute;
    content: "";
    background: url("assets/images/yellow-bg-2.jpg") repeat;
    height: 350px;
    top: 0;
    background-position: center center;
    width: 100%;
}

.trending-card {
    background-color: #fff;
    box-shadow: 0px 0px 5px #ddd;
    border-radius: 15px;
    padding: 0px 20px 20px 20px;
    text-align: center;
}

.trending-card .image {
    width: 100%;
    height: 180px;
    border-radius: 20px;
    position: relative;
    top: -20px;
}

.trending-card .image img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}

.trending-card .logo {
    /* height: 65px;*/
    text-align: left;
}

.logo p {
    font-size: 16px;
    color: #1a1a1a;
    font-weight: 500;
}

.trending-card .logo img {
    height: 100%;
    width: auto;
}

.trending-card h5 {
    color: #333333;
    margin-top: 15px;
    font-size: 14px;
    margin-bottom: 10px;
    text-align: left;
}

.trending-card h5 img {
    width: 8px;
    margin-right: 5px;
    vertical-align: baseline;
}

.trending-card .info {
    /* border-top: 1px solid #ddd; */
    /* padding-top: 15px; */
    display: flex;
    align-items: center;
    justify-content: space-between
}

.trending-card .info p {
    color: #333333;
    font-size: 12px;
}

.trending-card .info h4 {
    color: #1a1a1a;
    font-size: 20px;
    font-weight: 500;
}

.fa-rupee-small {
    font-size: 60%;
    font-weight: normal;
}


/****/

.filter-wrp {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    position: relative;
    z-index: 1003
}

.filter-wrp-inner {
    padding: 10px 0px
}

.filter-wrp-inner .filter-body {
    border-right: 1px solid #ddd;
    padding: 5px 25px
}

.filter-wrp-inner .filter-text.filter-body {
    padding-right: 50px;
}

.filter-wrp-inner .select-sort.filter-body {
    border-right: none;
    padding: 0
}

.filter-wrp-inner button {
    background-color: transparent;
    border: none;
    font-size: 15px;
    color: #1a1a1a;
    font-family: 'Poppins', sans-serif;
}

.selected-text {
    font-size: 15px;
    color: #1a1a1a;
    display: inline-block;
    text-align: left;
}

.select-location .selected-text,
.select-price .selected-text {
    width: 140px;
    cursor: pointer;
}

.filter-wrp-inner .icon {
    margin-right: 15px;
}

.filter-wrp-inner .select-location .icon {
    width: 11px
}

.sort-card {
    border-left: 1px solid #ddd !important;
    padding: 5px 45px 5px 25px;
    cursor: pointer;
}

.select-location .filter-dropdown-body {
    left: 98px;
    top: -10px !important;
    width: 300px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #fff
}

.select-price .filter-dropdown-body {
    left: 329px !important;
    top: -10px !important;
    width: 300px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #fff
}

.select-sort .filter-dropdown-body {
    left: 830px !important;
    top: -10px !important;
    width: 290px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: #fff
}

.filter-dropdown-body .uk-search-default .uk-search-input {
    background: #fff
}


/****/

.filter-wrp-inner .uk-dropdown {
    padding: 20px 15px;
    box-shadow: none;
}

.close-dropdown img {
    width: 20px;
    cursor: pointer;
}

.close-dropdown p {
    color: #1a1a1a;
    font-size: 15px;
    margin-left: 20px;
}

.uk-search-default .uk-search-input {
    border: none;
    border-bottom: 1px solid #ddd;
}

.filter-search svg {
    display: none;
}

.filter-search.uk-search-default .uk-search-input {
    height: 30px;
    padding-bottom: 15px;
}

.filter-search.uk-search .uk-search-icon {
    color: rgba(0, 0, 0, 0.5);
    padding-bottom: 15px;
    font-size: 18px;
}

.filter-search.uk-search-default .uk-search-icon {
    width: 25px;
}

.filter-search.uk-search-default .uk-search-icon:not(.uk-search-icon-flip)~.uk-search-input {
    padding-left: 35px;
}

.filter-search .uk-search-input {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
}

.dropdown-card-content {
    padding: 0px 20px 30px
}


/**Custom CheckBox**/

.custom-checkbox .input-helper {
    position: relative;
    display: inline-block;
}

.custom-checkbox .input-helper:before {
    content: '';
    display: block;
    position: absolute;
}

.custom-checkbox .input-helper--checkbox {
    padding-left: 20px;
}

.custom-checkbox .input-helper--checkbox:before {
    top: -2px;
    left: 0;
    width: 25px;
    height: 25px;
    border: 1px solid #e3e3e3;
}

.custom-checkbox input[type=checkbox],
.custom-checkbox input[type=radio] {
    display: none;
}

.custom-checkbox input[type=checkbox]:checked+label:before,
.custom-checkbox input[type=radio]:checked+label:before {
    background: #ffde00;
    border: 1px solid #ffde00;
    box-shadow: 0px 0px 0px 6px inset #fff;
}

.checkbox-group label span {
    display: inline-block;
    padding-left: 20px;
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 300;
}

.checkbox-group label span .fa-rupee {
    font-size: 90%;
    font-weight: 200;
}

.mb-15 {
    margin-bottom: 15px;
}

.dropdown-card-content .btn-group .btn-default {
    padding: 8px 25px;
    color: #1a1a1a;
    font-size: 12px;
    border: 1px solid #d7d7d7;
    font-weight: 500;
}

.dropdown-card-content .btn-group .btn-yellow {
    color: #1a1a1a;
    font-size: 12px;
    font-weight: 600;
}

.hideme {
    display: none;
}


/**Custom Scroll Bar**/

.customscroll {
    height: 280px
}

.mCSB_container {
    padding-top: 10px;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #ffde00;
}

.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #e3e3e3;
}

.mCSB_scrollTools .mCSB_draggerRail {
    width: 10px;
    border-radius: 0;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 10px;
    border-radius: 0;
}

.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
    -webkit-transition: opacity 1 ease-in-out, background-color .2s ease-in-out;
    -moz-transition: opacity 1 ease-in-out, background-color .2s ease-in-out;
    -o-transition: opacity 1 ease-in-out, background-color .2s ease-in-out;
    transition: opacity 1 ease-in-out, background-color .2s ease-in-out;
}

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: #ffde00;
}

.mCSB_scrollTools {
    opacity: 1;
}

.mCSB_scrollTools .mCSB_draggerContainer {
    background-color: #e3e3e3;
    width: 10px;
}


/****/

.arrived-in-town .touch-slider .uk-position-center-right-out {
    top: -47px;
}

.arrived-in-town .touch-slider .uk-position-center-left-out {
    right: 30px;
    top: -35px;
}


/****/

.new-arrive-inner-slider .w-46 {
    width: 100%
}

.new-arrive-inner-slider .uk-slider-container {
    padding-left: 20px;
}

.datepicker input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
}

.datepicker input::after {
    /* content: ""; */
    /* background: url(arrow-bottom-2.png) no-repeat; */
    /* width: 14px; */
    /* height: 8px; */
    content: "\f107";
    font-family: 'FontAwesome';
    font-size: 23px;
    line-height: 0.5;
}

.datepicker input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    color: #333333;
    font-size: 12px;
    width: auto;
}

.center-slider-offer .uk-width-3-4 {
    width: 60%;
}


/**25 Oct**/

.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
    background-color: transparent;
}


/**29 Oct**/

.logout-drop .uk-card-default {
    background: #fff;
    position: relative;
}

.logout-drop .uk-card-default::after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    content: "";
    top: -10px;
    right: 20px;
}

.logout-drop .uk-card-default {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    justify-content: space-around;
}

.logout-drop .uk-card-default:hover {
    background-color: #fff;
}

.logout-drop .uk-card-default p {
    width: 100%;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 15px
}

.logout-drop .btn-default {
    color: #333333 !important;
    background: #fff;
}

.logout-drop .btn-default:hover {
    color: #333333 !important;
    background: #ffde00;
}


/****/

.order-summery .total-amount p {
    padding-right: 30px;
    width: 65%;
    border-right: 1px solid #ddd;
}

.order-summery .total-amount h4 {
    border-left: none;
    padding: 0px 0 0 20px;
    width: calc(100% - 65%);
    text-align: right;
}

.order-details-profile {
    xmin-height: 320px;
}

.ordersummary-other-details .btn-yellow {
    padding: 10px 50px;
    font-size: 18px;
    font-weight: 500;
    float: right;
    margin-top: 25px !important;
}

.ordersummary-other-details .cancel-policy {
    clear: both;
    margin-top: 0px !important;
    padding-top: 30px;
}


/**31 Oct**/

.categories-wrp-inner .text-info h4 {
    font-size: 40px;
    font-weight: 700;
    /* font-family: 'Playfair Display', serif;*/
    font-family: 'Maven Pro';
    color: #1a1a1a;
    margin-bottom: 15px;
}

.categories-wrp-inner .text-info h4 span {
    letter-spacing: -3px;
}

.categories-wrp-inner .text-info h4 span .large-font {
    font-size: 48px;
}

.categories-wrp-inner .text-info p {
    font-size: 18px;
    font-weight: 300;
    color: #333333;
    padding-bottom: 30px;
}

.line-black {
    Width: 150px;
    border-top: 2px solid #1a1a1a;
    margin: auto
}

.categories-wrp-inner .text-info h3 {
    color: #1a1a1a;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 15px;
}

.filter-new-card .image {
    height: 200px;
    border-radius: 8px;
    object-fit: cover;
    overflow: hidden;
    transition: 0.2s ease-in;
}

.filter-new-card p {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    text-align: center;
}

.filter-new-card .uk-position-cover {
    border-radius: 7px;
    overflow: hidden;
    padding: 15px;
}

.filter-new-card .uk-position-cover .uk-position-center {
    padding: 15px;
}

.filter-new-card .uk-position-cover h5 {
    color: #ebebeb;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
}

.w-23 {
    width: 22%
}

.pt-0 {
    padding-top: 0px !important;
}

.filter-new-card .uk-dark.active+p {
    display: none;
}

.filter-new-card .active {
    transform: scale(1.1, 1.1);
    position: relative;
    transition: 0.2s ease-in;
}

.filter-new-card .uk-dark.active .uk-overlay-default {
    background: none;
}

.uk-overlay-default {
    background: rgba(0, 0, 0, .5);
}

.uk-box-shadow-bottom::after {
    bottom: 12px;
}

.filter-new-card .close {
    background-color: #ffde00;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-weight: 500;
    position: absolute;
    bottom: -12px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    font-size: 14px;
    color: #1a1a1a;
    cursor: pointer;
    display: none;
}

.filter-new-card .uk-dark.active>.close {
    display: block;
}

.categories-overlap::before {
    content: unset;
}

.category-yellow-bg {
    background-color: #ffde00;
    padding-bottom: 4em;
}

.explore-mar {
    margin-top: -6em;
}


/****/

.new-booking-card {
    border-radius: 10px;
    border: 1px solid #ddd;
}

.new-booking-card .time-slot,
.new-booking-card .guest,
.new-booking-card .dates {
    padding: 10px 15px
}

.new-booking-card .guest,
.new-booking-card .time-slot {
    border-top: 1px solid #ddd;
}

.new-booking-card .guest .info a {
    border: 1px solid #ddd;
    display: inline-block;
    margin-right: 5px;
    font-size: 12px;
    color: #1a1a1a;
    padding: 2px 10px;
    border-radius: 3px
}

.new-booking-card .icon {
    width: 30px;
    height: 30px;
    background-color: #ddd;
    margin-right: 10px;
}

.new-booking-card .info {
    width: calc(100% - 40px);
}

.new-booking-card .info h4 {
    font-size: 14px;
    font-weight: 600;
}

.mb-05 {
    margin-bottom: 5px;
}

.circle-checkbox .custom-checkbox .input-helper--checkbox::before {
    width: 12px;
    height: 12px;
    border-radius: 50px;
    top: 4px;
}

.circle-checkbox .checkbox-group label span {
    padding-left: 2px;
    font-size: 12px;
}

.circle-checkbox .custom-checkbox input[type="radio"]:checked+label::before {
    box-shadow: 0px 0px 0px 3px inset #fff;
}


/****/

.search-sidenav-wrp {
    background-color: #fff;
    padding: 10px 20px 25px 20px;
    position: fixed;
    right: -100%;
    top: 20px;
    z-index: 1001;
    width: 300px;
    border-radius: 5px;
    transition: 0.2s linear;
}

.search-sidenav-inner .f-categories {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.search-sidenav-inner .f-categories h4 {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.search-sidenav-inner .f-categories a {
    color: #1a1a1a;
    font-size: 12px;
    font-weight: 500;
    border-radius: 20px;
    border: 1px solid #ddd;
    display: inline-block;
    padding: 5px 15px;
    margin: 5px 2px;
}

.search-sidenav-inner .what-to-do h4 {
    color: #1a1a1a;
    font-size: 14px;
    margin-top: 10px;
}

.search-sidenav-inner .what-to-do figcaption {
    color: #1a1a1a;
    font-size: 14px;
    margin-top: 7px;
}

.search-sidenav-inner .what-to-do img {
    width: 100%;
    height: 115px;
    border-radius: 5px;
    object-fit: cover;
}

.hamburger-menu-right {
    right: 25px;
}


/**30 Nov**/

.popover-modal-details ul li li,
.popover-modal-details ul li {
    min-height: unset;
}


/**02 Dec**/

.mybucketlist-wrp .popular-experiences-card {
    position: relative;
}

.mybucketlist-wrp .popular-experiences-card .delete {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 16px;
}

.mybucketlist-wrp .experience-wrp-inner .w-46 {
    width: 70%;
}

.mybucketlist-wrp .experience-wrp-inner {
    justify-content: center;
}

.myprofile-order-tab.uk-subnav {
    border-bottom: 1px solid #ddd;
    margin-bottom: 50px;
}


/**15 Dec**/

.autocomplete-container .input-container .x {
    display: none !important;
}

.autocomplete-container .input-container input[type="text"] {
    background-repeat: no-repeat;
    padding-left: 30px;
    background-position: left;
}

.autocomplete-container .suggestions-container ul li a {
    text-align: left;
}


/**04 Jan 22**/

.switch-btn {
    display: flex;
    border-radius: 50px;
    border: 1px solid #ccc;
    padding: 5px 30px;
    cursor: pointer;
    height: 40px;
    align-items: center;
    background: transparent;
    transition: 0.2s linear;
    width: 120px;
}

.switch-btn span {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    color: #1a1a1a;
    display: inline-block;
}

.switch-btn span+span {
    margin-left: 15px;
}

.switch-btn:hover {
    background: #ffde00;
    border: 1px solid #ffde00
}

.switch-btn:hover span {
    color: #fff;
}

.counter-wrp {
    width: 120px
}

.cancel-transaction .uk-modal-body {
    padding: 20px;
}

.cancel-transaction .uk-modal-footer {
    padding: 10px 20px;
    background: transparent;
    border-top: none;
    text-align: left !important;
}

.cancel-transaction .uk-button-primary {
    background: #ffde00;
    color: #1a1a1a;
}


/**07 Jan**/

.order-summery-card .number {
    margin-top: 10px;
    max-width: 140px;
}

.mlf-2 {
    margin: 0 2%;
}


/**31 Jan**/

.new-arrive-inner-slider .popular-experiences-card {
    margin: 0 2%
}

.home-page-banner {
    min-height: 400px;
    background: #f4f4f4;
}

.center-icon-play-video {
    display: none;
}


/**03 feb**/

a.btn-view-all.formobilesli {
    right: 15px;
    /* right: unset; */
    bottom: 15px;
}

a.btn-view-all.formobilesli:hover,
a.btn-view-all.formobilesli:focus {
    color: #1a1a1a;
}


/****/

button:disabled,
button[disabled] {
    border: 1px solid #cccccc !important;
    background-color: #cccccc !important;
    color: #333333 !important;
    cursor: not-allowed;
}

.btn-yellow button:disabled,
.btn-yellow button[disabled] {
    border: 1px solid #cccccc !important;
    background-color: #cccccc !important;
    color: #333333 !important;
    cursor: not-allowed;
}


/****/

a.btn-view-all.formobilesli {
    display: none;
}


/*****************/

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1002;
    cursor: pointer;
    overflow: auto;
}

.overlay-2 {
    z-index: 1009;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    overflow: auto;
}


/****/

.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
.uk-heading-2xlarge,
.uk-heading-large,
.uk-heading-medium,
.uk-heading-small,
.uk-heading-xlarge,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
}

.sharing-card-wrp .listshare {
    font-size: 18px;
    color: #333;
    margin: 3px 10px 0
}

.welcome-user {
    cursor: pointer;
}

label[for=fileupload] {
    cursor: pointer;
}

.order-summery .total-amount.convenienceFee p {
    border-right: 0;
    font-size: 15px;
    color: #4d4d4d;
    font-weight: 300;
}

.order-summery .total-amount.convenienceFee h4 {
    font-size: 16px;
    color: #4d4d4d;
    font-weight: 300;
}

.order-summery .total-amount.totalAmount {
    border-top: 0;
    margin-top: 0;
    padding-top: 10px;
}


/**28 march**/

.matches-card .card .labels {
    border-radius: 0px 0px 8px 8px;
    text-align: center;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    top: -20px;
    width: 100%;
}

.hidelg-link {
    display: none !important;
}

.faq-wrp-inner.gray-accordion .acc-title {
    color: #1a1a1a;
    border: 1px solid #ddd;
    padding: 20px 25px;
    cursor: pointer;
}

.faq-wrp-inner .acc-content {
    display: flow-root;
    margin-top: 20px;
    padding: 0 20px;
}

.faq-wrp-inner .acc-content {
    margin-top: 0;
    /* padding-top: 1rem; */
}

.faq-wrp-inner .acc-content>*:last-child {
    margin-bottom: 1rem;
}

.session-timer {
    background: #fbfbfb;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    color: #404040;
    font-size: 14px;
}

.session-timer br {
    display: none;
}


/**12 Dec 2022**/

.redeem-point-edit .form-input {
    background-color: #fff;
    border: 1px solid #ddd !important;
    width: 100%;
    border-radius: 10px;
    min-height: 55px;
    padding: 10px 10px;
    font-size: 15px !important;
    color: #333333 !important;
    font-weight: 600 !important;
}

.redeem-point-edit .form-input:disabled {
    background-color: #f8f8f8;
}

.redeem-point-edit label {
    position: absolute;
    top: -13px;
    left: 10px;
    background-color: #cccccc;
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 12px;
    color: #333333;
}

.redeem-point-edit span {
    color: #0094e0;
    border-bottom: 1px solid;
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 12px;
    cursor: pointer;
}

/**27 Dec**/
.signup-label a,
.signup-label p {
    color: #0168b3;
    font-size: 15px;

}

.payback-account-modal .uk-modal-title {
    color: #666666;
    font-size: 16px;
}

.payback-account-modal .uk-input,
.payback-account-modal .uk-select {
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #8f8f8f;
    font-size: 14px;
}

.payback-account-modal .uk-select {
    width: 150px;
}

.payback-account-modal .checkbox-text {
    font-size: 11px;
    width: calc(100% - 20px);
    padding-left: 10px;
}

.payback-account-modal .uk-checkbox {
    margin-top: 0;
}

.payback-account-modal .uk-button-default {
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    margin: auto;
    display: table;
    font-size: 15px;
}

.payback-account-modal .form-group {
    position: relative;
}

.payback-account-modal .form-group .error {
    position: absolute;
    bottom: -18px;
    left: 5px;
}

/****/
.validate-otp-modal .uk-modal-title {
    color: #666666;
    font-size: 16px;
}

.validate-otp-modal p {
    color: #747474;
    font-size: 14px;
    font-weight: 300;
    text-align: center
}

.validate-otp-modal .image {
    text-align: center;
    margin-bottom: 15px;
}

.validate-otp-modal .uk-input {
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #8f8f8f;
    font-size: 14px;
    width: 150px;
    text-align: center;
    margin: auto;
    display: table;
    margin-top: 20px;

}

.validate-otp-modal .uk-button-default {
    background-color: #666666;
    color: #fff;
    border-radius: 10px;
    margin: auto;
    display: table;
    font-size: 15px;
}

.validate-otp-modal span {
    display: block;
    color: #696969;
    font-size: 14px;
    text-align: center;
}

.validate-otp-modal a {
    color: #0168b3;
    font-size: 14px;
    text-align: center;
    display: table;
    margin: auto;
}

/**Media Start**/

@media (min-width: 1600px) {
    /****/
}

@media (min-width: 1201px) and (max-width: 1350px) {
    .container {
        max-width: 1140px;
    }

    .profile-wrp::after {
        height: calc(100vh - 250px);
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .container {
        width: 950px;
    }

    .video video {
        height: 370px;
    }

    .listing-card .image {
        height: 370px;
    }

    .profile-wrp::after {
        height: calc(100vh - 270px);
    }

    /****/
    .footer-panel-left h4 {
        margin-bottom: 15px;
    }

    /****/
    .right-panel ul {
        padding-left: 0;
    }

    .right-panel ul li {
        margin-left: 12px;
    }

    .welcome-user .mr-15 {
        margin-right: 10px !important;
        width: 25px;
    }

    .categories-wrp-inner .text-info h4::after {
        width: 75%;
    }

    .categories-slider .uk-child-width-1-4\@m>* {
        width: 30%;
    }

    .camera-len-wrp {
        min-height: 420px;
    }

    /****/
    .myorder-card .details .order-details h3 {
        padding: 0;
    }

    .myorder-card .details .order-details h4 {
        font-size: 22px;
        padding: 10px 0 10px 15px;
    }

    /****/
    .categores-card-2 p {
        font-size: 13px;
    }

    .popular-experiences-card .location {
        font-size: 12px;
    }

    /**19 Oct**/
    .select-sort .filter-dropdown-body {
        left: 600px !important;
    }

    /****/
    .footer-panel-left.list2 {
        padding-left: 20px;
    }

    .footer-panel-right {
        padding-left: 20px;
    }

    .footer-panel-left ul li a {
        font-size: 12px;
    }

    /****/
}

@media (min-width: 768px) and (max-width: 991px) {
    .video-hightlight {
        padding: 0 15px;
    }

    .video-hightlight h2::before,
    .video-hightlight h2::after {
        width: 25%;
    }

    .container {
        width: 730px;
    }

    .categories-slider .uk-child-width-1-5\@m>* {
        width: 30%;
    }

    .categories-slider .uk-child-width-1-3\@s>* {
        width: 30.333%;
        margin: 1%;
    }

    .categories-slider .categories-img {
        height: 130px;
        width: 100%;
        object-fit: cover;
    }

    .listing-card {
        width: 48%;
    }

    .video video {
        height: 400px;
    }

    .listing-card .image {
        height: 400px;
    }

    .hamburger-menu-wrp-inner {
        width: 740px;
    }

    /****/
    .about-big-img .w-49 img {
        height: 330px;
    }

    .city-wrp ul li a {
        padding: 25px 15px;
        min-height: 170px;
    }

    /****/
    .contact-card-top {
        padding: 20px;
    }

    .contact-card-top .contact-card:nth-child(2) {
        padding: 25px 20px;
    }

    .contact-card-top .contact-card:first-child {
        padding-right: 20px;
    }

    .contact-card-top .contact-card:last-child {
        padding-left: 20px;
    }

    .contact-card-top .contact-card .icons {
        margin-right: 15px;
    }

    .contact-card-top .contact-card a,
    .contact-card-top .contact-card p {
        font-size: 15px;
    }

    /****/
    .key-highlight-wrp::after {
        display: none;
    }

    .key-highlight-wrp-inner .w-35 {
        width: 35%;
        display: none;
    }

    .key-highlight-wrp-inner .w-65 {
        width: 100%;
    }

    .key-highlight-info {
        padding-right: 0;
    }

    /* .book-now-card.hidelg {
        display: block;
    } */
    .related-video-slider .video-thumbnail,
    .images-block-single {
        height: 190px;
    }

    .image-top,
    .image-bottom {
        height: 90px;
    }

    .related-collection-slider .uk-card-media-top {
        height: 200px;
    }

    /**Now Sep**/
    .footer-wrp {
        padding: 50px 15px 30px;
    }

    .footer-panel-left ul li a {
        font-size: 12px;
        margin-bottom: 12px;
    }

    .footer-panel-left h4 {
        margin-bottom: 15px;
    }

    .footer-panel-right {
        padding-left: 10px;
    }

    .footer-panel-right .social-box {
        margin-top: 40px;
    }

    .footer-panel-right .social-box a {
        margin-right: 15px;
    }

    .footer-wrp-inner .w-25 {
        width: 33% !important;
        margin-bottom: 20px;
    }

    .footer-panel-left.list2 {
        padding-left: 20px;
    }

    /****/
    .myorder-card .details .order-name h4 {
        font-size: 16px;
    }

    .myorder-card .details .order-details h6 {
        font-size: 12px;
        padding: 10px 0 10px 10px;
    }

    .myorder-card .details .order-details h4 {
        font-size: 18px;
        padding: 10px 0 10px 15px;
    }

    .myorder-card .details .order-details h3 {
        font-size: 14px;
    }

    .pagination .uk-pagination>li>a {
        margin: 0 15px;
    }

    .myprofile-left-panel .uk-subnav li>a {
        padding: 10px 15px;
        font-size: 12px !important;
    }

    .btn-default.mt-3 {
        margin-top: 20px !important;
    }

    .myprofile-info h4 {
        font-size: 18px;
    }

    .mybucketlist-wrp .listing-card .image {
        height: 260px;
    }

    .mybucketlist-wrp .listing-card .details h4 {
        font-size: 14px;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .mybucketlist-wrp .listing-card .details p {
        font-size: 14px;
        margin-bottom: 15px;
        line-height: 1.4;
        min-height: 80px;
    }

    /****/
    .header-wrp .middle-panel .uk-tab>*>a {
        font-size: 10px;
        padding: 5px 14px;
    }

    .header-wrp .left-panel.w-20 {
        width: 25%;
    }

    .header-wrp .middle-panel {
        width: 40%
    }

    .header-wrp .w-25 {
        width: 30% !important;
    }

    .middle-panel .swtitch-tab.uk-tab>.uk-active>a {
        font-size: 10px;
    }

    .right-panel ul {
        padding-left: 0;
    }

    .right-panel ul li {
        margin-left: 10px;
    }

    .welcome-user .mr-15 {
        margin-right: 10px !important;
        width: 25px;
    }

    .logo-big {
        width: 180px;
        margin: auto;
    }

    .video-hightlight .details h4 {
        font-size: 60px;
    }

    .categories-wrp-inner .text-info h4::after {
        width: 65%;
    }

    .camera-len-wrp {
        min-height: 400px;
    }

    /****/
    .wishlist-sharing .fa-share-alt,
    .wishlist-sharing .fa-bookmark-o,
    .wishlist-sharing .fa-bookmark {
        font-size: 18px;
    }

    .listshare {
        width: 60px;
    }

    .wishlist-sharing {
        padding: 8px;
    }

    .guest-card {
        padding: 10px 15px;
    }

    .guest-card .icon {
        margin-right: 5px;
        width: 20px;
    }

    .guest-card .info {
        width: calc(100% - 25px);
    }

    .guest-card .info h4 {
        font-size: 12px;
    }

    .guest-booking .details h4 {
        font-size: 22px;
    }

    .btn-yellow.btn-full.mt-3 {
        margin-top: 20px !important;
    }

    .guest-booking .details span {
        font-size: 10px;
        margin-top: 15px;
    }

    .heading-w-border {
        font-size: 16px;
    }

    .related-video-slider .uk-position-center-left-out {
        top: -25px;
    }

    .related-video-slider .uk-position-center-right-out {
        top: -37px;
    }

    .spotlight-card {
        padding: 20px;
    }

    .hidetab {
        display: none;
    }

    .progress-list .details h5,
    .progress-list .details h4 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .inclusion-card .info {
        width: calc(100% - 60px);
    }

    .inclusion-card {
        margin-bottom: 20px;
        padding-right: 20px;
    }

    .details-other-info-wrp.mt-3 {
        margin-top: 0px !important;
    }

    /**09 Oct**/
    .heading-text-wrp p br {
        display: none;
    }

    .popular-experiences-card.w-46 {
        width: 49%;
        max-width: 420px;
        margin: auto;
        flex-direction: column;
        padding: 15px;
        border-radius: 10px;
    }

    .popular-experiences-card .left-panel.w-55 {
        width: 100%;
    }

    .popular-experiences-card .image {
        height: 170px;
        border-radius: 5px;
        margin-left: 0px;
    }

    .trending img {
        margin-right: 5px;
        width: 12px;
    }

    .trending {
        padding: 5px 14px;
        top: -31px;
        left: 15px;
        min-height: 25px;
    }

    .trending span {
        font-size: 10px;
    }

    .popular-experiences-card .w-45 {
        width: 100%;
    }

    .popular-experiences-card .brand {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 10px;
        text-align: left;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;
    }

    .popular-experiences-card .brand-name {
        width: 65%;
        border-right: 1px solid #ddd;
        padding-right: 10px;
    }

    .popular-experiences-card .details .logo {
        width: 35%;
        height: initial;
        margin: 0 !important;
        padding: 0 10px;
    }

    .popular-experiences-card .details h5 {
        font-size: 15px;
        margin-bottom: 0px;
    }

    .popular-experiences-card .details h6 {
        display: none;
    }

    .popular-experiences-card .location::after,
    .popular-experiences-card .location::before {
        display: none;
    }

    .popular-experiences-card .location {
        border: none;
        border-radius: 0;
        padding: 0;
        font-size: 12px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .popular-experiences-card .details .logo img {
        height: 60px;
    }

    .popular-experiences-card .price {
        display: flex;
        flex-wrap: wrap;
        xmax-width: 200px;
        margin: auto;
        padding-top: 15px;
        margin-top: 5px;
        align-itemsx: center;
        margin-bottom: 15px;
        justify-content: space-evenly;
    }

    .popular-experiences-card del {
        width: 50%;
        text-align: right;
        padding-right: 15px;
    }

    .popular-experiences-card del .fa-rupee {
        font-size: 60%;
    }

    .popular-experiences-card .offer-price {
        width: 50%;
        text-align: left;
        padding-left: 15px;
    }

    .popular-experiences-card del {
        display: block;
        xcolor: #333333;
        font-size: 18px;
    }

    .popular-experiences-card h4 {
        font-size: 24px;
        margin-top: 0;
    }

    .popular-experiences-card p {
        font-size: 11px;
        margin-bottom: 0;
    }

    .popular-experiences-card .btn-yellow {
        padding: 6px 35px;
        font-weight: 500;
    }

    .popular-experiences-card.mb-4 {
        margin-bottom: 30px !important;
    }

    /****/
    .brand-logo {
        text-align: center;
    }

    .new-arrival-wrp .uk-child-width-1-3\@s>* {
        width: 100%;
    }

    .trending-card {
        padding: 0px 15px 20px 15px;
    }

    .trending-card .image {
        height: 155px;
    }

    /****/
    .selected-text {
        font-size: 12px;
    }

    .select-location .selected-text,
    .select-price .selected-text {
        width: 110px;
    }

    .filter-wrp-inner .filter-body {
        padding: 5px 15px;
    }

    .sort-card {
        border: none !important;
    }

    /****/
    .new-arrive-inner-slider .popular-experiences-card.w-46 {
        width: 100%;
    }

    /**25 Oct**/
    .middle-panel .swtitch-tab.uk-tab>* {
        width: 50%;
    }

    /**16 Nov**/
    .header-wrp {
        padding: 0 15px;
    }

    .login {
        font-size: 10px;
    }

    /**19 Nov**/
    .order-details-profile .btn-box {
        flex-direction: column;
    }

    .sharing-card-wrp h4 {
        font-size: 20px;
    }

    .select-sort .filter-dropdown-body {
        left: initial !important;
        right: -5px
    }

    /****/
    .information-wrp-inner .left-panel ul li a {
        padding: 20px 20px 20px 5px;
        font-size: 14px;
    }

    .information-wrp-inner .left-panel.privacy-policy ul li a {
        padding-left: 20px;
    }

    /**26 Nov**/
    .text-info {
        padding-right: 0;
    }

    .categories-wrp-inner .text-info h4 {
        font-size: 32px;
        margin-bottom: 10px;
    }

    .categories-wrp-inner .text-info p {
        font-size: 16px;
        padding-bottom: 20px;
    }

    .filter-new-card .uk-position-cover .uk-position-center {
        padding: 10px;
    }

    .filter-new-card .uk-position-cover h5 {
        font-size: 14px;
    }

    .filter-new-card .image {
        height: 160px;
    }

    /**30 Nov **/
    .contact-card-top .contact-card {
        padding: 25px 0 0;
    }

    .footer-wrp-inner .w-25 {
        width: 25% !important;
    }

    .footer-panel-left ul li a {
        font-size: 10px;
        margin-bottom: 8px;
    }

    .footer-panel-left h4 {
        margin-bottom: 5px;
    }

    .footer-panel-right .number h4 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .footer-panel-right a {
        font-size: 12px;
    }

    .footer-panel-right .social-box {
        margin-top: 20px;
    }

    .footer-panel-right .email.mt-3 {
        margin-top: 20px !important;
    }

    /**25 Jan**/
    .popular-experiences-card {
        padding: 15px;
    }

    /**768x991 Nawaz**/
}

@media (max-width: 768px) {
    .faq-wrp-inner .acc-content p br {
        display: none;
    }

    .new-arrive-inner-slider .uk-slider-container {
        padding-left: 0;
    }

    .cashback {
        font-size: 14px;
    }

    .cashback .cashback-icon {
        width: 56%;
    }

    .logo p {
        min-height: 48px;
    }

    .new-arrive-inner-slider .touch-slider .uk-position-center-left-out {
        top: -53px;
    }

    .new-arrive-inner-slider .touch-slider .uk-position-center-right-out {
        top: -70px;
    }
}

.logo-mob {
    display: none;
}

@media (max-width: 767px) {
    .city-wrp ul li {
        width: 50%;
    }

    .contact-card-field-wrp h4 {
        font-size: 12px;
        color: #5c5c5c;
        font-weight: normal;
        font-family: 'Poppins';
    }

    .center-slider-offer .uk-width-3-4 {
        width: 80%;
    }

    app-standard app-banner-top .middle-panel.hidelg {
        margin: 0;
    }

    .sharelink {
        left: unset;
        right: 1em;
    }

    .ordersummary-other-details button.btn-yellow {
        width: 100%;
        padding: 12px 35px;
        font-size: 16px;
    }

    .header-wrp {
        padding: 0px 15px;
    }

    .right-panel ul li a.btn-default {
        display: none;
    }

    .right-panel ul li:nth-child(2) {
        margin-left: 0;
    }

    .right-panel ul.uk-flex {
        padding-left: 0;
    }

    .login {
        font-size: 10px;
    }

    .logo-box {
        width: 50px;
    }

    .header-wrp {
        flex-wrap: wrap;
        min-height: 65px;
    }

    .header-wrp .left-panel,
    .header-wrp .right-panel {
        width: 50% !important;
    }

    .openMenu,
    .openSearch {
        padding: 0px;
        xwidth: 40px;
        justify-content: center;
        height: 65px;
    }

    .openMenu img,
    .openSearch img {
        width: 18px;
    }

    .header-wrp .right-panel ul {
        justify-content: flex-end;
    }

    /****/
    .select-city-wrp {
        top: 0px;
        position: fixed;
        height: 100%;
        padding-top: 15px;
    }

    .select-city-wrp .close-btn {
        float: right;
        margin-right: 15px;
    }

    .select-city-wrp-inner {
        min-height: auto;
        clear: both;
    }

    .SelectCity-card {
        flex-direction: column;
        padding-left: 0;
    }

    .SelectCity-card li:first-child {
        border-right: none;
        padding-right: 50px;
    }

    .SelectCity-card li {
        padding: 25px 50px;
        margin: 0;
    }

    .SelectCity-card li:nth-child(2) {
        border-bottom: 1px solid #ddd;
    }

    /****/
    .video-hightlight {
        padding: 0 15px;
    }

    .video-hightlight p {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .video-hightlight h2 {
        font-size: 30px;
        line-height: 30px;
    }

    .video-hightlight h2 span {
        display: block;
    }

    .play-btn-box {
        min-height: 260px;
        margin-top: -125px;
        align-items: flex-end;
        padding-bottom: 40px;
    }

    .video-hightlight h2::after,
    .video-hightlight h2::before {
        width: 25%;
    }

    .video-hightlight h4 {
        max-width: 230px;
        margin: auto;
        margin-top: 50px;
    }

    /****/
    .categories-wrp-inner {
        flex-direction: column;
        flex-wrap: wrap;
    }

    .categories-wrp-inner .text-info {
        width: 100%;
        padding-right: 0px;
        text-align: center;
        margin-bottom: 15px;
    }

    .categories-slider {
        width: 100%;
    }

    .custom-nav svg {
        display: none;
    }

    .categories-slider .uk-child-width-1-5\@m>* {
        width: 45%;
        margin: 1%;
        margin-bottom: 15px;
    }

    .categories-slider .uk-panel h4 {
        font-size: 12px;
    }

    .categories-wrp .container {
        padding-right: 0;
    }

    .categories-slider .categories-img {
        width: 100%;
    }

    .playVideo {
        width: 40px;
    }

    .experience-wrp-inner {
        flex-direction: column;
    }

    .listing-card {
        width: 100%;
        max-width: 380px;
        margin: auto !important;
        margin-bottom: 25px !important;
    }

    .experience-wrp {
        padding: 10px;
        xbackground-color: #fff;
    }

    .experience-wrp .container {
        padding-left: 0;
        padding-right: 0;
    }

    .experience-wrp .heading {
        margin-left: 15px;
        margin-bottom: 5px;
    }

    .listing-card {
        margin: 0;
        margin-bottom: 25px;
        /* -webkit-box-shadow: 0px 4px 3px 0px rgba(128, 119, 128, 1);
        -moz-box-shadow: 0px 4px 3px 0px rgba(128, 119, 128, 1);
        box-shadow: 0px 4px 3px 0px rgba(128, 119, 128, 1);
        border-radius: 0; */
        margin-bottom: 50px !important;
    }

    /* .listing-card .image {
        height: auto;
    } */
    .video .vdo {
        width: 100%;
        height: 100%;
    }

    /****/
    .hidelg {
        display: block;
    }

    .filter-floating-btn {
        background-color: #fff;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        position: fixed;
        right: 20px;
        bottom: 20px;
        display: none !important;
    }

    .filter-card-wrp {
        background-color: rgba(0, 0, 0, 0.7);
        top: 0;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 12;
        /* display: flex;
        align-items: flex-end; */
    }

    .filter-card-wrp-inner {
        position: absolute;
        bottom: 0;
        width: 100%;
        transition: 0.2s linear;
        bottom: -100%;
    }

    .filter-card-wrp-inner.active {
        bottom: 0;
    }

    .filter-card-wrp-inner .close-btn {
        background: #fff;
        padding: 6px 15px;
        float: right;
        margin-right: 15px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .filter-card-wrp-inner.inactive {
        bottom: -100%;
    }

    .filter-card-wrp-inner .close-btn img {
        width: 18px
    }

    .filter-card {
        background-color: #fff;
        clear: both;
        padding: 25px 0 25px 15px;
    }

    .filter-card .heading {
        color: #1a1a1a;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .filter-card .categories-wrp {
        padding: 0 0;
    }

    .filteropen {
        display: none;
    }

    .filter-card .categories-slider .categories-img {
        height: 80px;
    }

    .filter-card .categories-slider .uk-child-width-1-5\@m>* {
        width: 35%;
    }

    .filter-card .categories-slider .uk-panel h4 {
        font-size: 10px;
    }

    /****/
    .search-box-wrp {
        top: 0;
        height: 100%;
        position: fixed;
        padding-top: 15px;
    }

    .search-box-wrp .close-btn {
        float: right;
        margin-right: 15px;
    }

    .search-box-card {
        min-height: auto;
        padding: 0 20px;
        clear: both;
        margin-top: 100px;
    }

    .search-box-card .uk-input {
        height: 50px;
    }

    /****/
    .hamburger-menu-wrp {
        position: fixed;
        top: 0;
        height: 100%;
        display: none;
        background-color: #fff;
        padding-top: 15px;
        overflow: auto;
    }

    .hamburger-mobile .close-btn {
        float: right;
        margin-right: 15px;
    }

    .hamburger-menu-wrp-inner {
        min-height: initial;
        padding: 0 15px;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 50px;
    }

    .w-50.menu-1,
    .w-50.menu-2 {
        width: 100% !important;
        text-align: center;
    }

    .menu-1 .uk-column-1-2 {
        border-right: none;
        display: flex;
        flex-direction: column;
    }

    .menu-1 .uk-accordion-content {
        margin-top: 10px;
    }

    .menu-1 .uk-accordion-content li a {
        font-size: 11px;
        margin: 8px 0;
    }

    .menu-2 .uk-flex {
        flex-direction: column;
    }

    .menu-2 li a {
        margin: 0px;
        font-size: 12px;
        display: block;
        padding: 8px 0;
    }

    .menu-2 li:nth-child(2) a {
        border-bottom: 1px solid #e5e5e5;
        border-top: 1px solid #e5e5e5;
    }

    /****/
    .logout-wrp {
        padding: 15px 0 15px 15px;
        /* position: absolute;
        width: 100%;
        bottom: 0; */
    }

    .logout-wrp-inner ul {
        padding-left: 0;
    }

    .logout-wrp-inner ul li a {
        color: #7d7d7d;
        font-size: 10px;
        margin-right: 10px;
        border-right: 1px solid #ddd;
        padding-right: 10px;
    }

    .logout-wrp-inner ul li:last-child a {
        border-right: none
    }

    .logout a {
        border: 1px solid #ddd;
        padding: 10px 15px;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        display: block;
        color: #1a1a1a;
        font-size: 14px;
        font-weight: 500;
        padding-right: 30px;
    }

    .logout a img {
        margin-right: 5px;
    }

    .logout a i {
        font-style: normal;
        position: relative;
        top: 2px;
    }

    /****/
    .menu-1 .uk-accordion-title::before {
        display: none;
    }

    .menu-1 .uk-accordion-title::after {
        content: "";
        width: 16px;
        height: 9px;
        margin-left: 10px;
        background-image: url("assets/images/icons/arrow-down-lg.png");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        display: inline-block;
        transform: rotate(180deg);
        transition: 0.2s linear;
        margin-top: -2px;
        position: relative;
        top: -2px;
    }

    .menu-1 .uk-open>.uk-accordion-title::after {
        background-image: url("assets/images/icons/arrow-down-lg.png");
        transform: rotate(0deg);
    }

    .menu-1 .uk-accordion-title {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .mobile-login-btn a {
        color: #333333;
        font-size: 11px;
        font-weight: 500;
        border: 1px solid #ddd;
        padding: 4px 10px;
        display: inline-block;
        border-radius: 20px;
        min-width: 70px;
        transition: 0.2s linear;
    }

    .mobile-login-btn a:hover {
        border: 1px solid #1a1a1a;
        color: #000
    }

    /****/
    .footer-wrp {
        padding: 50px 0px 0px;
    }

    .footer-wrp .w-50 {
        width: 100% !important;
    }

    .footer-panel-left::after {
        display: none;
    }

    .footer-panel-left ul li a,
    .footer-panel-left h4,
    .footer-panel-left p {
        font-size: 14px;
    }

    .policy-link.mt-3 {
        margin-top: 20px !important;
    }

    .footer-panel-left .policy-link a {
        font-size: 11px;
        margin-right: 10px;
        padding-right: 10px;
    }

    .footer-panel-right {
        padding-left: 0;
    }

    .footer-panel-right .uk-flex {
        flex-wrap: wrap;
    }

    .footer-panel-right .number {
        border-bottom: 1px solid #ddd;
        padding-bottom: 30px;
        margin-bottom: 30px;
    }

    .footer-panel-right .social-box {
        text-align: center;
        margin-left: -15px;
        margin-top: 30px;
        background: #fff;
        margin-right: -15px;
        padding: 20px;
        box-sizing: content-box;
    }

    .footer-panel-right .social-box a {
        color: #4a4a4a;
        margin-left: 10px;
    }

    .mobile-logo-wrp {
        padding: 50px 15px 20px;
        text-align: center;
    }

    /****/
    .offer-text-wrp {
        flex-direction: column;
    }

    .line-left,
    .line-right {
        min-height: 1px;
        width: 100%;
    }

    .offer-text-wrp {
        padding: 30px 15px;
    }

    .offer-text-card {
        padding: 40px 15px;
    }

    .line-left {
        border-left: none;
        border-top: 1px solid rgba(255, 255, 255, 0.3);
    }

    .line-right {
        border-left: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }

    .line-left::before,
    .line-right::before,
    .line-right::after,
    .line-left::after {
        display: none;
    }

    /****/
    .related-collection-slider .uk-slider-nav.hidelg {
        display: flex;
    }

    .related-collection-slider .uk-dotnav>*>* {
        background: #c8c8c8;
    }

    .uk-dotnav>.uk-active>* {
        border-color: #ffde00;
        background: #ffde00 !important;
    }

    .more-related-collection-wrp.mb-6 {
        margin-bottom: 25px !important;
    }

    .related-collection-slider .uk-grid-small>* {
        padding-left: 0px;
    }

    /****/
    .highlight-accordions-wrp-inner .uk-accordion-title {
        padding: 25px 20px;
    }

    .progress-list {
        padding: 20px 0px 10px;
    }

    .list-circle {
        width: 30px;
        height: 30px;
        background-size: cover;
    }

    .progress-list .details {
        width: calc(100% - 30px);
        padding-left: 10px;
    }

    .progress-list li a.list-group {
        margin-bottom: 30px;
    }

    .what-include {
        padding-left: 5px;
    }

    .terms-condition {
        padding-left: 5px;
        margin-top: 10px !important;
    }

    /****/
    .more-related-video-wrp .container {
        padding-left: 0;
        padding-right: 0;
    }

    .more-related-video-wrp h4 {
        font-size: 18px;
        margin-bottom: 20px;
        margin-left: 15px;
    }

    .related-video-slider .video-thumbnail,
    .images-block-single {
        height: 390px;
    }

    .image-top,
    .image-bottom {
        height: 185px;
    }

    .image-bottom {
        margin-top: 20px;
    }

    /****/
    .key-highlight-wrp-inner .w-65 {
        width: 100%;
    }

    .key-highlight-wrp-inner .w-35 {
        width: 100%;
        display: none
    }

    .key-highlight-pricing {
        display: none
    }

    .key-highlight-info {
        padding-right: 0;
    }

    .key-highlight-info-header h4 {
        line-height: 1.3;
    }

    .key-highlight-info-details p {
        padding-right: 0;
    }

    /****/
    .mobile-adv-banner {
        max-width: 375px;
        margin: auto;
        margin-top: -25px;
        margin-bottom: 20px;
    }

    .mobile-adv-banner img {
        width: 100%;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    /****/
    .login-wrp {
        max-width: 350px;
        padding: 25px 15px 50px;
        margin: 0px auto 0px;
    }

    .hideHeaderMob {
        display: none;
    }

    /**TIMER SLIDER**/
    .timer-slider {
        width: 100%;
        height: 100vh;
        background-color: #ddd;
        overflow: hidden;
    }

    .progress-slider-card .image-block {
        width: 100%;
        height: 100%;
    }

    .progress-slider-card .image-block img {
        width: 100%;
        height: 100%;
        object-fit: cover
    }

    .slick-prev:before,
    .slick-next:before {
        color: black;
    }

    .slick-dots {
        bottom: -30px;
    }

    .progressBarContainer {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        padding: 15px;
        display: flex;
    }

    .progressBarContainer div {
        width: 100%;
        cursor: pointer;
        margin-right: 5px;
        color: white;
    }

    .progressBarContainer div:last-child {
        margin-right: 0;
    }

    .progressBarContainer div span.progressBar {
        width: 100%;
        height: 4px;
        background-color: rgba(255, 255, 255, 0.4);
        display: block;
    }

    .progressBarContainer div span.progressBar .inProgress {
        background-color: rgba(255, 255, 255, 1);
        width: 0%;
        height: 4px;
    }

    /****/
    .timer-slider video {
        height: 100vh;
        width: 100%;
        object-fit: cover
    }

    .back-link {
        position: absolute;
        top: 35px;
        left: 0;
        width: 100%;
        padding: 0 15px;
        align-items: center;
    }

    .back-link .share-box>a {
        margin-left: 15px;
        font-size: 22px;
        line-height: 1;
        xvertical-align: top;
    }

    .back-link .listshare {
        font-size: 20px;
        margin-left: 15px;
    }

    .uk-slideshow-items>li::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        /* background-color: rgba(0, 0, 0, 0.3); */
        left: 0;
        top: 0;
        z-index: 1;
    }

    .video-img-slider .info {
        padding: 0px 15px;
        display: block;
        z-index: 10;
    }

    .video-img-slider .info img {
        margin-right: 10px;
    }

    .video-img-slider .info h4 {
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px !important;
    }

    .video-img-slider .info p {
        color: #fff;
        font-size: 13px;
    }

    .timer-slider .scroll-down p {
        padding-bottom: 15px;
    }

    .timer-slider .line-vertical {
        width: 30%;
    }

    /****/
    .profile-wrp-inner {
        padding-top: 25px;
    }

    .profile-list-wrp .list-card a {
        width: 46%;
        margin: 2%;
        background: #fff
    }

    .w-580 {
        max-width: 290px;
    }

    .profile-list-wrp .list-card a p {
        margin-bottom: 10px;
    }

    .other-link ul li {
        border-right: none;
        border-bottom: 1px solid #ddd;
    }

    .other-link ul {
        flex-direction: column;
        margin: 0 2%
    }

    .other-link li a {
        display: block;
        margin: 10px;
    }

    .other-link.mt-4 {
        margin-top: 20px !important;
        margin-bottom: 50px !important;
    }

    .profile-wrp::after {
        height: calc(100vh - 320px);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

    /****/
    .login-signup .back-btn {
        margin-top: 15px;
        margin-left: 15px;
    }

    .hideMobile {
        display: none;
    }

    .enquiry-wrp .key-highlight-pricing {
        display: block;
    }

    .enquiry-wrp .w-35 {
        width: 100%;
        max-width: 390px;
    }

    /****/
    .enquiry-wrp .key-highlight-pricing {
        padding: 0
    }

    .enquiry-wrp .border-form-input {
        padding: 0px 30px 40px
    }

    .enquiry-wrp .key-highlight-pricing h4 {
        padding: 25px 30px 0;
    }

    /****/
    /* .video-img-slider .uk-slideshow-items {
        min-height: calc(100vh - 146px) !important;
    } */
    .filter-floating-btn.hidelg {
        display: none;
    }

    /****/
    .middle-panel {
        margin: 20px 15px;
    }

    .middle-panel .swtitch-tab.uk-tab>* {
        width: 50%;
    }

    .middle-panel .swtitch-tab.uk-tab>.uk-active>a {
        font-size: 12px;
        border-radius: 30px;
    }

    .middle-panel .swtitch-tab {
        border-radius: 30px;
        padding: 3px 5px 3px;
    }

    .middle-panel .uk-tab>*>a {
        font-size: 12px;
        padding: 5px 30px;
        line-height: 1.2;
        text-align: center;
    }

    /****/
    .categories-wrp {
        padding: 20px 0 45px;
    }

    .categories-wrp-inner .text-info {
        text-align: left;
        margin-bottom: 5px;
    }

    .categories-wrp-inner .text-info h4::after {
        width: 30%;
        left: 150px;
    }

    .categories-wrp-inner .text-info p {
        font-size: 12px;
    }

    .categories-wrp-inner .text-info h4 {
        margin-bottom: 10px;
    }

    .categories-slider {
        margin-top: 25px;
    }

    .categories-wrp::before {
        height: 150px;
    }

    .categories-slider .uk-child-width-1-2>* {
        width: 54%;
        margin: 1%;
    }

    .categories-slider .categories-img {
        height: 130px;
        border-radius: 5px;
    }

    .categories-slider li.active .categories-img {
        border-bottom: 3px solid #ffde00;
        border-radius: 5px;
    }

    .categories-slider .categories-img {
        border-bottom: 3px solid #d9d9d9;
    }

    .categories-slider .cate-name h4 {
        font-size: 14px;
        margin-top: 10px;
    }

    /****/
    .heading-text-wrp h4 {
        font-size: 25px;
        max-width: 265px;
        margin: auto
    }

    .heading-text-wrp p {
        font-size: 14px;
        padding: 12px 15px 20px;
    }

    .heading-text-wrp p br {
        display: none
    }

    .line {
        Width: 90px;
        border-top: 1px solid #ffde00;
    }

    /****/
    .experience-wrp::before {
        width: 90%;
        height: 300px;
        background: url("assets/images/watermark.png") no-repeat center center;
        top: -85px;
        background-size: contain;
    }

    /****/
    .camera-len-wrp {
        padding: 5px 0 5px;
        min-height: 220px;
        background-position: center right;
    }

    .camera-len-wrp .mt-100 {
        margin-top: 0;
    }

    /****/
    .footer-wrp-inner {
        flex-direction: column;
    }

    .footer-wrp-inner .w-25 {
        width: 100% !important;
    }

    .footer-panel-left {
        position: relative;
        border-right: none;
    }

    .footer-panel-left.list2 {
        padding-left: 0;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .footer-panel-right {
        background: #272727;
        margin-left: -15px;
        margin-right: -15px;
        padding: 30px 15px 25px;
        text-align: center;
    }

    .footer-panel-right .number {
        border-bottom: none;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .footer-panel-right .email.mt-3 {
        margin-top: 20px !important;
    }

    .footer-panel-right .social-box {
        border-top: 1px solid;
        padding: 20px 0 0;
        background-color: transparent;
        margin: 0;
        margin-top: 20px;
    }

    .footer-panel-right .social-box a {
        color: #fff;
        margin: 0 10px;
        font-size: 18px;
    }

    /****/
    .footer-bottom .uk-flex {
        flex-direction: column;
        text-align: center;
    }

    .policy-link {
        margin-bottom: 10px;
    }

    .footer-bottom .policy-link a {
        margin: 0 5px
    }

    .footer-bottom p {
        max-width: 190px;
        margin: auto;
        line-height: 1.8
    }

    .footer-panel-left .uk-accordion-title::before {
        display: block;
    }

    .footer-panel-left ul li a.uk-accordion-title {
        width: 100%;
    }

    .footer-panel-left .uk-open>.uk-accordion-title::before {
        background-image: url("assets/images/icons/minus.png");
    }

    .footer-panel-left .uk-accordion-title::before {
        background-image: url("assets/images/icons/plus.png");
    }

    .footer-panel-left ul li a.uk-accordion-title {
        width: 100%;
        cursor: pointer;
    }

    /****/
    .header-wrp {
        position: absolute;
    }

    /****/
    .center-slider-wrp.mt-8 {
        margin-top: 0;
    }

    .center-slider-wrp .uk-width-3-4 {
        margin: 0;
        position: relative;
        width: 100%;
    }

    .center-slider-wrp {
        background: #ddd;
        min-height: calc(100vh - 132px);
    }

    .center-slider-wrp .img-width {
        min-height: calc(100vh - 132px);
    }

    /****/
    .container-inner {
        flex-wrap: wrap;
    }

    .w-70 {
        width: 100%;
    }

    .container-inner .w-70 {
        padding-right: 0;
    }

    .key-highlight-wrp {
        padding-top: 30px;
    }

    .key-highlight-info-header h4 {
        font-size: 22px;
    }

    .key-highlight-info-header h6 {
        margin-bottom: 10px;
    }

    .wrapper::before {
        height: 200px;
    }

    .key-highlight-info-details.mt-3 {
        margin-top: 40px !important;
    }

    .key-highlight-info-details p {
        line-height: 30px;
    }

    .heading-w-border {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .spotlight-card.w-49 {
        padding: 25px;
        width: 100%;
        margin-bottom: 20px;
    }

    /****/
    .the-plan-wrp.mt-3 {
        margin-top: 10px !important;
    }

    .list-circle {
        width: 15px;
        height: 15px;
        background: url('assets/images/icons/yellow-dot.png') no-repeat;
        margin-left: 0;
    }

    .progress-list {
        padding: 0px 0px 0px;
    }

    .progress-list ul li::after {
        left: 8px;
    }

    .progress-list .details h5 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    /****/
    .inclusion-card.w-49 {
        width: 100%;
        align-items: center;
    }

    .inclusion-card {
        margin-bottom: 30px;
    }

    .inclusion-card .info {
        width: calc(100% - 45px);
    }

    /****/
    .cancel-policy.mt-3 {
        margin-top: 20px !important;
    }

    .cancel-policy h6 {
        margin-bottom: 15px;
    }

    .more-related-collection-wrp.mt-6 {
        margin-top: 30px !important;
    }

    .footer-pb {
        padding-bottom: 90px;
    }

    .center-slider-wrp .uk-position-center {
        top: inherit;
        left: inherit;
        transform: translate(-0%, -0%);
        bottom: 20px;
        left: 0px;
    }

    .center-slider-wrp .uk-width-3-4 {
        height: 100%;
    }

    /****/
    .header-wrp {
        background: transparent;
        box-shadow: 0px 0px 0px #ddd !important;
    }

    .container.mt-130 {
        margin-top: 90px;
    }

    /****/
    .tabs-link li a {
        font-size: 12px;
    }

    .tabs-link li a span {
        display: none
    }

    .tabs-link li a b {
        display: none
    }

    .tabs-link.uk-tab>* {
        padding-left: 0;
        padding-right: 0;
    }

    .tabs-link li a {
        height: 40px;
    }

    .tabs-link li .icon {
        width: 15px;
        top: 8px;
        right: -7px;
    }

    .tabs-link li::after {
        width: 30px;
        height: 24px;
        top: 7px;
    }

    .tabs-link li a {
        height: 40px;
        line-height: 30px;
    }

    .tabs-link li.uk-active>a i {
        padding: 0 2px 10px;
    }

    /****/
    .order-details-wrp {
        flex-direction: column;
    }

    .order-details-wrp .w-40 {
        width: 100%;
        max-width: 430px;
        margin: auto;
    }

    .order-details-wrp .details-card .image {
        height: 170px;
    }

    .sharing-card-wrp {
        margin: 20px 5px;
    }

    .sharing-card-wrp h4 {
        font-size: 18px;
    }

    .date-and-time-wrp {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 10px;
    }

    .date-and-time-wrp .dt-card {
        width: 100%;
        border-right: none;
    }

    .date-and-time-wrp .info {
        width: 100%;
        padding-left: 0;
        margin-top: 10px;
    }

    .order-details-wrp .w-60 {
        margin-left: 0;
        width: 100% !important;
        margin-top: 15px;
    }

    .voucher-code h4 {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .voucher-code {
        padding: 15px;
    }

    .voucher-code .code {
        font-size: 26px;
        padding: 15px 40px;
    }

    .voucher-code p {
        padding: 0;
    }

    .order-summery {
        padding: 15px;
        margin-top: 15px;
        flex-direction: column;
    }

    .order-summery-card {
        border-right: none;
        padding-right: 0;
        width: 100%;
    }

    .order-summery .order-details {
        width: 100%;
    }

    .order-summery .order-details h5 {
        display: none;
    }

    .order-summery .order-details {
        width: 100%;
        margin-top: 30px;
    }

    .order-summery .order-details p,
    .order-summery .order-details h4 {
        font-weight: 500;
    }

    .need-help {
        padding: 20px 15px;
        border-radius: 5px;
        flex-direction: column;
        align-items: flex-start;
    }

    .need-help .number a {
        margin-top: 25px;
        display: inline-block;
    }

    .confirmation-wrp .border-btn {
        display: block;
        text-align: center;
    }

    .order-summery-card .order-price {
        flex-direction: initial;
    }

    .order-summery-card .order-price .w-50 {
        width: 50% !important;
    }

    .order-summery-card h4 {
        font-size: 22px;
        text-align: right;
    }

    .order-summery-card p {
        font-size: 14px;
    }

    .rightangle::after {
        display: none;
    }

    /****/
    .date-and-time-wrp .info a.edit-btn {
        display: none;
    }

    .discount-code .code-field label {
        font-size: 12px;
    }

    .code-applied {
        padding-left: 0;
    }

    .order-summery .order-details h4 {
        font-size: 22px;
    }

    .promo-code-applied {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
    }

    .order-summery .total-amount p {
        font-weight: 500;
    }

    .order-summery .total-amount h4 {
        font-size: 22px;
        font-weight: 500;
        border: none
    }

    .order-details-profile p {
        font-weight: 500;
        margin-bottom: 35px;
        font-size: 16px;
    }

    .order-details-profile h4 {
        font-size: 18px;
    }

    .order-details-profile .btn-box {
        flex-direction: column;
    }

    .profile-link-btn {
        margin: 5px;
        font-size: 14px;
        text-align: center;
    }

    .ordersummary-other-details .btn-yellow {
        display: block;
        text-align: center;
    }

    .order-summery .order-details {
        margin-top: 5px;
    }

    .date-and-time-wrp .info {
        display: flex;
        align-items: center;
    }

    .date-and-time-wrp .info p {
        margin-right: 15px;
    }

    .select-people-drop.mt-1 {
        margin-top: 0px !important;
    }

    .select-people-drop .uk-select {
        min-width: 120px;
    }

    .date-and-time-wrp .uk-form-controls .uk-select {
        max-width: 170px;
    }

    .date-and-time-wrp .uk-form-controls .uk-select:not([multiple]):not([size]) {
        background-position: 90% 50%;
    }

    .edit-btn-wrp .edit-btn {
        right: 20px;
    }

    /**Profile**/
    .container.mt-130.myprofile-wrp {
        margin-top: 20px;
    }

    .myprofile-wrp-inner .w-30,
    .myprofile-wrp-inner .w-70 {
        width: 100%;
    }

    #MyOrder {
        display: none
    }

    .myprofile-wrp.mb-6 {
        margin-bottom: 30px !important;
    }

    .myprofile-left-panel {
        border: none;
        padding: 0;
    }

    .myprofile-form.pl-25,
    .myprofile-order.pl-25,
    .mybucketlist.pl-25 {
        padding-left: 0;
    }

    .myprofile-form-wrp {
        border: none;
        padding: 0;
    }

    .myprofile-left-panel .uk-subnav>.uk-active>a::after {
        border-left: none;
    }

    .myprofile-left-panel .uk-subnav>.uk-active>a {
        background: transparent;
    }

    .full-border-form .form-group {
        margin-bottom: 15px;
        width: 100%;
    }

    .full-border-form .btn-yellow {
        margin-top: 20px;
    }

    .myprofile-order-tab.uk-subnav {
        margin-left: 10px;
    }

    .myprofile-order-tab.uk-subnav li>a {
        padding: 8px 12px;
        font-size: 11px;
    }

    .myorder-card {
        flex-direction: column;
    }

    .myorder-card .image {
        height: 150px;
    }

    .myorder-card .details {
        padding-left: 0;
        margin-top: 25px;
    }

    .myorder-card .details .order-details {
        padding: 15px 0px 15px 0;
        flex-wrap: wrap;
    }

    .myorder-card .details .order-details h6 {
        padding: 0;
        border-left: none;
        text-align: left;
    }

    .myorder-card .details .order-details p {
        padding: 0;
    }

    .myorder-card .details .order-details h4 {
        font-size: 22px;
        padding: 0;
        border-left: none;
        text-align: left;
    }

    .myorder-card .details .order-details h3 {
        padding: 0 0 10px;
    }

    .pagination {
        display: none;
    }

    .mybucketlist-wrp .listing-card {
        width: 100%;
        max-width: 410px;
    }

    .myprofile-form-wrp {
        max-width: 410px;
        margin: auto;
    }

    /****/
    .nav-down {
        position: fixed !important;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 0px 5px #ddd !important;
    }

    .citymodel .uk-modal-dialog {
        padding: 35px 30px 35px;
    }

    .select-city-model .uk-flex {
        justify-content: center
    }

    .city-card-m a span {
        margin-top: 15px;
    }

    .city-card-m {
        width: 49%;
    }

    .full-border-form .w-32 {
        width: 100% !important;
        margin-bottom: 15px;
    }

    .heading-text-wrp h4 {
        line-height: 30px;
    }

    .order-details-profile {
        min-height: auto;
    }

    .profile-icon {
        margin-top: 0px;
    }

    /**Rock Css Home page**/
    .heading-text-wrp {
        margin-bottom: 70px;
    }

    .heading-text-wrp h4 span {
        letter-spacing: -1px;
    }

    .heading-text-wrp h4 span .large-font {
        font-size: 40px;
    }

    /****/
    .text-gray-innerMenu {
        box-shadow: 0px 0px 5px #ddd !important;
        position: static;
    }

    .center-slider-wrp.center-full-slider-m {
        min-height: initial;
    }

    .center-slider-wrp.center-full-slider-m .img-width {
        min-height: initial;
    }

    .center-slider-wrp .uk-position-small {
        margin: 0;
    }

    .heading-subpara {
        font-size: 13px;
        color: #000
    }

    .heading-w-border.mb-2 {
        margin-bottom: 15px !important;
    }

    /****/
    .categores-card-2 {
        padding: 10px 10px 15px;
    }

    .categores-card-2 .icons {
        padding: 20px 15px;
        margin-bottom: 10px;
        margin-top: -30px;
    }

    .categores-card-2 p {
        font-size: 12px;
        text-transform: uppercase;
    }

    /****/
    .popular-experiences-card.w-46 {
        width: 100%;
        max-width: 420px;
        margin: auto;
        flex-direction: column;
        padding: 15px;
        border-radius: 10px;
    }

    .popular-experiences-card .left-panel.w-55 {
        width: 100%;
    }

    .popular-experiences-card .image {
        height: 170px;
        border-radius: 5px;
        margin-left: 0px;
    }

    .trending img {
        margin-right: 5px;
        width: 12px;
    }

    .trending {
        padding: 5px 14px;
        top: -31px;
        left: 15px;
        min-height: 25px;
    }

    .trending span {
        font-size: 10px;
    }

    .popular-experiences-card .w-45 {
        width: 100%;
    }

    .popular-experiences-card .brand {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 10px;
        text-align: left;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;
    }

    .popular-experiences-card .brand-name {
        width: 65%;
        border-right: 1px solid #ddd;
        padding-right: 10px;
    }

    .popular-experiences-card .details .logo {
        width: 35%;
        height: initial;
        margin: 0 !important;
        padding: 0 10px;
    }

    .popular-experiences-card .details h5 {
        font-size: 15px;
        margin-bottom: 0px;
    }

    .popular-experiences-card .details h6 {
        display: none;
    }

    .popular-experiences-card .location::after,
    .popular-experiences-card .location::before {
        display: none;
    }

    .popular-experiences-card .location {
        border: none;
        border-radius: 0;
        padding: 0;
        font-size: 12px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .popular-experiences-card .details .logo img {
        height: 60px;
    }

    .popular-experiences-card .price {
        display: flex;
        flex-wrap: wrap;
        xmax-width: 200px;
        margin: auto;
        padding-top: 15px;
        margin-top: 5px;
        align-items: center;
        margin-bottom: 15px;
        justify-content: space-evenly;
    }

    .popular-experiences-card del {
        width: 50%;
        text-align: right;
        padding-right: 15px;
    }

    .popular-experiences-card del .fa-rupee {
        font-size: 60%;
    }

    .popular-experiences-card .offer-price {
        width: 50%;
        text-align: left;
        padding-left: 15px;
    }

    .popular-experiences-card del {
        display: block;
        xcolor: #333333;
        font-size: 18px;
    }

    .popular-experiences-card h4 {
        font-size: 24px;
        margin-top: 0;
    }

    .popular-experiences-card p {
        font-size: 11px;
        margin-bottom: 0;
    }

    .popular-experiences-card .btn-yellow {
        padding: 6px 35px;
        font-weight: 500;
    }

    .popular-experiences-card.mb-4 {
        margin-bottom: 30px !important;
    }

    /****/
    .love-brand-wrp.mt-5 {
        margin-top: 25px !important;
    }

    .love-brand-wrp .uk-child-width-1-2>* {
        width: 40%;
    }

    .trending-wrp.mt-5 {
        margin-top: 20px !important;
    }

    .uk-slider-container.mt-5 {
        margin-top: 15px !important;
    }

    .touch-slider .uk-position-center-right-out {
        top: -40px;
    }

    .touch-slider .uk-position-center-left-out {
        top: -28px;
    }

    /****/
    .trending-wrp {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .trending-card {
        padding: 0px 15px 15px 15px;
    }

    .trending-wrp .container {
        padding-right: 0;
    }

    .trending-wrp .uk-child-width-1-2>* {
        width: 85%;
    }

    .new-arrival-wrp .uk-child-width-1-2>* {
        width: 100%;
    }

    .new-arrival-wrp .popular-experiences-card.w-46 {
        width: 98%;
    }

    /****/
    .categories-slider .uk-position-center-right-out {
        top: -85px;
    }

    .categories-slider .uk-position-center-left-out {
        top: -73px;
    }

    /****/
    .center-slider-wrp-2.offer-slider-center.mt-115 {
        margin-top: 20px;
    }

    .st-listing-pop.mt-5 {
        margin-top: 30px !important;
    }

    .st-listing-pop .heading-w-border.ml-40 {
        margin-left: 0px !important;
        margin-bottom: 35px !important;
    }

    .st-listing-pop .heading-w-border {
        width: 100%;
    }

    /****/
    .mobile-filter-wrp {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }

    .mobile-filter-wrp .m-filter {
        width: 50%;
        padding: 10px 25px 10px 0;
        text-align: right;
        border-right: 1px solid #ddd;
    }

    .mobile-filter-wrp .m-sort {
        width: 50%;
        padding: 10px 0px 10px 25px;
        cursor: pointer;
    }

    .mobile-filter-wrp img {
        margin-right: 10px;
    }

    /****/
    .sort-by-hamburger {
        background-color: #fff;
        padding: 10px 15px 30px 30px;
        position: fixed;
        z-index: 1001;
        width: 100%;
        top: 0;
        height: 100%;
        overflow: auto;
        right: -100%;
        transition: 0.2s linear;
    }

    .hamburger-menu-right {
        right: 0;
    }

    .sort-by-hamburger p {
        display: inline-block;
        color: #1a1a1a;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .filter-hamburger {
        background-color: #fff;
        position: fixed;
        z-index: 1001;
        width: 100%;
        top: 0;
        height: 100%;
        overflow: auto;
        left: -100%;
        transition: 0.2s linear;
        touch-action: none;
    }

    .filter-hamburger-menu-left {
        left: 0;
    }

    .overflow {
        overflow: hidden;
        overflow-y: scroll;
        -ms-scroll-snap-type: mandatory;
        scroll-snap-type: mandatory;
        -ms-scroll-snap-points-y: repeat(3rem);
        scroll-snap-points-y: repeat(3rem);
        -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
        display: block;
        scroll-snap-stop: always;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .experience-wrp-inner .listing-card {
        scroll-snap-align: start;
        height: 100%;
    }

    /****/
    main {
        max-height: 100vh;
        overflow: hidden;
        overflow-y: scroll;
        -ms-scroll-snap-type: y mandatory;
        scroll-snap-type: y mandatory;
        scroll-snap-stop: always;
    }

    main section,
    main header {
        scroll-snap-align: start;
        overflow: auto
    }

    /* .header-wrp {
        background: #fff;
    }
    .listing-heading {
        padding-bottom: 20px;
    }
    .listing-card {
        background: yellow;
        height: 100vh;
        max-width: 380px;
    }
    .header-wrp {
        position: static;
    } */
    /****/
    .hamburger-menu-main {
        width: 100%;
        top: 0;
        border-radius: 0;
        height: 100%;
        overflow: auto;
    }

    .hamburger-menu-right {
        right: 0;
    }

    .header-wrp {
        background: transparent;
        box-shadow: none;
        box-shadow: none !important;
    }

    .welcome-user .info {
        display: none;
    }

    .welcome-user .icons {
        width: 20px;
        padding-top: 6px;
    }

    /* .hamburger-menu-main {
        right: -100%;
    } */
    .logo-big {
        width: 120px;
        margin: auto;
        margin-bottom: 20px !important;
    }

    .video-hightlight .details h4 {
        font-size: 46px;
        max-width: 260px;
        margin-top: 10px;
        line-height: 50px;
    }

    .video-hightlight .details h6 {
        display: none;
    }

    /* .video-img-slider .info {
        display: none;
    } */
    .filter-floating-btn.hidelg {
        display: none;
    }

    /****/
    .middle-panel {
        margin: 20px 15px;
    }

    .middle-panel .swtitch-tab.uk-tab>* {
        width: 50%;
    }

    .middle-panel .swtitch-tab.uk-tab>.uk-active>a {
        font-size: 12px;
        border-radius: 30px;
    }

    .middle-panel .swtitch-tab {
        border-radius: 30px;
        padding: 3px 5px 3px;
    }

    .middle-panel .uk-tab>*>a {
        font-size: 12px;
        padding: 8px 30px;
        line-height: 1.2;
        text-align: center;
    }

    /****/
    .categories-wrp {
        padding: 20px 0 45px;
    }

    .categories-wrp-inner .text-info {
        text-align: left;
        margin-bottom: 5px;
    }

    .categories-wrp-inner .text-info h4::after {
        width: 30%;
        left: 150px;
    }

    .categories-wrp-inner .text-info p {
        font-size: 12px;
    }

    .categories-wrp-inner .text-info h4 {
        margin-bottom: 10px;
    }

    .categories-slider {
        margin-top: 25px;
    }

    .categories-wrp::before {
        height: 150px;
    }

    .categories-slider .uk-child-width-1-2>* {
        width: 54%;
        margin: 1%;
    }

    .categories-slider .categories-img {
        height: 130px;
        border-radius: 5px;
    }

    .categories-slider li.active .categories-img {
        border-bottom: 3px solid #ffde00;
        border-radius: 5px;
    }

    .categories-slider .categories-img {
        border-bottom: 3px solid #d9d9d9;
    }

    .categories-slider .cate-name h4 {
        font-size: 14px;
        margin-top: 10px;
    }

    /****/
    .heading-text-wrp h4 {
        font-size: 25px;
        max-width: 265px;
        margin: auto
    }

    .heading-text-wrp p {
        font-size: 14px;
        padding: 12px 15px 20px;
    }

    .heading-text-wrp p br {
        display: none
    }

    .line {
        Width: 90px;
        border-top: 1px solid #ffde00;
    }

    /****/
    .experience-wrp::before {
        width: 90%;
        height: 300px;
        background: url("assets/images/watermark.png") no-repeat center center;
        top: -85px;
        background-size: contain;
    }

    /****/
    .camera-len-wrp {
        padding: 5px 0 5px;
        min-height: 220px;
        background-position: center right;
    }

    .camera-len-wrp .mt-100 {
        margin-top: 0;
    }

    /****/
    .footer-wrp-inner {
        flex-direction: column;
    }

    .footer-wrp-inner .w-25 {
        width: 100% !important;
    }

    .footer-panel-left {
        position: relative;
        border-right: none;
    }

    .footer-panel-left.list2 {
        padding-left: 0;
        margin-top: 10px;
        margin-bottom: 30px;
    }

    .footer-panel-right {
        background: #272727;
        margin-left: -15px;
        margin-right: -15px;
        padding: 30px 15px 25px;
        text-align: center;
    }

    .footer-panel-right .number {
        border-bottom: none;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .footer-panel-right .email.mt-3 {
        margin-top: 20px !important;
    }

    .footer-panel-right .social-box {
        border-top: 1px solid;
        padding: 20px 0 0;
        background-color: transparent;
        margin: 0;
        margin-top: 20px;
    }

    .footer-panel-right .social-box a {
        color: #fff;
        margin: 0 10px;
        font-size: 18px;
    }

    /****/
    .footer-bottom .uk-flex {
        flex-direction: column;
        text-align: center;
    }

    .policy-link {
        margin-bottom: 10px;
    }

    .footer-bottom .policy-link a {
        margin: 0 5px
    }

    .footer-bottom p {
        max-width: 190px;
        margin: auto;
        line-height: 1.8
    }

    .footer-panel-left .uk-accordion-title::before {
        display: block;
    }

    .footer-panel-left ul li a.uk-accordion-title {
        width: 100%;
    }

    .footer-panel-left .uk-open>.uk-accordion-title::before {
        background-image: url("assets/images/icons/minus.png");
    }

    .footer-panel-left .uk-accordion-title::before {
        background-image: url("assets/images/icons/plus.png");
    }

    .footer-panel-left ul li a.uk-accordion-title {
        width: 100%;
        cursor: pointer;
    }

    /****/
    .header-wrp {
        position: absolute;
    }

    /****/
    .center-slider-wrp.mt-8 {
        margin-top: 0;
    }

    .center-slider-wrp .uk-width-3-4 {
        margin: 0;
        position: relative;
        width: 100%;
    }

    .center-slider-wrp {
        background: #ddd;
        min-height: 100vh - 132px;
    }

    .center-slider-wrp .img-width {
        min-height: 100vh;
    }

    /****/
    .container-inner {
        flex-wrap: wrap;
    }

    .w-70 {
        width: 100%;
    }

    .container-inner .w-70 {
        padding-right: 0;
    }

    .key-highlight-wrp {
        padding-top: 30px;
    }

    .key-highlight-info-header h4 {
        font-size: 22px;
    }

    .key-highlight-info-header h6 {
        margin-bottom: 10px;
    }

    .wrapper::before {
        height: 200px;
    }

    .key-highlight-info-details.mt-3 {
        margin-top: 40px !important;
    }

    .key-highlight-info-details p {
        line-height: 30px;
    }

    .heading-w-border {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .spotlight-card.w-49 {
        padding: 25px;
        width: 100%;
        margin-bottom: 20px;
    }

    /****/
    .the-plan-wrp.mt-3 {
        margin-top: 10px !important;
    }

    .list-circle {
        width: 15px;
        height: 15px;
        background: url('assets/images/icons/yellow-dot.png') no-repeat;
        margin-left: 0;
    }

    .progress-list {
        padding: 0px 0px 0px;
    }

    .progress-list ul li::after {
        left: 8px;
    }

    .progress-list .details h5 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    /****/
    .inclusion-card.w-49 {
        width: 100%;
        align-items: center;
    }

    .inclusion-card {
        margin-bottom: 30px;
    }

    .inclusion-card .info {
        width: calc(100% - 45px);
    }

    /****/
    .cancel-policy.mt-3 {
        margin-top: 20px !important;
    }

    .cancel-policy h6 {
        margin-bottom: 15px;
    }

    .more-related-collection-wrp.mt-6 {
        margin-top: 30px !important;
    }

    .footer-pb {
        padding-bottom: 90px;
    }

    .center-slider-wrp .uk-position-center {
        top: inherit;
        left: inherit;
        transform: translate(-0%, -0%);
        bottom: 20px;
        left: 0px;
    }

    .center-slider-wrp .uk-width-3-4 {
        height: 100%;
    }

    /****/
    .header-wrp {
        background: transparent;
        box-shadow: 0px 0px 0px #ddd !important;
    }

    .container.mt-130 {
        margin-top: 90px;
    }

    /****/
    .tabs-link li a {
        font-size: 12px;
    }

    .tabs-link li a span {
        display: none
    }

    .tabs-link li a b {
        display: none
    }

    .tabs-link.uk-tab>* {
        padding-left: 0;
        padding-right: 0;
    }

    .tabs-link li a {
        height: 40px;
    }

    .tabs-link li .icon {
        width: 15px;
        top: 8px;
        right: -7px;
    }

    .tabs-link li::after {
        width: 30px;
        height: 24px;
        top: 7px;
    }

    .tabs-link li a {
        height: 40px;
        line-height: 30px;
    }

    .tabs-link li.uk-active>a i {
        padding: 0 2px 10px;
    }

    /****/
    .order-details-wrp {
        flex-direction: column;
    }

    .order-details-wrp .w-40 {
        width: 100%;
        max-width: 430px;
        margin: auto;
    }

    .order-details-wrp .details-card .image {
        height: 170px;
    }

    .sharing-card-wrp {
        margin: 20px 5px;
    }

    .sharing-card-wrp h4 {
        font-size: 18px;
    }

    .date-and-time-wrp {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 10px;
    }

    .date-and-time-wrp .dt-card {
        width: 100%;
        border-right: none;
    }

    .date-and-time-wrp .info {
        width: 100%;
        padding-left: 0;
        margin-top: 10px;
    }

    .order-details-wrp .w-60 {
        margin-left: 0;
        width: 100% !important;
        margin-top: 15px;
    }

    .voucher-code h4 {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .voucher-code {
        padding: 15px;
    }

    .voucher-code .code {
        font-size: 26px;
        padding: 15px 40px;
    }

    .voucher-code p {
        padding: 0;
    }

    .order-summery {
        padding: 15px;
        margin-top: 15px;
        flex-direction: column;
    }

    .order-summery-card {
        border-right: none;
        padding-right: 0;
        width: 100%;
    }

    .order-summery .order-details {
        width: 100%;
    }

    .order-summery .order-details h5 {
        display: none;
    }

    .order-summery .order-details {
        width: 100%;
        margin-top: 30px;
    }

    .order-summery .order-details p,
    .order-summery .order-details h4 {
        font-weight: 500;
    }

    .need-help {
        padding: 20px 15px;
        border-radius: 5px;
        flex-direction: column;
        align-items: flex-start;
    }

    .need-help .number a {
        margin-top: 25px;
        display: inline-block;
    }

    .confirmation-wrp .border-btn {
        display: block;
        text-align: center;
    }

    .order-summery-card .order-price {
        flex-direction: initial;
    }

    .order-summery-card .order-price .w-50 {
        width: 50% !important;
    }

    .order-summery-card h4 {
        font-size: 22px;
        text-align: right;
    }

    .order-summery-card p {
        font-size: 14px;
    }

    .rightangle::after {
        display: none;
    }

    /****/
    .date-and-time-wrp .info a.edit-btn {
        display: none;
    }

    .discount-code .code-field label {
        font-size: 12px;
    }

    .code-applied {
        padding-left: 0;
    }

    .order-summery .order-details h4 {
        font-size: 22px;
    }

    .promo-code-applied {
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
    }

    .order-summery .total-amount p {
        font-weight: 500;
    }

    .order-summery .total-amount h4 {
        font-size: 22px;
        font-weight: 500;
        border: none
    }

    .order-details-profile p {
        font-weight: 500;
        margin-bottom: 35px;
        font-size: 16px;
    }

    .order-details-profile h4 {
        font-size: 18px;
    }

    .order-details-profile .btn-box {
        flex-direction: column;
    }

    .profile-link-btn {
        margin: 5px;
        font-size: 14px;
        text-align: center;
    }

    .ordersummary-other-details .btn-yellow {
        display: block;
        text-align: center;
    }

    .order-summery .order-details {
        margin-top: 5px;
    }

    .date-and-time-wrp .info {
        display: flex;
        align-items: center;
    }

    .date-and-time-wrp .info p {
        margin-right: 15px;
    }

    .select-people-drop.mt-1 {
        margin-top: 0px !important;
    }

    .select-people-drop .uk-select {
        min-width: 120px;
    }

    .date-and-time-wrp .uk-form-controls .uk-select {
        max-width: 170px;
    }

    .date-and-time-wrp .uk-form-controls .uk-select:not([multiple]):not([size]) {
        background-position: 90% 50%;
    }

    .edit-btn-wrp .edit-btn {
        right: 20px;
    }

    /**Profile**/
    .container.mt-130.myprofile-wrp {
        margin-top: 20px;
    }

    .myprofile-wrp-inner .w-30,
    .myprofile-wrp-inner .w-70 {
        width: 100%;
    }

    #MyOrder {
        display: none
    }

    .myprofile-wrp.mb-6 {
        margin-bottom: 30px !important;
    }

    .myprofile-left-panel {
        border: none;
        padding: 0;
    }

    .myprofile-form.pl-25,
    .myprofile-order.pl-25,
    .mybucketlist.pl-25 {
        padding-left: 0;
    }

    .myprofile-form-wrp {
        border: none;
        padding: 0;
    }

    .myprofile-left-panel .uk-subnav>.uk-active>a::after {
        border-left: none;
    }

    .myprofile-left-panel .uk-subnav>.uk-active>a {
        background: transparent;
    }

    .full-border-form .form-group {
        margin-bottom: 15px;
        width: 100%;
    }

    .full-border-form .btn-yellow {
        margin-top: 20px;
    }

    .myprofile-order-tab.uk-subnav {
        margin-left: 10px;
    }

    .myprofile-order-tab.uk-subnav li>a {
        padding: 8px 12px;
        font-size: 11px;
    }

    .myorder-card {
        flex-direction: column;
    }

    .myorder-card .image {
        height: 150px;
    }

    .myorder-card .details {
        padding-left: 0;
        margin-top: 25px;
    }

    .myorder-card .details .order-details {
        padding: 15px 0px 15px 0;
        flex-wrap: wrap;
    }

    .myorder-card .details .order-details h6 {
        padding: 0;
        border-left: none;
        text-align: left;
    }

    .myorder-card .details .order-details p {
        padding: 0;
    }

    .myorder-card .details .order-details h4 {
        font-size: 22px;
        padding: 0;
        border-left: none;
        text-align: left;
    }

    .myorder-card .details .order-details h3 {
        padding: 0 0 10px;
    }

    .pagination {
        display: none;
    }

    .mybucketlist-wrp .listing-card {
        width: 100%;
        max-width: 410px;
    }

    .myprofile-form-wrp {
        max-width: 410px;
        margin: auto;
    }

    /****/
    .nav-down {
        position: fixed !important;
        width: 100%;
        background-color: #fff;
        box-shadow: 0px 0px 5px #ddd !important;
    }

    .citymodel .uk-modal-dialog {
        padding: 35px 30px 35px;
    }

    .select-city-model .uk-flex {
        justify-content: center
    }

    .city-card-m a span {
        margin-top: 15px;
    }

    .city-card-m {
        width: 49%;
    }

    .full-border-form .w-32 {
        width: 100% !important;
        margin-bottom: 15px;
    }

    .heading-text-wrp h4 {
        line-height: 30px;
    }

    .order-details-profile {
        min-height: auto;
    }

    .profile-icon {
        margin-top: 0px;
    }

    /**Rock Css Home page**/
    .heading-text-wrp {
        margin-bottom: 70px;
    }

    .heading-text-wrp h4 span {
        letter-spacing: -1px;
    }

    .heading-text-wrp h4 span .large-font {
        font-size: 40px;
    }

    /****/
    .text-gray-innerMenu {
        box-shadow: 0px 0px 5px #ddd !important;
        position: static;
    }

    .center-slider-wrp.center-full-slider-m {
        min-height: initial;
    }

    .center-slider-wrp.center-full-slider-m .img-width {
        min-height: initial;
    }

    .center-slider-wrp .uk-position-small {
        margin: 0;
    }

    .heading-subpara {
        font-size: 13px;
        color: #000
    }

    .heading-w-border.mb-2 {
        margin-bottom: 15px !important;
    }

    /****/
    .categores-card-2 {
        padding: 10px 10px 15px;
    }

    .categores-card-2 .icons {
        padding: 20px 15px;
        margin-bottom: 10px;
        margin-top: -30px;
    }

    .categores-card-2 p {
        font-size: 12px;
        text-transform: uppercase;
    }

    /****/
    .popular-experiences-card.w-46 {
        width: 100%;
        max-width: 420px;
        margin: auto;
        flex-direction: column;
        padding: 15px;
        border-radius: 10px;
    }

    .popular-experiences-card .left-panel.w-55 {
        width: 100%;
    }

    .popular-experiences-card .image {
        height: 170px;
        border-radius: 5px;
        margin-left: 0px;
    }

    .trending img {
        margin-right: 5px;
        width: 12px;
    }

    .trending {
        padding: 5px 14px;
        top: -31px;
        left: 15px;
        min-height: 25px;
    }

    .trending span {
        font-size: 10px;
    }

    .popular-experiences-card .w-45 {
        width: 100%;
    }

    .popular-experiences-card .brand {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 10px;
        text-align: left;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;
    }

    .popular-experiences-card .brand-name {
        width: 100%;
        border-right: none;
        padding-right: 10px;
    }

    .popular-experiences-card .details .logo {
        width: 35%;
        height: initial;
        margin: 0 !important;
        padding: 0 10px;
        display: none;
    }

    .popular-experiences-card .details h5 {
        font-size: 15px;
        margin-bottom: 0px;
    }

    .popular-experiences-card .details h6 {
        display: none;
    }

    .popular-experiences-card .location::after,
    .popular-experiences-card .location::before {
        display: none;
    }

    .popular-experiences-card .location {
        border: none;
        border-radius: 0;
        padding: 0;
        font-size: 12px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .popular-experiences-card .details .logo img {
        height: 60px;
    }

    .popular-experiences-card .price {
        display: flex;
        flex-wrap: wrap;
        xmax-width: 200px;
        margin: auto;
        padding-top: 15px;
        margin-top: 5px;
        align-items: center;
        margin-bottom: 0px;
        justify-content: space-evenly;
    }

    .popular-experiences-card del {
        width: 50%;
        text-align: right;
        padding-right: 15px;
    }

    .popular-experiences-card del .fa-rupee {
        font-size: 60%;
    }

    .popular-experiences-card .offer-price {
        width: 50%;
        text-align: left;
        padding-left: 0px;
    }

    .popular-experiences-card del {
        display: block;
        xcolor: #333333;
        font-size: 18px;
    }

    .popular-experiences-card h4 {
        font-size: 24px;
        margin-top: 0;
    }

    .popular-experiences-card p {
        font-size: 11px;
        margin-bottom: 0;
    }

    .popular-experiences-card .btn-yellow {
        padding: 6px 35px;
        font-weight: 500;
    }

    .popular-experiences-card.mb-4 {
        margin-bottom: 30px !important;
    }

    /****/
    .love-brand-wrp.mt-5 {
        margin-top: 25px !important;
    }

    .love-brand-wrp .uk-child-width-1-2>* {
        width: 40%;
    }

    .trending-wrp.mt-5 {
        margin-top: 20px !important;
    }

    .uk-slider-container.mt-5 {
        margin-top: 15px !important;
    }

    .touch-slider .uk-position-center-right-out {
        top: -45px;
    }

    .touch-slider .uk-position-center-left-out {
        top: -28px;
    }

    /****/
    .trending-wrp {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .trending-card {
        padding: 0px 15px 15px 15px;
    }

    .trending-wrp .container {
        padding-right: 0;
    }

    .trending-wrp .uk-child-width-1-2>* {
        width: 70%;
    }

    .new-arrival-wrp .uk-child-width-1-2>* {
        width: 100%;
    }

    .new-arrival-wrp .popular-experiences-card.w-46 {
        width: 98%;
    }

    /****/
    .categories-slider .uk-position-center-right-out {
        top: -85px;
    }

    .categories-slider .uk-position-center-left-out {
        top: -73px;
    }

    /****/
    .center-slider-wrp-2.offer-slider-center.mt-115 {
        margin-top: 20px;
    }

    .st-listing-pop.mt-5 {
        margin-top: 30px !important;
    }

    .st-listing-pop .heading-w-border.ml-40 {
        margin-left: 0px !important;
        margin-bottom: 35px !important;
    }

    .st-listing-pop .heading-w-border {
        width: 100%;
    }

    /****/
    .mobile-filter-wrp {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }

    .mobile-filter-wrp .m-filter {
        width: 50%;
        padding: 10px 25px 10px 0;
        text-align: right;
        border-right: 1px solid #ddd;
    }

    .mobile-filter-wrp .m-sort {
        width: 50%;
        padding: 10px 0px 10px 25px;
        cursor: pointer;
    }

    .mobile-filter-wrp img {
        margin-right: 10px;
    }

    /****/
    .sort-by-hamburger {
        background-color: #fff;
        padding: 10px 15px 30px 30px;
        position: fixed;
        z-index: 1001;
        width: 100%;
        top: 0;
        height: 100%;
        overflow: auto;
        right: -100%;
        transition: 0.2s linear;
    }

    .hamburger-menu-right {
        right: 0;
    }

    .sort-by-hamburger p {
        display: inline-block;
        color: #1a1a1a;
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 20px;
    }

    .filter-hamburger {
        background-color: #fff;
        position: fixed;
        z-index: 1001;
        width: 100%;
        top: 0;
        height: 100%;
        overflow: auto;
        left: -100%;
        transition: 0.2s linear;
        touch-action: none;
    }

    .filter-hamburger-menu-left {
        left: 0;
    }

    .overflow {
        overflow: hidden;
    }

    /****/
    .uk-width-100 {
        width: 100px;
        background: #f8f8f8
    }

    .filter-hamburger .close-btn {
        background-color: #ffde00;
        height: 40px;
    }

    .filter-hamburger .close-btn .uk-close {
        color: #1a1a1a;
        font-weight: 500;
    }

    .filter-btn {
        background-color: #fff;
        height: 50px;
        box-shadow: 0px 0px 5px #ddd;
        padding-right: 15px;
        padding-top: 10px;
    }

    .m-filter-tab-wrp {
        height: calc(100vh - 142px)
    }

    .m-filter-tab-wrp .uk-width-expand {
        padding: 10px 5px 10px 10px;
    }

    .m-filter-tab-wrp .checkbox-group label span {
        font-size: 14px;
    }

    .filter-btn .btn-yellow,
    .filter-btn .btn-default {
        padding: 5px 20px;
        font-size: 12px;
    }

    .checkbox-group label span {
        font-size: 14px;
    }

    .m-filter-tab-wrp .uk-tab>.uk-active>a {
        color: #1a1a1a;
        border: none;
        background: #fff;
        display: block;
        width: 100%;
    }

    .m-filter-tab-wrp .uk-tab>* {
        width: 100%;
    }

    .m-filter-tab-wrp .uk-tab>*>a {
        padding: 20px 10px;
        color: #1a1a1a;
    }

    /****/
    .pagination.showpagination {
        display: block;
    }

    .pagination .uk-pagination>li>a {
        font-size: 14px;
        margin: 0 12px;
    }

    .pagination .uk-pagination>.uk-active>* {
        font-size: 26px;
    }

    /****/
    .order-summery .total-amount h4 {
        width: calc(100% - 50%);
    }

    .order-summery .total-amount p {
        width: 50%;
    }

    .order-summery .total-amount.convenienceFee p {
        width: 70%;
        font-size: 13px;
    }

    .order-summery .total-amount.convenienceFee h4 {
        font-size: 14px;
    }

    /**31 Oct**/
    .categories-wrp .container {
        padding-right: 15px;
    }

    .categories-wrp-inner .text-info h4 {
        font-size: 16px;
    }

    .categories-wrp-inner .text-info h4 span {
        letter-spacing: 0px;
    }

    .categories-wrp-inner .text-info h4 span .large-font {
        font-size: 20px;
    }

    .filter-new-card-wrp {
        flex-wrap: wrap;
    }

    .filter-new-card.w-23 {
        width: 47%;
    }

    .filter-new-card .uk-position-cover h5 {
        font-size: 11px;
    }

    .filter-new-card .uk-position-cover {
        padding: 5px;
    }

    .filter-new-card p {
        display: none;
    }

    .filter-new-card.w-23 {
        margin-bottom: 20px;
    }

    .filter-new-card .image {
        height: 100px;
        width: 100%;
    }

    .categories-wrp::before {
        height: 260px;
    }

    .filter-new-card [class*="uk-inline"] {
        width: 100%;
    }

    /* .uk-overlay-default {
        background: rgba(255, 255, 255, .3);
    } */
    .filter-new-card .active {
        transform: scale(1.1, 1.1);
    }

    .explore-mar {
        margin-top: -8em;
    }

    /**19 Nov **/
    .information-wrp-inner {
        flex-wrap: wrap;
    }

    .information-wrp-inner .w-30,
    .information-wrp-inner .w-70 {
        width: 100%;
    }

    .information-wrp h4 {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .information-wrp-inner .left-panel ul li a {
        padding: 15px 15px 15px 5px;
    }

    .information-wrp-inner .left-panel ul li a span {
        font-size: 14px;
    }

    .information-wrp-details {
        padding-left: 0;
    }

    .information-wrp-details .info h3 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .information-wrp-details .info.mt-5 {
        margin-top: 30px !important;
    }

    .information-wrp-inner .left-panel.privacy-policy ul li a {
        margin-bottom: 25px;
    }

    /**25 Nov**/
    .ordersummary-wrp .order-details-wrp .details-card {
        min-height: auto;
    }

    /**26 nov**/
    .search-sidenav-wrp {
        top: 0px;
        width: 100%;
        max-width: 420px;
        border-radius: 0px;
        overflow: auto;
        height: 100%
    }

    /**27 Nov**/
    .information-wrp-details.mt-6 {
        margin-top: 30px !important;
    }

    /**30 Nov**/
    .mt-110 {
        margin-top: 85px;
    }

    .contact-card-top {
        flex-direction: column;
    }

    .contact-card-top .contact-card:first-child {
        padding-right: 0;
    }

    .contact-card-top .contact-card {
        padding: 0px 0;
        width: 100%;
    }

    .contact-card-top .contact-card:last-child {
        padding-left: 0;
    }

    .contact-card-top .contact-card a,
    .contact-card-top .contact-card p {
        font-size: 14px;
    }

    .contact-card-top .contact-card:nth-child(2) {
        border-left: none;
        border-right: none;
        padding: 15px 0;
        border-top: 1px solid #666669;
        border-bottom: 1px solid #666669;
        margin: 15px 0;
    }

    .contact-card-field-wrp .border-form-input .form-group {
        width: 100%;
    }

    .contact-card-field-wrp.mt-5 {
        margin-top: 0px !important;
    }

    .contact-card-field-wrp .uk-input {
        height: 45px;
    }

    .contact-card-top .contact-card .icons {
        margin-right: 15px;
        width: 35px;
    }

    .footer-wrp-inner .w-25 {
        border-right: none;
    }

    /****/
    .mybucketlist-wrp .popular-experiences-card.w-46 {
        padding-top: 40px;
    }

    /**10 Dec**/
    .ordersummary-other-details .sticky-btn .btn-yellow {
        float: none;
        margin-top: 0px !important;
    }

    .sticky-btn {
        background: #fff;
        border-top: 1px solid #ddd;
        padding: 15px;
        position: fixed;
        bottom: 0;
        width: 100%;
        margin-top: 0 !important;
        left: 0;
        z-index: 10;
    }

    .sharelink {
        left: initial;
        right: inherit;
    }

    #component-tab-left {
        padding-left: 0;
    }

    .information-wrp h4 {
        font-size: 16px;
    }

    .disc {
        padding-left: 0;
    }

    .alpha-asc {
        padding-left: 15px;
    }

    .information-wrp-inner .left-panel ul li a {
        padding: 15px;
    }

    /**5 Jan**/
    .center-slider-wrp.video-img-slider.mt-8 {
        margin-top: 28px !important;
    }

    .popular-experiences-card del {
        font-size: 15.82px;
        color: rgb(153, 153, 153);
    }

    .back-link .sharelink {
        left: auto;
        right: 10px;
    }

    .popular-experiences-card .price {
        display: flex;
        flex-wrap: wrap;
        padding-top: 15px;
        align-items: flex-start;
        width: 54%;
        float: left;
        justify-content: space-between;
        flex-direction: column;
    }

    .popular-experiences-card del,
    .popular-experiences-card .offer-price {
        width: initial;
    }

    .popular-experiences-card .btn-yellow {
        padding: 6px 30px;
        float: right;
        margin-top: 15px;
        margin-bottom: 5px;
        padding: 8px 40px;
        float: right;
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 1.2em;
    }

    .m-filter-tab-wrp .uk-width-expand {
        overflow: auto;
    }

    .m-filter-tab-wrp {
        height: calc(100vh - 90px);
    }

    .popular-experiences-card .vouchers {
        margin-top: 0;
        float: right;
    }

    /**8 JAn**/
    .touch-slider-wrp-inner .hideMobile {
        display: block;
    }

    .touch-slider-wrp.mt-5.new-arrive-inner-slider {
        margin-top: 25px !important;
    }

    /**25 Jan**/
    .standardCard.w-46.mlf-2 {
        width: 100%;
        margin: 0 !important;
        max-width: 410px
    }

    .popular-experiences-card {
        padding: 15px;
    }

    .popular-experiences-wrp-inner {
        justify-content: center
    }

    /**31 Jan**/
    .discount-code .code-field {
        flex-grow: 7;
    }

    .listing-card .details p,
    .listing-card .details p a {
        min-height: auto;
    }

    .center-icon-play-video {
        position: absolute;
        z-index: 100;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
    }

    .center-icon-play-video .play-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    /**31 Jan**/
    .ordersummary-other-details .btn-yellow {
        float: initial;
        margin-top: 0px !important;
        max-width: 200px;
        margin: auto;
    }

    .floating-btn {
        background: #fff;
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 14px 20px;
        z-index: 100;
        box-shadow: 0px -2px 2px 0px #ddd;
        -webkit-box-shadow: 0px -2px 2px 0px #ddd;
        -moz-box-shadow: 0px -2px 2px 0px #ddd;
    }

    /**1 Feb**/
    .center-slider-wrp.video-img-slider .uk-width-3-4 {
        height: 100vh;
    }

    .center-slider-wrp.video-img-slider.mt-8.center-full-slider-m .uk-width-3-4 {
        height: auto;
    }

    .home-page-banner {
        min-height: 270px;
    }

    /**03 Feb**/
    .center-slider-wrp.video-img-slider.mt-8.show-in-mobile-only {
        margin-top: 0px !important;
    }

    section.center-slider-wrp.video-img-slider.mt-8.show-in-mobile-only .img-width {
        min-height: 100% !important;
    }

    .location-logo {
        position: absolute;
        left: 0em;
        right: unset;
    }

    /****/
    .center-slider-wrp.video-img-slider.details-page-view.mt-8 {
        margin-top: 0px !important;
    }

    /****/
    .progress-donut {
        padding-left: 0;
        position: absolute;
        top: 20px;
        width: 100%;
        display: flex;
        padding: 0 10px;
    }

    .progress-donut li {
        width: 100%;
        height: 4px;
        background: rgba(255, 255, 255, 0.4);
        display: inline-block;
        margin: 0 5px;
        cursor: pointer;
    }

    .progress-donut li.uk-active {
        background: #fff
    }

    /**07 feb**/
    a.btn-view-all.formobilesli {
        display: block;
    }

    /**09 Feb**/
    .deal-slider-wrp .uk-slider-container {
        height: initial;
    }

    .center-slider-wrp.video-img-slider.deal-slider-wrp .uk-width-3-4 {
        height: initial !important;
    }

    /**29 March**/
    .hidelg-link {
        display: block !important;
    }

    /**767**/
    section.contact-us-wrp .uk-flex-column {
        flex-direction: column-reverse;
    }

    .contact-card-top {
        margin-top: 40px;
    }

    .logo-mob {
        position: absolute;
        right: 7px;
        bottom: 7px;
        width: 80px;
        display: block;
        z-index: 9;
        border-radius: 0 0px 5px 0px;
        overflow: hidden;
    }
}


/**Angular css**/

.ngx-spinner-icon {
    display: none !important;
}

.sharelink {
    background-color: gray;
}

.citymodel.uk-modal-container .uk-modal-dialog {
    width: 600px;
}

.selected-city-card .info {
    cursor: pointer;
}

.key-highlight-info-details p {
    line-height: 32px;
    font-size: 14px;
}

.hightlight-point ul li {
    margin: 15px 0;
}

.image-top img {
    height: 100%;
}

.image-bottom img {
    height: 100%;
}

.hightlight-point h5 {
    margin-top: 20px;
}


/**Qadeer Css**/

section.center-slider-wrp.video-img-slider.mt-8.show-in-mobile-only {
    min-height: auto;
}

section.center-slider-wrp.video-img-slider.mt-8.show-in-mobile-only .img-width {
    min-height: auto;
}

.location-bar .icon {
    font-size: 22px;
}

.location-bar .icon img {
    width: 15px;
}

.location-bar p span {
    display: inline;
    vertical-align: bottom;
    color: #333333;
}

span.fa.fa-share-alt.icon {
    padding-right: 1em;
    vertical-align: unset;
    cursor: pointer;
}

.location-bar div span:last-child {
    padding-left: 1em;
    border-left: 1px solid #d9d9d9;
    vertical-align: unset;
    cursor: pointer;
    font-size: 22px;
}

.location-bar span.place {
    padding-right: 2em;
}

span.location-pin.icon {
    padding-right: 5px;
}

.icon-anchor {
    color: #333333;
    display: inline-block;
    line-height: 1;
}

.icon-anchor:hover {
    color: #333333;
}

.location-bar .place {
    font-size: 14px;
    color: #333333;
    line-height: 1.567;
    text-align: left;
}

.info-section a.icon-anchor:last-child {
    padding-left: 1em;
    border-left: 1px solid #d9d9d9;
    vertical-align: unset;
    cursor: pointer;
    font-size: 22px;
}

.location-title {
    font-size: 24px;
    color: #1a1a1a;
    /* line-height: 1.4; */
    text-align: left;
    padding: 10px 0;
    width: 75%;
    font-weight: 600;
}

.location-type {
    line-height: 1.4;
    text-align: left;
    color: #333333;
    font-size: 14px;
    font-weight: 300;
    display: block;
    margin: 0;
}

.location-logo {
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
}

.location-bar {
    position: relative;
}

.location-copy p {
    color: #333333;
    text-align: left;
    line-height: 32px;
    font-size: 16px;
    line-height: 32px;
    font-size: 14px;
}

.experience-title {
    display: inline-block;
    background: #fff;
    z-index: 1;
    padding-right: 1em;
    color: #1a1a1a;
    font-size: 18px;
    font-weight: 500;
}

.experience-title:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    /* transform: translateY(-50%); */
    z-index: -1;
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
}

.experience-header {
    position: relative;
}

.location-copy {
    margin-bottom: 2.5em;
}

.food-label {
    margin-right: 3em;
}

.switch {
    display: inline-block;
    height: 20px;
    position: relative;
    width: 60px;
    width: 45px;
    height: 13px;
}

.switch input {
    display: none;
}

.slider {
    background-color: #f0f0f0;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
}

.slider:before {
    background-color: #626060;
    bottom: -4px;
    content: "";
    height: 26px;
    left: 0px;
    position: absolute;
    transition: 0.4s;
    width: 26px;
    width: 22px;
    height: 22px;
}

input:checked+.slider {
    background-color: #ffee7f;
}

input:checked+.slider:before {
    background-color: #f7d700;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.uk-flex.veg-non-veg-section {
    margin-top: 2em;
}

.veg-copy,
.non-veg-copy {
    color: #333333;
    font-size: 16px;
    padding-left: 0.8em;
    line-height: 2;
}

.selling-item-container {
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
    padding: 1em;
    margin-top: 1.5em;
    margin-bottom: 1em;
    position: relative;
    /* z-index: 1; */
    display: block;
}

.offer-section {
    width: 80%;
    margin-top: 0.5rem;
    /* border-right: 1px solid #e5e5e5;
    padding-top: 1em;*/
}

.price-section {
    width: 20%;
    padding-left: 15px;
    border-left: 1px solid #e5e5e5;
}

.prices {
    margin-bottom: 1rem;
}

.offer-section .offer-copy {
    font-size: 20px;
    line-height: 1.3;
    color: #1a1a1a;
    font-weight: bold;
    margin-bottom: 0.8em;
}

.buffet-copy {
    font-size: 16px;
    color: #333333;
    line-height: 100%;
    margin: 0.4em 0 0.9em;
}

.more-details {
    color: #333;
    position: relative;
    display: inline-block;
    font-size: 12px;
    font-weight: 300;
    cursor: pointer;
    margin-bottom: 1em;
}

.more-details:hover {
    color: #000;
}

.more-details:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: #333;
}

p.striked {
    font-size: 15.82px;
    color: rgb(153, 153, 153);
    display: inline-block;
    position: relative;
    padding: 0 1em;
    /* display: block; */
    color: #f66c40;
    font-size: 14px;
}

p.striked:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    background: #f66c40;
    height: 1px;
    width: 100%;
}

p.current-price {
    font-size: 30px;
    color: #1a1a1a;
    font-weight: 500;
    line-height: 100%;
    text-align: right;
}

.if-fillwed p.current-price {
    text-align: left;
}

.current-price>span {
    font-size: 10px;
    color: #333333;
    vertical-align: middle;
    display: inline-block;
    padding-left: 5px;
}

.people-count {
    color: #333333;
    font-size: 15px;
    /* font-weight: 600; */
    /* border-right: 1px solid #e3e3e3; */
    padding-right: 0;
    width: 100%;
    text-transform: capitalize;
    margin-bottom: 0.8rem;
}

.validity {
    padding-left: 0px;
    width: 100%;
    margin-bottom: 0.8em;
}

.validity-copy {
    font-weight: 300;
    font-size: 13px;
    color: #333333;
    display: inline-block;
    line-height: 100%;
    vertical-align: bottom;
}

.validity-days {
    font-size: 16px;
    color: #333333;
    padding-left: 1em;
    line-height: 100%;
    display: inline-block;
    vertical-align: bottom;
}

span.validity-timing {
    color: #333;
    padding-right: 15px;
    font-size: 15px;
}

.current-price i,
.striked i {
    font-size: 60%;
}

.select-div {
    border-radius: 50px;
    background-color: rgb(255, 255, 255);
    padding: 7.3px 19px;
    border: 1px solid #d7d7d7;
    font-size: 11px;
    color: #1a1a1a;
    text-transform: uppercase;
    line-height: 1.144;
    font-weight: 600;
    text-align: center;
}

.selected-div {
    border-radius: 50px;
    background-color: rgb(255, 255, 255);
    padding: 7.3px 19px;
    border: 1px solid #d7d7d7;
    font-size: 11px;
    color: #1a1a1a;
    text-transform: uppercase;
    line-height: 1.144;
    font-weight: 600;
}

.selling-item:after {
    content: "";
    background: #efefef;
    height: 100px;
    width: 100%;
    bottom: -6px;
    left: 0;
    position: absolute;
    border-radius: 20px;
    z-index: -1;
    transition: all 0.5s ease-in;
}

.selling-item:before {
    content: "";
    background: #efefef;
    /*height: calc(100% - 20%);*/
    height: 100%;
    width: 100px;
    left: -6px;
    bottom: -6px;
    position: absolute;
    border-radius: 20px;
    transition: all 0.5s ease-in;
}

.selling-item.selected:after,
.selling-item.selected:before {
    background: #ffde00;
}

.selling-item {
    position: relative;
    margin-top: 35px;
}

.validity select {
    border: none;
    color: #333;
    font-size: 15px;
    line-height: inherit;
    font-weight: 400;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    min-width: 160px;
    cursor: pointer;
    padding-right: 2em;
    background-image: url("assets/images/icons/arrow-bottom-2.png");
    background-repeat: no-repeat;
    background-position: right;
    font-family: "Poppins";
    background-color: #fff;
    background-size: 12px;
}

.selling-item.selected:after {
    /* background: #ffde00; */
}

h3.address-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.address-copy {
    color: #333333;
    font-size: 13px;
}

.address-bar {
    padding: 2.5em 0;
}

.outlets-dropdown {
    width: 280px;
    margin: 2.5em 0 2em;
}

.outlets-dropdown .guest-card {
    border: 1px dashed rgb(0 0 0 / 35%);
    padding: 10px 20px;
}

.text-center {
    text-align: center !important;
}

.if-fillwed {
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 0.5s;
    animation-name: fadeIn;
    animation-duration: 0.5s;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.missing-copy {
    font-size: 19px;
    font-family: "Poppins";
    color: #333333;
    margin-top: 1em;
}

.option-copy {
    color: #1a1a1a;
    font-weight: 300;
}

.small-copy {
    font-size: 12px;
    color: #aeaeae;
    margin: 3em 0;
}

.btn-block a {
    text-transform: uppercase;
}

.filled-heading {
    font-size: 16px;
    color: #1a1a1a;
    padding: 0 1em;
    text-transform: uppercase;
    position: relative;
}

.filled-heading:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 10px;
    background: #ffde00;
    left: -20px;
    top: 7px;
}

.cart-buffet-copy {
    font-size: 16px;
    color: #1a1a1a;
}

.cart-quantity {
    font-size: 14px;
    margin: 1em 0 4em;
    color: #333;
}

.pay-copy {
    font-size: 16px;
    color: #1a1a1a;
    font-weight: 600;
    margin-bottom: 10px;
}

a.btn-yellow.btn-full[disabled] {
    cursor: not-allowed;
    background: #fff !important;
    border-color: rgb(235, 235, 235);
    color: #cacaca;
}

.grey-bg {
    background: url(assets/images/grey-bg.png);
    background-size: contain;
    background-repeat: repeat-x;
}

.popover-modal {
    display: none;
    position: absolute;
    background: #fff;
    padding: 1em;
    -webkit-box-shadow: 0px 0px 8px 0px rgb(218 218 218);
    -moz-box-shadow: 0px 0px 8px 0px rgb(218 218 218);
    box-shadow: 0px 0px 8px 0px rgb(218 218 218);
    padding: 20px;
    border-radius: 5px;
    width: 70%;
}

.popover-modal ul,
.popover-modal-details ul {
    list-style-type: disc;
    list-style-position: outside;
    margin-top: 1em;
    overflow: hidden;
    /* padding-left: 0; */
    margin-left: -1em;
}

.popover-modal ul li,
.popover-modal-details ul li {
    width: 50%;
    float: left;
    padding-left: 0;
    margin-bottom: 1em;
    font-size: 11px !important;
    padding-right: 24px;
}

.popover-details li {
    width: 100% !important;
    font-size: 14px !important;
    min-height: 120px;
}

.modal-block:hover .popover-modal {
    display: block;
    z-index: 4;
}

.popover-modal:after {
    content: "";
    position: absolute;
    left: 3px;
    top: -12px;
    margin-left: -3px;
    border: 7px solid #db0f0f00;
    background-color: transparent;
    border-bottom-color: #fff;
}

.switcher {
    display: inline-block;
    position: relative;
}

.switcher input {
    display: none;
}

input:checked+.sliderr {
    display: block;
}

.sliderr.roundd {
    border-radius: 34px;
}

.sliderr {
    cursor: pointer;
    transition: 0.4s;
}

.selected .sliderr:before {
    display: block;
}

.selected .sliderr:before {
    background-color: #ffffff;
    background-image: url(assets/images/tick.png);
    background-position: center;
    background-size: contain;
}

.sliderr.roundd:before {
    border-radius: 50%;
}

.sliderr:before {
    content: "";
    transition: 0.4s;
    width: 2.2em;
    height: 2.2em;
    display: none;
}

label.switcher {
    display: flex;
    border-radius: 50px;
    border: 1px solid #ccc;
    padding: 3px;
    cursor: pointer;
    height: 40px;
    align-items: center;
}

.selection {
    /* border-radius: 50px; */
    /* border: 1px solid #ccc; */
    /* padding: 5px; */
}

.select-copy {
    padding: 0px 1.3em;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    /* line-height: 2.2; */
    color: #1a1a1a;
}

.select-copy2 {
    display: none;
}

.selected .select-copy1 {
    display: none;
}

.selected .select-copy2 {
    display: block;
}

.selected label.switcher {
    border: 1px solid #ffde00;
    background: #ffde00;
}

.location-bar .sharelink {
    top: 2em;
    left: 3em;
}

.modal-block {
    display: inline-block;
}

.gallery-item img {
    display: block;
    width: 100%;
}

.gallery-item.item-right img {
    width: 50%;
    float: left;
    padding-left: 11px;
    /* padding-bottom: 10px; */
}

.gallery {
    border-radius: 18px;
    overflow: hidden;
}

.w-56 {
    width: 56% !important;
}

.w-44 {
    width: 44% !important;
}

.gallery-item.item-right img:nth-child(3),
.gallery-item.item-right img:nth-child(4) {
    padding-top: 7px;
}

input.quantity-input {
    width: 100%;
    border: 1px solid #bdbdbd;
    color: #333333;
    padding: 0.7em 1em;
    border-radius: 5px;
    position: relative;
    text-align: center;
    font-size: 16px;
    background: #fff;
}

.number {
    color: #333333;
    position: relative;
    margin-top: 0px;
}

span.minus {
    position: absolute;
    top: 50%;
    z-index: 3;
    transform: translateY(-50%);
    left: 1em;
    cursor: pointer;
}

span.plus {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1em;
    font-size: 17px;
    cursor: pointer;
}

.gallery-item.item-left a:first-child {
    width: 56.5%;
    float: left;
    padding-left: 0;
    position: relative;
}

.gallery-item a {
    float: left;
    padding-left: 10px;
    width: 21.75%;
}

.gallery-item a:nth-child(4),
.gallery-item a:nth-child(5) {
    padding-top: 12px;
}

.gallery {
    /* padding-top:30px; */
}

a.btn-view-all {
    color: #1a1a1a;
    position: absolute;
    bottom: 2em;
    right: 1em;
    padding: 0.8em 2em;
    z-index: 1;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    width: auto;
}

.gallery-item.item-left {
    position: relative;
    overflow: hidden;
}

a.btn-view-all:after {
    content: "";
    background: #fff;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0.8;
    z-index: -1;
    border-radius: 50px;
}

a.btn-view-all:hover:after {
    opacity: 1;
}

a.btn-view-all img {
    width: 20px;
    margin-right: 1em;
}

.hot-icon {
    position: absolute;
    top: -10px;
    z-index: 3;
}

.icon img.pool {
    width: 24px;
}

.equal-divs>div {
    width: 33.33%;
    border-right: 1px solid #efefef;
}

.equal-divs>div:last-child {
    border: none;
}

.hotel-item .buffet-copy {
    font-size: 18px;
    color: #333333;
    line-height: 100%;
    margin: 0.4em 0 1.5em;
    font-weight: 300;
}

.hotel-item .offer-section .offer-copy {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
    padding-top: 1em;
}

.provides {
    padding-left: 0;
}

.provides li {
    font-size: 13px;
    margin-bottom: 10px;
}

.provides li * {
    vertical-align: middle;
    display: inline-block;
}

.provides li span {
    padding-left: 1em;
    /* display: inline-block; */
}

.provides li img {
    width: 19px;
}

.provides li:last-child {
    margin-bottom: 0;
}

ul.complimentary {
    list-style-type: disc;
    /* margin-left: -1em; */
}

ul.complimentary li {
    margin-bottom: 10px;
    font-size: 13px;
}

.hotel-item .more-details {
    margin-left: 30px;
    padding-top: 1em;
}

.hotel-item .price-section {
    border: none;
    width: 100%;
}

.hotel-item .selection {
    display: inline-block;
    margin-top: 2em;
}

.complimentary-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.equal-divs {
    align-items: normal;
}

.hotel-images .container {
    position: relative;
    /* top: 15px; */
}

.hotel-images .hot-icon {
    top: -9px;
    left: 4em;
}

.more-popup.uk-modal-dialog {
    width: 35%;
    padding: 20px;
    border-radius: 5px;
}

.uk-modal-page {
    overflow: visible !important;
}

.uk-modal-page>body {
    overflow-y: visible !important;
    overflow-x: hidden !important;
}

.hotel-images {
    /* padding-top: 15px; */
}

.amenities-list ul {
    list-style-type: disc;
    display: block;
    clear: both;
    overflow: hidden;
    margin-left: -1em;
}

.amenities-list ul li {
    width: 25%;
    float: left;
    font-size: 13px;
    padding-top: 10px;
}

.amenities-list {
    clear: both;
}

.amenities-list .more-details {
    margin-top: 2em;
    margin-bottom: 0;
}

.current-room hr {
    margin-top: 0;
    margin-left: 10px;
}

.offer-img-section {
    margin-top: -4em;
    border-radius: 20px;
}

.current-room.selling-item-container {
    margin-top: 4em;
}

ul.room-detail-list li {
    font-size: 12px;
    width: 50%;
    float: left;
    padding-bottom: 15px;
}

ul.room-detail-list {
    padding-left: 0;
}

ul.room-detail-list li img {
    padding-right: 10px;
    width: 30px;
}

.room-details .offer-section {
    padding: 0em 0em 0 2em;
}

ul.room-detail-list li:nth-child(2n+1) {
    clear: left;
}

.amenities-list b {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 600;
}

.images-section {
    padding-top: 15px;
}

.hotel-bookings .info {
    width: 100%;
}

.hotel-bookings .guest-card {
    margin-bottom: 0.5em;
    padding: 9px;
}

.guest-card.uk-flex.uk-flex-middle.w-50 {}

.hotel-bookings .guest-card:last-child {
    border-left: none;
}

.hotel-bookings .filled-heading {
    padding: 0;
    padding-bottom: 1em;
}

.prices.text-center {
    margin-top: 2em;
}

.mobile-price-section .selection {
    display: none;
}

.show-in-mobile {
    display: none;
}

.modal-item-title {
    position: relative;
}

.modal-list-item {
    margin-top: 2em;
}

ul.staycation-list li {
    font-size: 14px;
    font-weight: 300;
    padding-left: 7px;
}

.popover-modal-details .modal-head {
    font-size: 25px;
    color: #1a1a1a;
    font-weight: 400;
}

.popover-modal-details .sub-head {
    font-size: 14px;
    color: #1a1a1a;
    font-weight: 300;
}

.close-date-popup {
    display: none;
}

.show-in-mobile-only {
    display: none;
}

.feaBlock {
    margin: 20px 0;
    text-align: left;
}

.info-section .outlets-dropdown {
    z-index: 4;
    position: relative;
}

.popover-modal-details ul.uk-subnav {
    list-style-type: none;
    margin-top: -20px;
    margin-left: 0;
}

.popover-modal-details ul.uk-subnav li {
    width: auto;
    padding-right: 0;
    margin-bottom: 0;
    border-top: 4px solid #ffffff;
}

.popover-modal-details ul.uk-subnav li a {
    color: #1a1a1a;
    padding: 8px 23px;
}

.popover-modal-details ul.uk-subnav li.uk-active {
    border-top: 4px solid #fff000;
    background-color: #e3e3e3;
}

.popover-modal-details ul.uk-switcher {
    margin-left: 0;
    border-top: 1px solid #ccc;
    margin-top: 0 !important;
    padding: 1em 0;
    list-style-type: none;
}

.popover-modal-details ul.uk-subnav li.uk-active a {
    background: #e3e3e3;
}

ul.staycation-list {
    list-style-type: none;
    list-style-image: url(assets/images/icons/tick.jpg);
}

.validity-timings {
    color: #033333;
    font-size: 14px;
    line-height: inherit;
    font-weight: 400;
}

.gallery-item.item-left a:first-child img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.sticky-section {
    position: sticky;
    top: 100px;
}

.images-section~* .touch-slider-wrp a.btn-yellow {
    color: #1a1a1a;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .price-section {
        padding-left: 20px;
    }

    .validity {
        padding-left: 20px;
    }

    .selling-item-container {
        padding: 1.5em;
    }
}

@media (min-width: 320px) and (max-width: 991px) {
    .location-title {
        font-size: 24px;
    }

    .selling-item-container>.uk-flex {
        flex-direction: column;
    }

    .selling-item-container>.uk-flex>* {
        width: 100% !important;
    }

    .selling-item-container {
        padding: 2em 1.2em;
        margin-left: 0.5em;
        margin-right: 0.5em;
    }

    .price-section {
        padding-left: 0;
        border-left: none;
    }

    .price-section .selection {
        display: none;
    }

    .mobile-price-section .selection {
        display: flex;
        justify-content: center;
    }

    .selling-item-container>.uk-flex>.mobile-price-section {
        width: auto;
        margin: 2.5em 0 1em;
        text-align: center;
    }

    .select-copy {
        padding: 0px 2.3em;
    }

    .show-in-mobile {
        display: block;
    }

    .show-in-desktop {
        display: none;
    }

    .price-section hr {
        display: none;
    }

    .price-section>.uk-flex>* {
        width: 50%;
    }

    .validity {
        padding-left: 0;
        width: 100%;
    }

    .validity hr {
        display: none;
    }

    .modal-item-title {
        position: relative;
    }

    .modal-list-item {
        margin-top: 2em;
    }

    ul.staycation-list li {
        font-size: 14px;
        font-weight: 300;
        padding-left: 7px;
    }

    .popover-modal-details .modal-head {
        font-size: 25px;
        color: #1a1a1a;
        font-weight: 400;
    }

    .popover-modal-details .sub-head {
        font-size: 14px;
        color: #1a1a1a;
        font-weight: 300;
    }

    .close-date-popup {
        display: none;
    }

    .show-in-mobile-only {
        display: none;
    }

    .feaBlock {
        margin: 20px 0;
        text-align: left;
    }

    .info-section .outlets-dropdown {
        z-index: 4;
        position: relative;
    }

    .popover-modal-details ul.uk-subnav {
        list-style-type: none;
        margin-top: -20px;
        margin-left: 0;
    }

    .popover-modal-details ul.uk-subnav li {
        width: auto;
        padding-right: 0;
        margin-bottom: 0;
        border-top: 4px solid #ffffff;
    }

    .popover-modal-details ul.uk-subnav li a {
        color: #1a1a1a;
        padding: 8px 23px;
    }

    .popover-modal-details ul.uk-subnav li.uk-active {
        border-top: 4px solid #fff000;
        background-color: #e3e3e3;
    }

    .popover-modal-details ul.uk-switcher {
        margin-left: 0;
        border-top: 1px solid #ccc;
        margin-top: 0 !important;
        padding: 1em 0;
        list-style-type: none;
    }

    .popover-modal-details ul.uk-subnav li.uk-active a {
        background: #e3e3e3;
    }

    ul.staycation-list {
        list-style-type: none;
        list-style-image: url(assets/images/icons/tick.jpg);
    }

    .validity-timings {
        color: #033333;
        font-size: 14px;
        line-height: inherit;
        font-weight: 400;
    }
}

@media (max-width: 767px) {
    .info-section .listshare:first-child {
        display: none;
    }

    .location-title {
        width: 100%;
    }
}

.location-logo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.sticky-section {
    position: sticky;
    top: 100px;
}

.images-section~* .touch-slider-wrp a.btn-yellow {
    color: #1a1a1a;
}

@media (min-width: 992px) and (max-width: 1200px) {
    .price-section {
        padding-left: 20px;
    }

    .validity {
        padding-left: 20px;
    }

    .selling-item-container {
        padding: 1.2em;
    }
}

@media (min-width: 320px) and (max-width: 991px) {
    .selling-item-container>.uk-flex {
        flex-direction: column;
    }

    .selling-item-container>.uk-flex>* {
        width: 100% !important;
    }

    .selling-item-container {
        padding: 1.2em;
        margin-left: 0.5em;
        margin-right: 0.5em;
    }

    .price-section {
        padding-left: 0;
        border-left: none;
    }

    .price-section .selection {
        display: none;
    }

    .mobile-price-section .selection {
        display: flex;
        justify-content: center;
    }

    .location-title {
        width: 100%;
    }

    .selling-item-container>.uk-flex>.mobile-price-section {
        width: auto;
        margin: 1.5em 0 0em;
        text-align: center;
    }

    .select-copy {
        padding: 0px 2.3em;
    }

    .show-in-mobile {
        display: block;
    }

    .show-in-desktop {
        display: none;
    }

    .price-section hr {
        display: none;
    }

    .price-section>.uk-flex>* {
        width: 50%;
    }

    .validity {
        padding-left: 0;
        width: 100%;
    }

    .validity hr {
        display: none;
    }

    span.validity-timing {
        display: inline;
        font-size: 15px;
        padding-right: 5px;
        /* color: #333333;*/
    }

    .validity select {
        /*  margin-top: 1em;*/
        color: inherit;
    }

    .people-count {
        padding-right: 1.5em;
    }

    .price-section>div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .current-price>span {
        display: block;
        padding-left: 0;
        text-align: left;
    }

    .prices {
        padding-left: 0em;
        padding-right: 1em;
        margin-bottom: 0;
    }

    .popover-modal {
        width: 95%;
    }

    .popover-modal ul li {
        width: 100%;
        padding-right: 0;
    }

    .selling-item:before {
        content: "";
        /* background: #efefef; */
        /* height: calc(100% - 43%); */
        /* width: 100px; */
        left: 0;
        /* bottom: -8px; */
        /* position: absolute; */
        /* border-radius: 20px;*/
    }

    .selling-item:after {
        content: "";
        background: #efefef;
        height: 100px;
        bottom: -8px;
        left: 0;
        width: 97%;
        position: absolute;
        border-radius: 20px;
        z-index: -1;
    }

    .modal-block~hr {
        margin-top: 10px;
    }

    .offer-section .offer-copy {
        font-size: 20px;
    }

    .buffet-copy {
        font-size: 16px;
        color: #333333;
        line-height: 100%;
        margin: 0.1em 0 1em;
    }

    .offer-section {
        padding-top: 0;
    }

    .validity-days {
        font-size: 13px;
        color: #333333;
    }

    .validity select {
        font-size: 15px;
        display: inline;
        width: calc(100% - 104px);
    }

    p.current-price {
        font-size: 28px;
        text-align: left;
    }

    .select-copy {
        font-size: 10.43px;
    }

    label.switcher {
        padding: 0px;
        height: 33px;
    }

    .selling-item-container .hot-icon {
        position: relative;
        margin-top: calc(-32px + 5px);
        z-index: 3;
        padding-bottom: 1em;
    }

    .location-copy p {
        font-size: 16px;
    }

    .address-bar {
        padding: 2.5em 0 0;
    }

    .book-now-card .price p.striked {
        font-size: 15.82px !important;
        color: rgb(153, 153, 153) !important;
        font-weight: 400 !important;
    }

    .location-bar {
        margin-top: -2em;
    }

    .more-popup.uk-modal-dialog {
        width: 100%;
    }

    ul.staycation-list li {
        width: 100%;
    }

    section.book-now-card.uk-flex.hidelg.hidetab {
        left: 0;
    }

    .room-details .offer-section {
        padding: 1.5em 0em 0em;
    }

    .current-room.selling-item-container {
        margin-top: 4em;
        padding: 2em 1em;
    }

    .hotel-item .offer-section .offer-copy {
        padding-top: 0;
    }

    .amenities-list ul li {
        width: 50%;
    }

    .hotel-item .more-details {
        margin-left: 0;
        margin-top: 0.5em;
        margin-bottom: 0;
        padding-top: 0;
    }

    .provides {
        margin-bottom: 1em;
    }

    .provides li {
        width: 50%;
        float: left;
    }

    ul.complimentary {
        display: none;
    }

    .hotel-item .buffet-copy {
        margin: 0.4em 0 1em;
    }

    .hotel-item .more-details span:first-child {
        display: none;
    }

    ul.complimentary {
        margin-left: -1em;
    }

    .hotel-item .price-section .prices {
        padding-left: 0;
        width: 100%;
    }

    .hotel-item .current-price>span {
        display: block;
    }

    .hotel-item .price-section .selection {
        display: flex;
        margin-top: 1em;
    }

    .date-popup {
        display: none;
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 101;
        background: #fff;
    }

    .date-popup .guest-booking {
        position: absolute;
        top: 50%;
        width: 100% !important;
        height: auto !important;
        padding-top: 4em;
        transform: translateY(-50%);
        box-shadow: none;
    }

    .date-popup .prices {
        padding-left: 0;
    }

    .close-date-popup {
        position: absolute;
        right: 1em;
        top: 1em;
        width: 25px;
        height: 25px;
        display: block;
    }

    .current-room.selling-item-container hr {
        margin-left: 0;
    }

    .more-details {
        /* padding-right: 1em; */
        /* background-image: url(right-chevron.jpg); */
        /* background-position: right center; */
        /* background-repeat: no-repeat; */
        /* background-size: auto; */
    }

    .equal-divs>div {
        border: none;
    }

    .location-logo img {
        height: 40px;
        width: auto;
    }
}

@media (max-width: 767px) {
    .location-logo {
        position: absolute;
        right: 1em;
        transform: unset;
        padding: 10px;
        background: #fff;
        border-radius: 10px;
        width: 100px;
        top: unset;
        bottom: 100%;
        text-align: center;
    }

    .location-logo img {
        /* height: 65px; */
        /* width: auto; */
    }

    .show-in-mobile-only {
        display: block;
    }

    .show-in-desktop-ipad {
        display: none;
    }

    span.fa.fa-share-alt.icon {
        display: none;
    }

    .location-bar div span:last-child {
        display: none;
    }

    .icon-anchor {
        display: none;
    }

    /**2 Dec**/
    #order-summary .uk-modal-dialog {
        padding: 25px 20px 30px;
    }

    .voucher-code .code {
        font-size: 20px;
        padding: 15px 30px;
    }

    .need-help .number a {
        padding: 8px 36px;
    }
}

@media (max-width: 480px) {
    .session-timer br {
        display: block;
    }
}

@media (max-width: 320px) {
    p.current-price {
        font-size: 23px;
    }

    .food-label {
        margin-right: 2em;
    }
}


/*custom select box css*/

.cusSelBlock {
    height: 25px;
    min-width: 100%;
}

#cusSelectbox {
    height: 100%;
    width: 100%;
}

.s-hidden {
    visibility: hidden;
}

.cusSelBlock {
    display: inline-block;
    position: relative;
    perspective: 800px;
}

.selectLabel {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    background-color: #fff;
    /* border: 1px solid #333; */
    /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); */
    color: #333;
    cursor: pointer;
    display: block;
    height: 100%;
    width: 100%;
    /* letter-spacing: 2px; */
    line-height: 28px;
    padding: 0 19px 0 0px;
    text-align: left;
    transform-style: preserve-3d;
    transform-origin: 50% 0%;
    transition: transform 300ms;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 13.33px;
    color: #1a1a1a;
}

.selectLabel:after {
    content: "\25BC";
    content: "";
    background-image: url(assets/images/icons/arrow-bottom-2.png);
    background-repeat: no-repeat;
    background-position: center;
    /* border-left: 1px solid #333; */
    color: #333;
    font-size: 12px;
    line-height: 17px;
    padding: 10px;
    text-align: center;
    position: absolute;
    right: 0px;
    top: 20%;
    /* height: 70%; */
    /* width: 50px; */
}

.selectLabel:active {
    transform: rotateX(30deg);
}

.selectLabel:active:after {
    content: "\25B2";
    content: "";
}

.selectLabel.active:after {
    content: "\25B2";
    content: "";
}

::-webkit-scrollbar {
    display: none;
}

.options {
    position: absolute;
    top: 40px;
    height: 1px;
    width: 100%;
}

.options li {
    background-color: #ffffff;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px dashed #ccc;
    cursor: pointer;
    display: block;
    line-height: 25px;
    list-style: none;
    opacity: 1;
    padding: 0 8px 0 8px;
    text-align: left;
    transition: transform 300ms ease;
    position: absolute;
    top: -40px;
    left: 0;
    z-index: 0;
    height: 25px;
    width: 100%;
    font-size: 13px;
}

.options li:hover,
.options li.active {
    background-color: #ffde00;
    color: #1a1a1a;
}

.options li:nth-child(1) {
    /* transform: translateY(2px); */
    z-index: 8;
}

.options li:nth-child(2) {
    /* transform: translateY(4px); */
    z-index: 7;
}

.options li:nth-child(3) {
    z-index: 6;
}

.options li:nth-child(4) {
    z-index: 5;
}

.options li:nth-child(5) {
    z-index: 4;
}

.options li:nth-child(6) {
    z-index: 1;
}

.sb-icon:hover {
    color: #ffde00;
}

.key-highlight-info-header:after {
    content: '';
    background: #ffde00;
    position: absolute;
    top: -5em;
    left: -100%;
    height: calc(100% + 9em);
    width: 500%;
    z-index: -1;
}

.key-highlight-info-header {
    position: relative;
}

.center-slider-wrp~.book-now-card~section.wrapper {
    overflow-x: clip;
}

.search-result h5 {
    font-size: 24px;
    padding-bottom: 20px;
}

.search-result h5 span {
    font-weight: 600;
}


/****/

.filter-new-card-wrp.uk-grid .filter-new-card {
    margin: 0;
    padding: 0 15px;
}

.filter-new-card-wrp.uk-grid {
    margin-left: 0;
    margin-right: 0;
}

.filter-new-card-wrp~.uk-slidenav {
    background: #ffde00;
}

.filter-new-card-wrp~.uk-slidenav-previous.uk-slidenav {
    left: -17px;
}

.filter-new-card-wrp~.uk-slidenav-next.uk-slidenav {
    right: -17px;
}

.filter-new-card-wrp~.uk-slidenav-previous.uk-slidenav svg,
.filter-new-card-wrp~.uk-slidenav-next.uk-slidenav svg {
    width: 10px;
}

.active.acc-title::before {
    /* background-image: url(up-chevron.png); */
    content: "\f106";
}

.acc-title::before {
    content: "";
    /* width: 1.4em; */
    /* height: 1.4em; */
    margin-left: 10px;
    float: right;
    /* background-image: url(down-chevron.png); */
    /* background-repeat: no-repeat; */
    /* background-position: 50% 50%; */
    /* background-size: contain; */
    content: "\f107";
    font-family: 'FontAwesome';
    font-size: 35px;
    line-height: 0.5;
    color: #333;
}

.acc-content {
    display: flow-root;
    max-height: 0;
    transition: max-height 0.5s ease-out;
    overflow: hidden;
}

.active~.acc-content {
    max-height: 1200px;
    transition: max-height 0.5s ease-in;
}

.acc-content>:last-child {
    margin-bottom: 0;
}

.no-arrow {
    opacity: 0;
    visibility: hidden;
}

.border-pink {
    border-right: 1px solid #e7839e;
    border-bottom: 5px solid #e7839e;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

.loginSignup-btn.login-copy {
    border: none;
    font-weight: bold;
    color: #1a1a1a;
    font-size: 1.2em;
}

.faq-wrp .highlight-accordions-wrp-inner .uk-accordion-title {
    padding: 20px 25px;
    cursor: pointer;
}

.contact-card-field-wrp.mt-5.w-1020 {
    width: 100%;
}

.price-section .switch-btn,
.price-section .counter-wrp {
    float: right;
}

.selling-item-container .prices {
    text-align: right;
}

.touch-slider .uk-position-center-left-out:after {
    content: "\f104";
    font-size: 30px;
    font-family: 'FontAwesome';
    color: #1a1a1a;
    line-height: 1;
}

.touch-slider .uk-position-center-right-out:after {
    content: "\f104";
    font-size: 30px;
    font-family: 'FontAwesome';
    color: #1a1a1a;
    line-height: 1;
}

.touch-slider .uk-position-center-left-out img {
    display: none;
}

.touch-slider .uk-position-center-right-out img {
    display: none;
}

.text-white {
    color: #fff;
}

.uk-form-icon {
    color: #706b6b;
}

.f20 {
    font-size: 20px !important;
}

.f23 {
    font-size: 23px !important;
    line-height: 0.5;
}

.border-mansoon {
    border-right: 1px solid #81c2f7;
    border-bottom: 5px solid #81c2f7;
}

.offer-slider {
    margin: -1rem -15px 1rem;
    position: relative;
    overflow: hidden;
    padding: 10px 0 15px;
}

.offer-flex {
    border: 1px solid #ccc;
    display: flex;
    padding: 1rem;
    margin: 0 1rem;
    border-radius: 8px;
    box-shadow: 0px 6px 2px #ccc;
    justify-content: left;
    align-items: center;
}

.offer-flex-item {
    margin-right: 1rem;
    cursor: pointer
}

.offer-per {
    font-weight: 600;
    font-size: 0.8rem;
}

.offer-info {
    font-size: 0.8rem;
}

.offer-slider .uk-slidenav-next.uk-slidenav {
    margin-right: -11px;
}

.offer-slider .uk-slidenav-previous.uk-slidenav {
    margin-left: -11px;
}

div[id^="modal-offer_"] .uk-modal-dialog {
    padding: 0;
    width: 500px;
    max-width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

div[id^="modal-offer_"] ul {
    list-style-type: disc;
    list-style-position: outside;
    margin-top: 0em;
    overflow: hidden;
    /* padding-left: 0; */
    margin-left: -1em;
}

div[id^="modal-offer_"] ul li {
    float: left;
    padding-left: 0;
    margin-bottom: 0.5rem;
    font-size: 14px;
    padding-right: 0;
    width: 100%;
}

div[id^="modal-offer_"] .uk-modal-body {
    display: flow-root;
    padding: 1rem;
}

div[id^="modal-offer_"] .uk-modal-title {
    font-size: 1.2rem;
    line-height: 1.3;
}

div[id^="modal-offer_"] .uk-modal-header {
    display: flow-root;
    padding: 1rem;
}

img.offer-logo {
    margin-bottom: 1.2rem;
}

p.offer-for {
    font-weight: 500;
    font-size: 1rem;
    color: #000;
    margin-bottom: 3px;
}

p.offer-into {
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 5px;
}

p.offer-validity {
    font-size: 0.8rem;
}

.terms-heading {
    font-weight: 600;
    margin-bottom: 0.5rem;
    font-size: 15px;
}

.loyal-link.w-40 {
    padding: 15px 15px;
    cursor: pointer;
    width: 100%;
    background: #f4f4f4;
}

.loyal-link img {
    margin-right: 1rem;
}

.loyal-link:after {
    content: "\f054";
    font-size: 1.1rem;
    color: #333;
    opacity: 0.6;
    line-height: 1.8;
    float: right;
    font-family: 'FontAwesome';
}

.account-details-screen img {
    width: 120px;
}

p.points-details {
    font-family: 'Poppins';
    color: #747474;
    font-size: 0.8rem;
    margin: 0.5rem 0 1rem;
}

div[id="modal-loyal-points"] .uk-modal-dialog {
    padding: 0;
    width: 500px;
    max-width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

div[id="modal-loyal-points"] .uk-modal-body {
    display: flow-root;
    padding: 1rem;
}

div[id="modal-loyal-points"] .uk-modal-title {
    font-size: 1.2rem;
    line-height: 1.3;
}

div[id="modal-loyal-points"] .uk-modal-header {
    display: flow-root;
    padding: 1rem;
}

div[id="modal-loyal-points"] .form-group {
    margin-bottom: 1rem;
}

div[id="modal-loyal-points"] .form-group input {
    border: none;
    border-bottom: 1px solid #ddd;
    color: #333;
    font-size: 14px;
    font-weight: 300;
}

.points-form .btn-yellow {
    border-radius: 20px;
    display: inline-block;
    padding: 15px 35px;
    color: #000 !important;
    font-size: 14px;
    transition: all 0.5s linear;
    /* background-color: #d8d7d7 !important; */
    cursor: pointer;
    border: transparent;
    display: block;
    margin: 0 auto;
}

.form-group.text-center {
    padding-top: 1rem;
}

.total-saving {
    background: #fffecb;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    font-weight: 600;
    margin-bottom: 1rem;
}

i.fa.fa-info {}

.total-saving i {
    margin-right: 5px;
    border: 1px solid #000;
    padding: 2px 7px;
    border-radius: 26px;
}

.total-saving span {
    float: right;
}

.redeem-info p {
    margin-bottom: 0.5rem;
}

.redeem-info {
    padding-top: 1rem;
    padding-bottom: 1.5rem;
}

.redeem-info span {
    font-weight: 600;
}

.redeem-block .btn-yellow {
    padding: 15px 35px;
    display: block;
    margin: 0 auto;
}

.loyalty-amount.convenienceFee~.total-amount.convenienceFee {
    border-top: 0;
    margin-top: 0;
}

@media screen and (max-width:767px) {
    .offer-slider .uk-slidenav-next.uk-slidenav {
        display: none;
    }

    .offer-slider .uk-slidenav-previous.uk-slidenav {
        display: none;
    }

    div[id^="modal-offer_"] .uk-modal-dialog,
    div[id="modal-loyal-points"] .uk-modal-dialog,
    div[id="payback-account"] .uk-modal-dialog,
    div[id="validate-otp-modal"] .uk-modal-dialog {
        position: relative;
        box-sizing: border-box;
        margin: 0 auto;
        width: 600px;
        max-width: 100% !important;
        background: #fff;
        opacity: 0;
        transform: translateY(100%);
        transition: 0.3s linear;
        transition-property: opacity, transform;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    div[id^="modal-offer_"] .uk-margin-auto-vertical,
    div[id="modal-loyal-points"] .uk-margin-auto-vertical,
    div[id="payback-account"] .uk-margin-auto-vertical,
    div[id="validate-otp-modal"] .uk-margin-auto-vertical {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    div[id^="modal-offer_"].uk-open .uk-modal-dialog,
    div[id="modal-loyal-points"].uk-open .uk-modal-dialog,
    div[id="payback-account"].uk-open .uk-modal-dialog,
    div[id="validate-otp-modal"].uk-open .uk-modal-dialog {
        opacity: 1;
        transform: translateY(0);
    }

    div[id^="modal-offer_"] .uk-modal-dialog,
    div[id="modal-loyal-points"] .uk-modal-dialog,
    div[id="validate-otp-modal"].uk-modal-dialog {
        border-radius: 10px 10px 0 0;
    }

    .offer-slider {
        padding: 10px 2px 15px;
    }

    .offer-slider [class*=uk-child-width]>* {
        box-sizing: border-box;
        width: 75%;
    }

    .offer-flex {
        margin: 0 10px;
        padding: 0.5rem;
    }

    .offer-for {
        font-size: 0.9rem;
    }

    .offer-into {
        font-size: 0.9rem;
    }

    div[id^="modal-offer_"] hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .offer-flex-item img {
        width: 40px;
    }
}

.earn-box {
    padding: 15px 25px;
    border: 1px solid #ddd;
    position: relative;
}

.earn-form .uk-input {
    border: none;
    border-bottom: 1px solid #ddd;
    color: #333;
    font-size: 14px;
    font-weight: 300;
}

input.check-btn {
    /* appearance: none; */
    background: none;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.7rem;
    color: #606060;
    font-size: 15px;
    cursor: pointer;
}

.earn-form .form-group {
    position: relative;
    margin-bottom: 5px;
}

.payback-img img {
    margin: 0 auto 2rem;
    display: block;
}

.earn-note li {
    color: #8f8f8f;
    font-size: 13px;
    margin-bottom: 0.3rem;
}

.earn-note ol {
    list-style-type: auto;
    padding-left: 1.5rem;
}

.earn-note b {
    margin-bottom: 7px;
    display: block;
    color: #535353;
    font-weight: 500;
}

.earn-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    background-color: #eee;
    border: 1px solid #ddd;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkmark:after {
    left: 6px;
    top: 6px;
    width: 10px;
    height: 10px;
    border-width: 0 3px 3px 0;
    background: #ffde00;
}

input:checked~.checkmark:after {
    display: block;
}

input:checked~.checkmark {
    background-color: #ffffff;
    border-color: #ffde00;
}

.earn-checkbox label {
    position: relative;
    padding-left: 2.5rem;
    cursor: pointer
<<<<<<< HEAD
}

.loyal-link-rewards.w-40 {
    padding: 15px 25px;
    cursor: pointer;
    width: 100%;
    background: #f4f4f4;
}
.loyal-link-rewards img {
    margin-right: 1rem;
}
.loyal-link-rewards-close{
    float: right;
    font-size: 25px;
}
div[id="modal-block-points-redemption"] .uk-modal-dialog {
    padding: 0;
    width: 500px;
    max-width: 100%;
    border-radius: 10px;
    overflow: hidden;
}
div[id="modal-block-points-redemption"] .uk-modal-body {
    display: flow-root;
    padding: 1rem;
}

div[id="modal-block-points-redemption"] .uk-modal-title {
    font-size: 1.2rem;
    line-height: 1.3;
}

div[id="modal-block-points-redemption"] .uk-modal-header {
    display: flow-root;
    padding: 1rem;
}

div[id="modal-block-points-redemption"] .form-group {
    margin-bottom: 1rem;
}

div[id="modal-block-points-redemption"] .form-group input {
    border: none;
    border-bottom: 1px solid #ddd;
    color: #333;
    font-size: 14px;
    font-weight: 300;
}
.border-0 {
    border: none !important;
}
.p-0 {
    padding: 0 !important;
}


